export const transformError = (errors) =>{
    return errors.map(err => {
        switch (err.name) {
            case "required":
                err.message = `${err.params.missingProperty} es requerido`;
                break;
            case "type":
                let type = (err.params.type==="string")?"texto":"número"
                err.message = `${err.property} debe ser de tipo ${type}`;
                break;
            case "maxLength":
                err.message = `${err.property} no debe superar ${err.params.limit} caracteres`;
                break;
            case "minimum":
                err.message = `${err.property[0]} debe ser mayor a ${err.params.limit}`;
                break;
            case "maximum":
                err.message = `${err.property[0]} debe ser menor a ${err.params.limit}`;
                break;
            case "pattern":
                err.message = `${err.property} no cumple con las reglas de escritura`;
                break;
            default:
                break;
        }
        return err;
    });
}