import React, { useCallback, useEffect, useState, Fragment, useRef } from "react";
import Swal from 'sweetalert2'
import {
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AuthRouter } from './AuthRouter';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { MainScreen } from '../components/main/MainScreen';
import { confirmLogin } from '../helpers/auth/confirmLogin';
import { login } from '../actions/auth';
import { types } from '../types/types';
import { AdminRouter } from './AdminRouter';
import { NavBar } from '../components/generics/NavBar';
import { AsideMenu } from '../components/generics/AsideMenu';
import { Layout, Spin, message } from 'antd';
import { UserRouter } from './UserRouter';
import { ReportsRouter } from "./ReportsRouter";
import '../index.css';
import '../components/user/styles/deleteStyles.css'
import { getAvailableYear } from "../helpers/admin/getAvailableYears";

export const AppRouter = ({ wsClient }) => {
  const mounted = useRef(true);
  const dispatch = useDispatch();
  const [checking, setChecking] = useState(true);
  const [isLogIn, setIsLogIn] = useState(false);
  const { role, name, isAutenticated } = useSelector(state => state.auth);
  //const { deletedMessages } = useSelector(state => state.auth);
  const { Content, Sider } = Layout;

  const ayncrono = useCallback(async (token, isLoginProcess) => {
    if (token) {

      const [authResponse, openYearsResponse] = await Promise.all([confirmLogin(token, isLoginProcess), getAvailableYear()])
      const status = authResponse.status && openYearsResponse.status;
      localStorage.setItem('isLoginProcess', false);
      if (status && !authResponse.payload.isIdle) {
        const { years_available, closured_years } = openYearsResponse.payload;
        const openYears = years_available.filter(year => !closured_years.includes(year));
        dispatch(login(authResponse.payload.item.name, authResponse.payload.item.role, authResponse.payload.item.agreements, status, openYears));
        setIsLogIn(true);
        setChecking(false);
        return
      }

      if (status && authResponse.payload.isIdle) {
        localStorage.removeItem('token');
        setChecking(false);
        return
      }

      setIsLogIn(false);
      setChecking(false);
      message.error(authResponse.payload.message);
    }

    setChecking(false);
  }, [dispatch]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let isLoginProcess = localStorage.getItem("isLoginProcess")
    ayncrono(token, isLoginProcess);
  }, [ayncrono]);



  const [receivedMessage, setReceivedMessage] = useState(null);

  const handleWebSocketMessage = (message) => {
    if (mounted.current) {
      setReceivedMessage(message);
    }
  };

  useEffect(() => {
    mounted.current = true; // El componente está montado
    wsClient.setMessageHandler(handleWebSocketMessage);

    return () => {
      mounted.current = false; // El componente se está desmontando
    };
  }, [wsClient]);

  useEffect(() => {
    if (receivedMessage) {
      const objDeletes = JSON.parse(receivedMessage);

      const successfulRequests = [];
      const failedRequests = [];
      const notFoundRequest = [];
      const array = objDeletes.message;

      array.forEach(valor => {
        const { hasError, isDeleted } = valor;
        if ((hasError && !isDeleted)) {
          failedRequests.push({ name_agreement: valor.name_agreement, uploadDate: valor.uploadDate })
        } else if (!hasError && isDeleted) {
          successfulRequests.push({ name_agreement: valor.name_agreement, uploadDate: valor.uploadDate })
        } else if (!hasError && !isDeleted) {
          notFoundRequest.push({ name_agreement: valor.name_agreement, uploadDate: valor.uploadDate })
        }
      });

      if (successfulRequests.length > 0 && failedRequests.length <= 0 && notFoundRequest.length <= 0) {

        Swal.fire({
          customClass: {
            popup: 'popupV2',
            actions: 'accionsV2',
            cancelButton: 'buttonCancel'
          },
          html: `<div class='modal-container'><div class='modal-header'><div class='modal-header-title'><div class="wrapper"> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
             </div><div class='header-title'>Eliminación exitosa</div></div><div class='modal-header-content'> Se ha(n) eliminado  <b> ${successfulRequests.length} </b> registro(s) exitosamente.  
            ${successfulRequests.map(obj => `<div class="results-content">
            <div class="result-item">
              <div class="result-id"><b>Convenio:</b> ${obj.name_agreement}</div>
              <div class="result-date"><b>Fecha:</b> ${obj.uploadDate}</div>
            </div>
            <!-- Repite los divs result-item para otros registros -->
          </div>
          
          `).join('')}
            </div></div></div`,
          confirmButtonText: `Aceptar`,
        });
      }


      if (notFoundRequest.length > 0 || failedRequests.length > 0) {

        Swal.fire({
          customClass: {
            popup: 'popupV2',
            actions: 'accionsV2',
            cancelButton: 'buttonCancel'
          },
          html: `<div class='modal-container'>
          <div class='modal-header'>
            <div class='modal-header-title'>
              <div class="wrapper"></div>
              <div class='header-title'>
                <div class='header-icon'>!
                </div>Alerta
              </div>
            </div>
            <div class='modal-header-content'>${successfulRequests.length > 0 ? `<p> Se ha(n) eliminado
              <b>${successfulRequests.length}</b> registro(s) exitosamente.</p>
              <div class="results-content">${successfulRequests.map(obj =>
            `<div class="result-item">
                  <div class="result-id"><b>Convenio:</b> ${obj.name_agreement}
                  </div>
                  <div class="result-date"><b>Fecha:</b> ${obj.uploadDate}
                  </div>
                </div>`).join('')}
              </div>` : ''}
              ${failedRequests.length > 0 ? `<p> Ha fallado la eliminación de <b>${failedRequests.length}</b> registro(s). </p>
              <div class="results-content">${failedRequests.map(obj => `<div class="result-item">
                  <div class="result-id"><b>Convenio:</b> ${obj.name_agreement}
                  </div>
                  <div class="result-date"><b>Fecha:</b> ${obj.uploadDate}</div>
                </div>`).join('')}</div>` : ''}
              ${notFoundRequest.length > 0 ? `<p>(<b>${notFoundRequest.length}</b>) Carga(s) eliminada(s) sin beneficiarios a eliminar. </p>
              <div class="results-content">${notFoundRequest.map(obj => `<div class="result-item">
                  <div class="result-id"><b>Convenio:</b> ${obj.name_agreement}
                  </div>
                  <div class="result-date"><b>Fecha:</b> ${obj.uploadDate}</div>
                </div>`).join('')}
              </div>` : ''}
            </div>   
          </div>
        </div>
        </div>`
          ,
          confirmButtonText: `Aceptar`,
        });
      }



    }
    setReceivedMessage(null);
  }, [receivedMessage]);
  useEffect(() => {
    if (isAutenticated) {
      let token = localStorage.getItem("token");
      wsClient.openConnection();
      wsClient.sendMessage(token);
    }
  }, [isAutenticated, wsClient])

  if (checking) {
    return (<>

      <div style={{ display: "flex", justifyContent: "center", padding: 40 }}>
        <div>
          <Spin style={{ fontSize: 30, color: "#F16522", fontWeight: "bold" }} size="large" tip="Cargando..." />
        </div>
      </div>

    </>)
  }

  return (
    <Router>
      <div className="animate__animated animate__fadeIn">
        <Switch>
          <Fragment>
            <PublicRoute path="/auth" isAuthenticated={isLogIn} component={AuthRouter} />
            {
              (isLogIn) &&
              (
                <Layout>
                  <NavBar wsClient={wsClient} userName={name} />
                  <Layout style={{ marginTop: 64 }}>
                    <Sider width={200}>
                      <AsideMenu role={role} />
                    </Sider>
                    <Content
                      className="site-layout-background container-routes"
                      style={{
                        paddingLeft: 24,
                        margin: 0,
                        minHeight: 300,
                      }}>
                      <div style={{
                        margin: 30,
                        backgroundColor: "#FFF",
                        borderRadius: 4,
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                      }}>
                        {(role === types.TOTAL_CONTROL_ROLE.name) && <PrivateRoute isAuthenticated={isLogIn} path="/admin" component={AdminRouter} />}
                        {(role !== types.GLOBAL_CONTROL_ROLE.name) && <PrivateRoute isAuthenticated={isLogIn} path="/user" component={UserRouter} />}
                        <PrivateRoute isAuthenticated={isLogIn} path="/reports" component={ReportsRouter} />
                        <PrivateRoute isAuthenticated={isAutenticated} exact path="/" component={MainScreen} />
                      </div>
                    </Content>
                  </Layout>
                </Layout>
              )
            }
            <Redirect to="/auth/login" />
          </Fragment>
        </Switch>
      </div>
    </Router>
  )
}



