import { putCloseSession } from "../helpers/auth/putCloseSession";

export const closeSession = (wsClient) => {

    const token = localStorage.getItem("token");

    if(token && token !== ''){
      putCloseSession(token).then(response => {
        if(response.status){
          localStorage.removeItem("token");
          wsClient.closeConnection();
          window.location.reload();
        }
      }).catch(error => console.error(error));
    }
}