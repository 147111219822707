import React, { Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AdminSchema } from '../pages/admin/AdminSchema'

export const AdminRouter = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path="/admin/schema" component={AdminSchema} />
                <Route exact path="/admin/users" component={AdminSchema} />
                <Route exact path="/admin/agreements" component={AdminSchema} />
                <Route exact path="/admin/tables" component={AdminSchema} />
                <Route exact path="/admin/delete" component={AdminSchema} />
                <Route exact path="/admin/clousure" component={AdminSchema} />
                <Redirect to="/" />
            </Switch>
        </Fragment>
    )
}
