import { getToken } from "../../services/HttpService";

export const getQueueMessagesReport = async (requestCode) =>{
    

    const endPonint = `${process.env.REACT_APP_API}/queue/messagesReports`;
    const response = await getToken();

    const resp = await fetch(endPonint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json",
            requestCode
        },
        method:"GET"
    });

    return resp.json();
}