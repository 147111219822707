import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { message, Input, Select, Button, Empty, Radio } from 'antd';
import Form from '@rjsf/material-ui';
import Swal from 'sweetalert2';
import { useForm } from '../../hooks/useForm';
import { CloudUploadOutlined, DeleteFilled } from "@ant-design/icons";
import { getStructure } from '../../helpers/admin/getStructures';
import { postItemsAdded } from '../../helpers/admin/postItemsAdded';
import { buildFields } from '../../services/buildFields.service';
import { buildPropertiesAndRules } from '../../services/buildPropertiesAndRules.service';
import './styles/editSchemaProgram.css'


export const EditSchemaProgram = () => {

    const [schema, setSchema] = useState({});
    const [properties, setProperties] = useState({});
    const [structures, setStructure] = useState([]);
    const [listItems, setListItems] = useState([]);
    const [fieldAdded, setFieldAdded] = useState(null);
    const [archiveControl, setArchiveControl] = useState(null);
    const [existentedField, setExistentedField] = useState(null);
    const [optAction, setOptAction] = useState("EXISTENTED_VALUE");
    const { name } = useSelector(state => state.auth);
    const { Option } = Select;

    const [values, handlerInputChange, reset] = useForm({
        newFieldName: ""
    });

    const optionsWithDisabled = [
        { label: 'Existente', value: 'EXISTENTED_VALUE' },
        { label: 'Nuevo', value: 'NEW_VALUE' }
    ];

    const { newFieldName } = values;
    const memoAbortController = useMemo(() => new AbortController(), []);

    useEffect(() => {

        getStructure(memoAbortController)
            .then((resp) => {
                (resp) &&
                    setStructure(resp.items);
            })
            .catch(err => console.error(err));
        return () => {
            memoAbortController.abort();
        }
    }, [memoAbortController]);

    useEffect(() => {
        setSchema({
            "type": "object",
            "properties": properties
        });
    }, [properties]);

    const handlerAddElement = async (e) => {
        e.preventDefault();
        let field = null;

        if (optAction === "EXISTENTED_VALUE") {

            if ((archiveControl === null || existentedField === null)) {
                message.error("Hay campos vacios");
                return;
            }

            field = existentedField;
        } else {

            if ((archiveControl === null) || newFieldName.trim() === "") {
                message.error("Hay campos vacios");
                return;
            }

            field = newFieldName;
        }

        let arraySet = [...listItems, field.trim()];
        arraySet = [...new Set(arraySet)];

        await buildFields(arraySet).then(async values => {
            setProperties(values);
            setListItems(arraySet);
        })
            .catch(console.error);
    }

    const handlerOnSubmitForm = (e) => {

        const { formData } = e;
        const schemaForm = e.schema;

        const propertiesAndRules = buildPropertiesAndRules(formData);

        const { rules, schemaProperties, requireds } = propertiesAndRules;


        const params = {
            code: archiveControl,
            properties: schemaProperties,
            required: requireds,
            rules,
            user: name,
            uiSchema: {
                "ui:order": listItems
            },
            schema: schemaForm
        }

        Swal.fire({
            title: 'Esquemas',
            text: '¿Deseas guardar?',
            footer: "<small>Recuerda que los campos agregados no se pueden borrar </small>",
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: `Cancelar`,
            confirmButtonText: `Guardar`,
            denyButtonText: `No Guardar`,
        }).then((result) => {

            if (result.isConfirmed) {

                postItemsAdded(params)
                    .then((resp) => {
                        if (resp.status) {
                            Swal.fire('Guardado!!', "", 'success');
                            setListItems([]);
                            reset();
                            setFieldAdded(null);
                            setSchema({});
                        }
                        else {
                            Swal.fire('Error!', "'" + resp.result + "'", 'error');
                        }
                    })
            } else if (result.isDenied) {
                Swal.fire('Variable(s) No guardada', '', 'info')
            }
        });


    }

    const handlerDeleteField = () => {
        const value = fieldAdded;
        if (value) {

            if (properties.hasOwnProperty(value)) {
                let newProperties = { ...properties };
                delete newProperties[value];
                setProperties(newProperties);
                let newListItem = [...listItems];
                const index = newListItem.indexOf(value);
                newListItem.splice(index, 1);
                setListItems(newListItem);
            }
            setFieldAdded(null);
        }
    }

    const generateListSelect = () => {
        if (archiveControl) {

            let structureChosed = structures.filter(data => data.code === archiveControl);

            let structureKeys = Object.keys(structureChosed[0].properties);

            return structureKeys.map((option) =>
                (<Option key={option} value={option}>{option}</Option>))
        }

    }



    return (
        <div className="animate__animated animate__fadeIn containerEditSchema">

            <div className="boxLeft">

                <form onSubmit={handlerAddElement} autoComplete="off">

                    <div className="formControl">
                        <label htmlFor="typeOfAction">Tipo de campo</label>
                        <Radio.Group
                            style={{ display: "block" }}
                            id="typeOfAction"
                            name="typeOfAction"
                            options={optionsWithDisabled}
                            onChange={(e) => setOptAction(e.target.value)}
                            value={optAction}
                            optionType="button"
                            buttonStyle="solid" />

                    </div>

                    <div className="formControl">
                        <label htmlFor="archive">Formulario</label>

                        <Select className="schemaEditControl" id="archive" name="archive" onChange={setArchiveControl}>
                            {
                                structures.map((option, i) =>
                                    (<Option key={i} value={option.code}>{option.archive}</Option>)
                                )
                            }
                        </Select>
                    </div>

                    {
                        (optAction === "EXISTENTED_VALUE") &&
                        <div className="formControl">
                            <label htmlFor="newFieldNameSelect">Campos Existentes</label>
                            <Select
                                className="schemaEditControl"
                                id="newFieldNameSelect"
                                name="newFieldNameSelect"
                                showSearch
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                onChange={setExistentedField}
                            >
                                {
                                    generateListSelect()
                                }
                            </Select>

                        </div>
                    }


                    {
                        (optAction === "NEW_VALUE") &&
                        <div className="formControl">
                            <label htmlFor="userId">Nuevo Campo</label>
                            <Input
                                required
                                id="newFieldName"
                                className="schemaEditControl"
                                name="newFieldName"
                                value={newFieldName}
                                onChange={handlerInputChange}
                                placeholder="Escriba el nombre" />

                        </div>
                    }


                    <Button
                        type="primary"
                        shape="round"
                        onClick={handlerAddElement}
                        icon={
                            <CloudUploadOutlined
                                style={{
                                    fontSize: "20px",
                                }}
                            />
                        }
                        size="large"
                        style={{
                            background: "#345AB3",
                            borderColor: "#345AB3",
                            marginTop: "30px",
                        }}
                    >
                        Cargar
                    </Button>
                </form>

                <div className="deleteControls">
                    <label htmlFor="archiveAdded">Campos creados</label>
                    <Select className="schemaEditControl" id="archiveAdded" name="archiveAdded" placeholder="Seleccione un campo" onChange={setFieldAdded}>
                        {
                            listItems.map((option, i) =>
                                (<Option key={i} value={option}>{option}</Option>)
                            )
                        }
                    </Select>

                    <Button
                        type="primary"
                        shape="round"
                        onClick={handlerDeleteField}
                        icon={
                            <DeleteFilled
                                style={{
                                    fontSize: "20px",
                                }}
                            />
                        }
                        size="large"
                        style={{
                            background: "#345AB3",
                            borderColor: "#345AB3",
                            marginTop: "15px",
                        }}
                    >
                        Limpiar campo
                    </Button>

                </div>
            </div>

            <div className="boxFomRight">

                {listItems.length > 0 ? <Form schema={schema} onSubmit={handlerOnSubmitForm} liveValidate>
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        htmlType={'submit'}
                        style={{
                            width: 130,
                            background: "#345AB3",
                            borderColor: "#345AB3",
                            marginTop: "30px",
                            textAlign: "center"
                        }}>
                        Enviar
                    </Button>
                </Form>
                    :
                    <Empty imageStyle={{
                        height: 250,
                    }} description={<span>No hay datos</span>} />

                }
            </div>

        </div>
    )
}
