import { getSourceData } from "../helpers/admin/getSourceData";
import { initialSchema } from "../models/initialSchema.model";

export const buildFields = async (data, archiveCode) =>{
     
    let source = await getSourceData();
    let arraySource = Object.values(source.items);
    let arrayPrograms = [];

    arraySource.forEach((value) => arrayPrograms.push(value.table));

    if(archiveCode !== "5-184A"){
        let index = arrayPrograms.indexOf("Modalidad");
        arrayPrograms.splice(index,1);
    }else{
        let index = arrayPrograms.indexOf("Modalidad AIN");
        arrayPrograms.splice(index,1);
        index = arrayPrograms.indexOf("Modalidad JEC");
        arrayPrograms.splice(index,1);
    }

    let schemaProperties={};
    data.forEach( (prop) =>{
        schemaProperties = {...schemaProperties,
            [prop.trim()]:initialSchema(arrayPrograms, data)
        }  
    });

    return schemaProperties;
}