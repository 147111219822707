import { getToken } from "../../services/HttpService";

export const getTablesList = async (abortController) => {
    
    const endPonint = `${process.env.REACT_APP_API}/tablelistCatalog`;
    const response = await getToken();

    const resp = await fetch(endPonint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        signal: abortController.signal,
        redirect: "follow"
    });
    const data = await resp.json();
    
    return data;
}
