import { getToken } from "../../services/HttpService";

export const getSourceData = async () =>{

    const response = await getToken();
    const endPonint = `${process.env.REACT_APP_API}/tables`;

    const resp = await fetch(endPonint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        }
    });
    const data = await resp.json();
    
    return data;
}