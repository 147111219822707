import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Form from "@rjsf/material-ui";
import Swal from "sweetalert2";
import { message, Input, Select, Button, Space, Divider, Table } from "antd";
import { SearchOutlined, SendOutlined } from "@ant-design/icons";
import {cloneDeep} from "lodash"
import { ModalTraslateBeneficiary } from "../user/ModalTraslateBeneficiary";
import { getOneMicrodata } from "../../helpers/user/getOneMicrodata";
import { postRequestChange } from "../../helpers/user/postRequestChange";
import { putNoRequiredMicrodata } from "../../helpers/user/putNoRequiredMicrodata";
import { buildRowsAndColumns } from "../../services/buildRowsAndColumns.service";
import { buildUiSchema } from "../../services/buildUiSchema.service";
import { messageModal } from "../../services/messageModal";
import { useForm } from "../../hooks/useForm";
import { transformError } from "../../services/transformError";
import "./styles/editNonRequireds.css";

export const EditNonRequireds = () => {
  const initialState = { rows: [], columns: [] };
  const [{ rows, columns }, setColRowListAgreements] = useState(initialState);
  const [schemaForm, setSchemaForm] = useState({});
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentRange, setCurrentRange] = useState("");
  const [agreement, setAgreement] = useState(null);
  const [{ id, agreementID }, setIdAndAgreementId] = useState({ agreementID: "", id: "" });
  const [isRequiredRequest, setIsRequiredRequest] = useState(false);
  const [currentRecord, setCurrentRecord] = useState([]);
  const [dataQuery, setDataQuery] = useState({});
  const [open, setOpen] = useState(false);
  const [traslates, setTraslates] = useState(null);
  const [state, setstate] = useState({ searchText: "", searchedColumn: "" });
  const [loading, setLoading] = useState(false);
  const [beneficiarySelected, setBeneficiarySelected] = useState({ year: null, state: '' })

  const { agreements, name } = useSelector((state) => state.auth);

  const [values, handlerInputChange, reset] = useForm({ idBeneficiary: "" });

  const { idBeneficiary } = values;
  const { Option } = Select;

  useEffect(() => {
    const fetchData = async () => {
      if (id !== "") {
        try {
          const microData = await getOneMicrodata({ id, agreementID });
          const listMicrodata = microData.payload.items;
          if (listMicrodata.length > 0) {
            setIsRowSelected(false);
            setSelectedRowKeys([]);

            const newListMicrodata = listMicrodata.map((value) => {
              return {
                Estado: value.state,
                "Nro Identificacion": value["Nro Identificacion"],
                range: value["range"],
                ...value.properties,
                "Fecha Nacimiento": new Date(new Date(value.properties["Fecha Nacimiento"]).setUTCHours(10, 0, 0, 0)).toISOString(),
                "Fecha Vinculacion": new Date(new Date(value.properties["Fecha Vinculacion"]).setUTCHours(10, 0, 0, 0)).toISOString()
              };
            });

            setCurrentRecord(cloneDeep(newListMicrodata));

            newListMicrodata.forEach((_,index) => delete newListMicrodata[index].range);
            const { arrayColumns, arrayRows } = buildRowsAndColumns(newListMicrodata, 200, "antd", state, setstate, false, true);

            setColRowListAgreements({
              columns: arrayColumns,
              rows: arrayRows,
            });

            const rulesKey = Object.keys(microData.payload.schema.rules);

            const rules = microData.payload.schema.rules;

            const uiSchemaForm = buildUiSchema(rulesKey, rules);
            setSchemaForm({
              schema: {
                title: `Formulario de Edición`,
                type: "object",
                properties: microData.payload.schema.properties,
                required: microData.payload.schema.required,
              },
              uiSchema: { ...microData.payload.schema.uiSchema, ...uiSchemaForm },
              rules: microData.payload.schema.rules,
              code: microData.payload.schema.code,
            });
            setDataQuery(microData.payload.dataQuery);
            setLoading(false);
          } else {
            message.info(
              "No se encontraron registros, verifique el id o el convenio"
            );
            setLoading(false);
            setIdAndAgreementId({ agreementID: "", id: "" });
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData().catch((err) => console.error("ERROR!!", err));
  }, [id, agreementID]);

  const confirmSubmit = (formData, typeRequest) => {
    if (JSON.stringify(formData) === "{}") return;
    const [record] = currentRecord.filter(value => value.range === currentRange);
    messageModal({
      formData,
      currentRange,
      schemaForm,
      currentRecord : record,
      typeRequest,
      name,
    }).then((response) => {
      if (response) {
        Swal.fire({
          title: "Solicitud",
          text: "¿ Desea enviar esta solicitud ?",
          footer:
            "<small>Esta solicitud sera aplicada cuando un usuario de control acepte el cambio</small>",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Enviar`,
          denyButtonText: `No Enviar`,
          cancelButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            postRequestChange(response).then((resp) => {
              if (resp.status) {
                setTraslates(null);
                setColRowListAgreements({
                  columns: [],
                  rows: [],
                });
                setIdAndAgreementId({ agreementID: "", id: "" });
                setColRowListAgreements({ rows: [], columns: [] });
                setIsRowSelected(false);
                Swal.fire("Enviado!!", "Solicitud Enviada", "success");
              } else {
                Swal.fire("Error!", "'" + resp.Error + "'", "error");
                setTraslates({});
              }
            });
          } else if (result.isDenied) {
            Swal.fire("No Enviado", "", "info");
          }
        });
      } else {
        setTraslates({});
      }
    });
  };

  const confirmSubmitMemo = useMemo(
    () => confirmSubmit({ ...traslates }, "TRASLATE"),
    [traslates, confirmSubmit]
  );

  //Efecto que envia informacion de los traslados
  useEffect(() => { return null }, [traslates, confirmSubmitMemo]);

  const handlerRowSelected = (data) => {
    const properties = { ...data };
    delete properties["ID Convenio"];

    const schema = { ...schemaForm };
    const keysRules = Object.keys(schema.rules);

    keysRules.forEach((value, i) => {
      delete properties["range"];
      if (schema.rules[value].rules.omitir) {
        delete properties[value];
      }
    });

    const keyProperties = Object.keys(properties);

    //se reordena nombres y codigos para evitar que formulario salte error
    keyProperties.forEach((value) => {
      if (schema.rules[value]) {
        if (schema.rules[value].rules.listado) {
          if (
            schema.rules[value].rules.listado.generateCode &&
            schema.schema.properties[value].enumNames
          ) {
            schema.schema.properties[value] = {
              ...schema.schema.properties[value],
              default: data[schema.rules[value].rules.listado.generateCode],
            };
          } else if (schema.schema.properties[value].enumNames) {
            const oneEnumName = data[value];
            const index =
              schema.schema.properties[value].enumNames.indexOf(oneEnumName);
            const oneEnumValue = schema.schema.properties[value].enum[index];
            schema.schema.properties[value] = {
              ...schema.schema.properties[value],
              default: oneEnumValue,
            };
          } else {
            schema.schema.properties[value] = {
              ...schema.schema.properties[value],
              default: data[value],
            };
          }
        } else {
          schema.schema.properties[value] = {
            ...schema.schema.properties[value],
            default: data[value],
          };
        }
      } else {
        if (schema.schema.properties[value]) {
          schema.schema.properties[value] = {
            ...schema.schema.properties[value],
            default: data[value],
          };
        }
      }
    });

    let uiSchemaReadOnly = {};
    schema.schema.required.forEach((value) => {
      uiSchemaReadOnly = {
        ...uiSchemaReadOnly,
        [value]: {
          "ui:readonly": "readOnly",
        },
      };
    });
    schema.uiSchema = { ...schema.uiSchema, ...uiSchemaReadOnly };
    setSchemaForm(schema);
    setCurrentRange(currentRecord[properties.key].range);
    setBeneficiarySelected({ year: new Date(properties['Fecha Vinculacion']).getFullYear(), state: properties.Estado })
    setIsRowSelected(true);
  };

  //envio del formulario
  const handlerOnSubmit = (e) => {
    let { formData } = e;
    const listSchemaKey = Object.keys(schemaForm.rules);
    listSchemaKey.forEach((value) => {
      if (schemaForm.rules[value].rules.listado) {
        if (
          schemaForm.rules[value].rules.listado.generateCode &&
          schemaForm.schema.properties[value].enumNames
        ) {
          const index = schemaForm.schema.properties[value].enum.indexOf(
            formData[value]
          );
          const resultNames =
            schemaForm.schema.properties[value].enumNames[index];
          const valueName = schemaForm.rules[value].rules.listado.generateCode;
          if (formData[value]) {
            formData = {
              ...formData,
              [valueName]: formData[value],
              [value]: resultNames,
            };
          }
        } else if (schemaForm.schema.properties[value].enumNames) {
          const index = schemaForm.schema.properties[value].enum.indexOf(
            formData[value]
          );
          const resultNames =
            schemaForm.schema.properties[value].enumNames[index];
          if (resultNames) {
            formData = { ...formData, [value]: resultNames };
          }
        }
      }

      if (schemaForm.rules[value].rules.especial) {
        if (schemaForm.rules[value].rules.especial.generateField) {
          const listOfFields =
            schemaForm.rules[value].rules.especial.generateField;
          const infrastructureFiltered = dataQuery.infrastructures.filter(
            (item) => item.tradeName === formData[value]
          );
          listOfFields.forEach((dataField) => {
            formData = {
              ...formData,
              [dataField.fieldsOfForm]:
                infrastructureFiltered[0][dataField.fieldsOfInfrastructure],
            };
          });
        }
      }
    });

    formData = {
      ...formData,
      "Codigo Institucion": parseInt(formData["Codigo Institucion"]),
      "ID Convenio": agreement.code,
    };

    if (isRequiredRequest) {
      const typeRequest = "CHANGE";
      confirmSubmit(formData, typeRequest);
      return;
    }

    let request = {
      formData,
      required: schemaForm.schema.required,
      range: currentRange,
    };

    Swal.fire({
      title: "Solicitud",
      text: "¿ Desea Realizar estos cambios ?",
      footer:
        "<small>Este cambio no requiere aceptación de usuario de control</small>",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Enviar`,
      denyButtonText: `No Enviar`,
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        putNoRequiredMicrodata(request).then((resp) => {
          if (resp.status) {
            setTraslates(null);
            setColRowListAgreements({
              columns: [],
              rows: [],
            });
            setIdAndAgreementId({ agreementID: "", id: "" });
            setColRowListAgreements(initialState);
            setIsRowSelected(false);
            reset();
            Swal.fire("Enviado!!", "Solicitud Enviada", "success");
          } else {
            Swal.fire("Error !!", resp.Error, "error");
          }
        });
      } else if (result.isDenied) {
        Swal.fire("No Enviado", "", "info");
      }
    });

    setIsRowSelected(false);
  };

  const handlerOnSubmitFilter = () => {

    if (idBeneficiary.trim() === "") {
      message.error("EL ID del beneficiario esta vacio");
      return;
    }

    if (agreement === null) {
      message.error("Debe seleccionar un convenio");
      return;
    }
    const idAgreement = agreement.code;
    setIdAndAgreementId({
      agreementID: idAgreement,
      id: idBeneficiary,
    });
    setLoading(true);
  };

  const handlerChangeRequireds = () => {
    const schema = { ...schemaForm };
    const listProperties = Object.keys(schema.schema.properties);
    const listRequireds = Object.values(schema.schema.required);

    let listBlockeds = listProperties.filter(
      (value) => !listRequireds.includes(value)
    );

    let uiSchemaReadOnly = {};

    listBlockeds.forEach((value) => {
      uiSchemaReadOnly = {
        ...uiSchemaReadOnly,
        [value]: {
          "ui:readonly": "readOnly",
        },
        "Nro Identificacion": {
          "ui:readonly": "readOnly",
        },
        "Nombre Infraestructura": {
          "ui:readonly": "readOnly",
        },
      };
    });

    schema.uiSchema = {
      ...uiSchemaReadOnly,
    };

    setSchemaForm(schema);
    setIsRequiredRequest(true);
  };

  const handlerClickOpen = () => {
    setOpen(true);
  };

  const handlerClickDelete = () => {
    const typeRequest = "DELETE";
    const [record] = currentRecord.filter(value => value.range === currentRange);
    const formData = {
      "Nro Identifiacion": record["Nro Identificacion"],
    };
    messageModal({
      formData,
      currentRange,
      schemaForm,
      currentRecord : record,
      typeRequest,
      name,
    }).then((response) => {
      if (response) {
        Swal.fire({
          title: "Solicitud",
          text: "¿ Desea enviar esta solicitud ?",
          footer:
            "<small>Esta solicitud sera aplicada cuando un usuario de control acepte el cambio</small>",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Enviar`,
          denyButtonText: `No Enviar`,
          cancelButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            postRequestChange(response).then((resp) => {
              if (resp.status) {
                setTraslates(null);
                setColRowListAgreements({
                  columns: [],
                  rows: [],
                });
                setIdAndAgreementId({ agreementID: "", id: "" });
                setColRowListAgreements(initialState);
                setIsRowSelected(false);
                reset();
                Swal.fire("Enviado!!", "Solicitud Enviada", "success");
              } else {
                Swal.fire("Error!", "'" + resp.Error + "'", "error");
              }
            });
          } else if (result.isDenied) {
            Swal.fire("No Enviado", "", "info");
          }
        });
      }
    });
  };

  const onSetAgreement = (event) => {
    const [agreementFiltered] = agreements.filter(
      (data) => data.code === event
    );
    setAgreement(agreementFiltered);
  };

  const rowSelectionAgreements = {
    onChange: (selectedRow, selectedRows) => {
      setSelectedRowKeys(selectedRow);
      const [result] = selectedRows;
      handlerRowSelected(result);
    }
  }

  const verifyActuallySearching = () => {

    if (rows.length > 0) {
      return (idBeneficiary === rows[0]["Nro Identificacion"]) ? true : false;
    } else {
      return false;
    }
  }

  return (
    <>
      <div className="containerNonRequireds animate__animated animate__fadeIn">
        <div>
          <div style={{ display: "flex" }}>
            <Space size="large">
              <div style={{ width: 276 }}>
                <label htmlFor="agreementID">Convenio</label>
                <Select
                  id="agreementID"
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Selecciona un convenio"
                  optionFilterProp="children"
                  onChange={onSetAgreement}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {agreements.map((value) => (
                    <Option key={value.code} value={value.code}>
                      {value.name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div style={{ width: 276 }}>
                <label htmlFor="agreementID">ID del beneficiario</label>
                <Input
                  autoComplete="off"
                  onChange={handlerInputChange}
                  value={idBeneficiary}
                  name="idBeneficiary"
                  id="agreementID"
                  style={{ width: "100%" }}
                  placeholder="Esciba el ID"
                />
              </div>
            </Space>
          </div>

          <Button
            id="btnSearch"
            loading={loading}
            className="btnSearch"
            disabled={verifyActuallySearching()}
            type="primary"
            shape="round"
            onClick={handlerOnSubmitFilter}
            size="large"
            icon={<SearchOutlined style={{ fontSize: "20px" }} />}
            style={{
              width: 150,
              marginTop: "30px",
            }}
          >
            {(loading) ? "Buscando" : "Buscar"}
          </Button>
        </div>

        <div style={{ height: 200, marginTop: 40 }}>
          {rows.length > 0 && (
            <>

              <Table rowSelection={{
                type: "radio",
                selectedRowKeys,
                ...rowSelectionAgreements
              }} tableLayout="fixed" columns={columns} dataSource={rows} scroll={{ x: '100vw', y: 130 }} size="small" />

              {isRowSelected && (
                <div>
                  <Space size="middle">
                    <Button
                      shape="round"
                      type="primary"
                      id="btnRequireds"
                      className="btnRequireds"
                      onClick={handlerChangeRequireds}
                      disabled={beneficiarySelected.state !== 'active'}
                    >
                      Cambiar Obligatorios
                    </Button>
                    <Button
                      shape="round"
                      type="primary"
                      id="btnTransfer"
                      className="btnRequireds"
                      onClick={handlerClickOpen}
                      disabled={(beneficiarySelected.year < new Date().getFullYear()) || beneficiarySelected.state !== 'active'}
                    >
                      Solicitar Traslado
                    </Button>
                    <Button
                      shape="round"
                      type="primary"
                      id="btnDelete"
                      className="btnRequireds"
                      onClick={handlerClickDelete}
                    >
                      Solicitar Eliminacion
                    </Button>
                  </Space>
                </div>
              )}
            </>
          )}

          <Divider />
        </div>

        <div style={{ marginTop: 180, width: 450 }}>
          {schemaForm.schema && isRowSelected && (
            <Form
              noHtml5Validate={true}
              onSubmit={handlerOnSubmit}
              schema={schemaForm.schema}
              uiSchema={schemaForm.uiSchema}
              autoComplete="off"
              transformErrors={transformError}
              showErrorList={false}
              liveValidate
            >
              <Button
                id="btnSearch"
                className="btnSearch"
                type="primary"
                shape="round"
                htmlType="submit"
                size="large"
                icon={<SendOutlined style={{ fontSize: "20px" }} />}
                disabled={beneficiarySelected.state !== 'active'}
                style={{
                  width: 150,
                  marginTop: "30px",
                  marginBottom: "30px"
                }}
              >
                Enviar
              </Button>
            </Form>
          )}
        </div>
      </div>
      {dataQuery.infrastructures && open && (
        <ModalTraslateBeneficiary
          open={open}
          setOpen={setOpen}
          listAgrements={agreements}
          programID={schemaForm.code}
          state={state}
          setstate={setstate}
          setTraslates={setTraslates}
          infrastructures={dataQuery.infrastructures}
        />
      )}
    </>
  );
};
