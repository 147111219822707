import CloudUpload from "../../../assets/images/cloudUpload.svg"
import { Button, Upload, Space, Typography} from "antd"
import { FileExcelTwoTone } from "@ant-design/icons"
import React from "react"
const { Title } = Typography;

const { Dragger } = Upload;

const Uploader = ({ setUploadFile, uploadedFile }) => {
  const draggerProps = {
    name: "file",
    accept: ".xls, .xlsx",
    showUploadList: false,
    multipe: false,
    // beforeUpload: file => file.size < 100000,
    // onStart: _ => true,
    customRequest: info => {
      // if (info.file.size > 100000) 
      //   message.error(`El archivo ${info.file.name} es demasiado pesado.`)
      // else 
      setUploadFile(info.file)
      return info.file
    }
  }

  return (
    <div className="animate__animated animate__fadeIn">
      <Dragger {...draggerProps} style={{ padding: "10px" }}>
        {uploadedFile ? (
          <Space direction="vertical">
            <FileExcelTwoTone
              twoToneColor="#389e0d"
              style={{ fontSize: "90px", padding: "10px" }}
            />
            <Title level={5}>{uploadedFile.fileName}</Title>
          </Space>
        ) : (
          <p className="ant-upload-drag-icon">
            <img src={CloudUpload} alt="" />
          </p>
        )}
        <p className="ant-upload-text">
        {uploadedFile?.name||""}
        </p>
        <p className="ant-upload-text">
          <Button
            type="primary"
            shape="round"
            size={uploadedFile ? "small" : "large"}
            style={{ background: "#345AB3", borderColor: "#345AB3" }}
          >
            {uploadedFile
              ? "Seleccionar otro archivo"
              : "Seleccione un archivo a subir"}
          </Button>
        </p>
        {!uploadedFile && (
          <p className="ant-upload-hint" style={{ marginTop: "15px" }}>
            (XLS, XLSX Máximo 100KB)
          </p>
        )}
      </Dragger>
    </div>
  );
};

export default Uploader;
