import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import Form from '@rjsf/material-ui';
import Swal from 'sweetalert2';
import { Select, Button, Modal, Empty, message } from 'antd';
import { getItems } from '../../helpers/user/getItems';
import { postMicrodata } from '../../helpers/user/postMicrodata';
import { buildUiSchema } from '../../services/buildUiSchema.service';
import { transformError } from '../../services/transformError';
import './styles/schemaUpload.css'

export const SchemaUpload = () => {

    const [schemas, setSchemas] = useState([]);
    const [schemaForm, setSchemaForm] = useState();
    const [dataQuery, setDataQuery] = useState();
    const [agreement, setAgreement] = useState(null);
    const [errorsList, setErrorsList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {agreements} = useSelector(state => state.auth);
    const memoAbortController = useMemo(() => new AbortController(), [] );

    const { Option } = Select;
    
    const handleOk = () => {
    setIsModalVisible(false);
    };

    const handleCancel = () => {
    setIsModalVisible(false);
    };

    //TODO: eliminar schema convenio de la lista
    useEffect(() => {
        getItems(memoAbortController).then((values) =>{ 
            if(values){
                setDataQuery(values.dataQuery);
                const recordObtained = values.items.filter(data => data.code !== "5-184A");
                setSchemas(recordObtained);
            }
        }).catch( err => console.error);

        return () => {
            memoAbortController.abort()
          }

    }, [memoAbortController]);

    const handlerOnChamgeSchema = (e) => {
        const listFormDataKey = Object.keys(e.formData);
        const  {properties} = e.schema;
        let uiSchema = {...e.uiSchema};

        listFormDataKey.forEach((key) =>{
            properties[key] = {
                ...properties[key],
                default: e.formData[key]
            }
        });

        e.uiSchema = {...uiSchema}

        const currentSchema = JSON.stringify(schemaForm) ;
        const newSchema = JSON.stringify(e);

        if(currentSchema !== newSchema){
            setSchemaForm({...e});
        }
    }

    const onChangeAgreement = (event) => {
        const [agreementFiltered] = agreements.filter(data => data.code === event);
        
        if(agreementFiltered?.infrastructures){
            setAgreement(agreementFiltered);
            setupForm(agreementFiltered.program, agreementFiltered.infrastructures);
        }
        else{
            message.warn("El convenio o el usuario no tiene infraestructuras asignagnadas");
        }
    }

    const setupForm = (archive, infraestructures) =>{
        const [resp] = schemas.filter(item => item.archive === archive);
        const rulesKey = Object.keys(resp.rules);
        const rules = resp.rules;
        let uiSchema = buildUiSchema(rulesKey, rules);
        if(resp.properties["Nombre Infraestructura"]){
            resp.properties["Nombre Infraestructura"].enum = infraestructures;
        }

        setSchemaForm({
            schema:{
                "archive":resp.archive,
                "codeSchema": resp.code,
                "title": resp.archive,
                "type":"object",
                "properties":resp.properties,
                "required": resp.required
            },
            uiSchema:{
                ...resp.uiSchema,
                ...uiSchema
            }
        });
    }
    
    const handlerSubmit = (e) =>{

        let {formData} = e;
        let params = {};
        //==== busca regla de generar codigo y los adiciona a sus respectivos campos ===//
        const [schemaChoosed] = schemas.filter(item => item.archive === e.schema.archive);

        const listSchemaKey = Object.keys(schemaChoosed.rules);
        listSchemaKey.forEach(value =>{
            if(schemaChoosed.rules[value].rules.listado){
                if(schemaChoosed.rules[value].rules.listado.generateCode && schemaForm.schema.properties[value].enumNames){

                    const index = schemaForm.schema.properties[value].enum.indexOf(formData[value]);
                    const resultNames = schemaForm.schema.properties[value].enumNames[index];
                    const valueName = schemaChoosed.rules[value].rules.listado.generateCode;
                    if(formData[value]){
                        formData = {...formData, 
                            [valueName]: formData[value],
                            [value]:resultNames
                        }
                    }
                }else if(schemaForm.schema.properties[value].enumNames){
                    const index = schemaForm.schema.properties[value].enum.indexOf(formData[value]);
                    const resultNames = schemaForm.schema.properties[value].enumNames[index];
                    if(resultNames){
                        formData = {...formData, 
                            [value]:resultNames
                        }
                    }
                }
            }
            
            if(schemaChoosed.rules[value].rules.especial){
                if(schemaChoosed.rules[value].rules.especial.generateField){
                    const listOfFields = schemaChoosed.rules[value].rules.especial.generateField;
                    const infrastructureFiltered = dataQuery.infrastructures.filter(item => item.tradeName === formData[value]);
                    listOfFields.forEach(dataField =>{
                        formData = {
                            ...formData,
                            [dataField.fieldsOfForm]:infrastructureFiltered[0][dataField.fieldsOfInfrastructure]
                        }
                    })
                }
            }

            formData["Codigo Institucion"] = parseInt(formData["Codigo Institucion"]);
        });
    
            const idNumber = formData["Nro Identificacion"];
            delete formData["Nro Identificacion"];
            
            params = {
                "Nro Identificacion": idNumber,
                schemaCode:schemaChoosed.code,
                date:{createdAt: new Date().toISOString()},
                properties: {...formData, "ID Convenio":agreement.code, "Nro Identificacion": idNumber},
                rules: schemaChoosed.rules
            }

        Swal.fire({
            title: 'Microdato',
            text:"¿ Desea guardar los registros ?",
            footer:"<small>Recuerde que los campos obligatorios solo se pueden cambiar a traves de una solicitud</small>",
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: `Cancelar`,
            confirmButtonText: `Guardar`,
            denyButtonText: `No Guardar`,
          }).then((result) => {
            if (result.isConfirmed) {
                postMicrodata(params)
                .then((resp) => {
                    if(resp.status){
                        Swal.fire('Guardado!', "Guardado Exitoso", 'success')
                        //limpia formulario
                        setSchemaForm();
                        const listFormDataKey = Object.keys(schemaForm.formData);
                        const  {properties} = schemaForm.schema;
                        listFormDataKey.forEach((key) =>{
                            delete properties[key].default
                        });
                        setSchemaForm({...schemaForm, properties});
                        setErrorsList([]);
                    }
                    else{
                        Swal.fire('Error!',"'"+ resp.Error + "'", 'error');
                        if(resp?.listErrors){
                            setErrorsList(resp.listErrors);
                            setIsModalVisible(true);
                        }
                    } 
                })
            } else if (result.isDenied) {
              Swal.fire('No Guardado', '', 'info');
            }
        });
    }

    return (
        
        <div className="containerEditSchema animate__animated animate__fadeIn">
            {
                (schemas.length > 0 ) && 

                <div className="boxFormLeft">
                    <div className="formControl">
                        <label htmlFor="agreementID">Convenio</label>
                        <Select
                            id="agreementID"
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Selecciona un convenio"
                            optionFilterProp="children"
                            onChange={onChangeAgreement}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {agreements.map((value) =>
                                (<Option key={value.code} value={value.code}>{value.name}</Option>)
                            )}
                        </Select>

                    </div>

                </div>
            }
                
            <div className="boxFomRight">
                {(schemaForm && agreement) ? 
                    <Form 
                    noHtml5Validate={true}
                    uiSchema={schemaForm.uiSchema} 
                    schema={schemaForm.schema} 
                    onSubmit = {handlerSubmit} 
                    onChange = {handlerOnChamgeSchema}
                    autoComplete="off"
                    transformErrors={transformError}
                    showErrorList={false}>
                        <Button
                            type="primary"
                            shape="round"
                            htmlType="submit"
                            size="large"
                            style={{
                                width: "100%",
                                background: "#345AB3",
                                borderColor: "#345AB3",
                                marginTop: "50px"
                            }}
                            >
                            Cargar
                        </Button>
                    </Form>
                :  <Empty imageStyle={{
                    height: 250,
                    }} 
                    description={<span>No hay datos</span>} />
                
                }
            </div>

            <Modal title="Politicas incumplidas" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

                {
                    errorsList.map((err,i) =>
                        ( <p key={i}>* {err}</p>)
                    )
                }
            </Modal>
          
        </div>
    )
}


