import React, { Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { UserPage } from '../pages/user/UserPage'

export const UserRouter = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path="/user/microdata" component={UserPage} />
                <Route exact path="/user/updatemicrodata" component={UserPage} />
                <Route exact path="/user/agreements" component={UserPage} />
                <Route exact path="/user/list/beneficiary/transfer/requets" component={UserPage} />
                <Route exact path="/user/beneficiary/transfer/request/:id" component={UserPage} />
                <Redirect to="/" />
            </Switch>    
        </Fragment>
    )
}
