import { Check, ChevronRightOutlined, Clear, ExpandLess, ExpandMore } from '@material-ui/icons'
import {
    Box, Button, Card, CardActions, CardContent,
    CardHeader, Container, Typography, FormControl,
    InputLabel, OutlinedInput, Collapse, List,
    ListItemText,
    Divider
} from '@material-ui/core'

import { deepOrange } from '@material-ui/core/colors'
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ListItem } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Alert } from '@material-ui/lab'
import { isEmpty } from 'lodash'
import { Badge, message, Spin } from 'antd'
import moment from 'moment'

import { getRequestBeneficiaryById, putRequestBeneficiary } from '../../helpers/admin/getRequestBeneficiary'
import { types } from '../../types/types'
import { traslateStateItem } from '../../services/traslateStateName.service'


export const TypeRequest = (type, state = '') => {

    const stateTranslated = (state === 'active') ? 'Activo' : 'Trasladado'
    switch (type) {
        case 'TRASLATE':
            return 'Traslado.'
        case 'CHANGE':
            return 'Modificación.'
        case 'DELETE':
            return `Eliminación (${stateTranslated}).`
        default:
            break
    }
}

export const BeneficiaryTextAreaPanel = ({ state, onComment }) => {

    const [showMessage, setShowMessage] = useState(false);
    const [comment, setComment] = useState('');

    const onCommentAction = (state) => {
        setShowMessage(false)
        if (!isEmpty(comment)) {
            onComment(comment, state)
        }
        else setShowMessage(true)
    }

    if (state !== 'IN_PROGRESS') return null

    return (
        <Card>
            <CardContent>
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                        Comentario de atención
                    </InputLabel>
                    <OutlinedInput
                        onChange={evt => setComment(evt.target.value)}
                        id="outlined-adornment-amount"
                        value={comment}
                        labelWidth={60}
                        multiline
                    />
                </FormControl>
            </CardContent>
            <BeneficiaryButtonPanel
                onAction={onCommentAction}
                state={state}
            />
            {
                showMessage &&
                <Alert severity="info">El comentario es obligatorio para dar un estado a la petición</Alert>
            }
        </Card>
    )
}

export const BeneficiaryButtonPanel = ({ state, onAction }) => {

    const { role } = useSelector(state => state.auth);

    const onActionButtonPanel = (action) => {
        onAction(action)
    }

    if (state === 'IN_PROGRESS' && role === types.TOTAL_CONTROL_ROLE.name) return (
        <CardActions disableSpacing>
            <Button
                onClick={() => onActionButtonPanel('ACCEPTED')}
                variant='outlined'
            >
                <Check />
                Aceptar
            </Button>
            <Button
                onClick={() => onActionButtonPanel('REJECTED')}
                variant='outlined'
            >
                <Clear />
                Rechazar
            </Button>
        </CardActions>
    )
    else return null
}

export const BeneficiaryCardDetail = ({ name, date, comment, properties, state }) => {

    const [showCollapse, setShowCollapse] = useState(false)

    return (
        <div className="animate__animated animate__fadeIn">
            <CardHeader
                title={
                    <>
                        <p>{name}</p>
                        <small>{TypeRequest(state, properties?.currentRecord?.Estado)}</small>
                    </>
                }
                subheader={date}
            />
            <Divider />
            <CardContent>
                <Typography variant="body1" color="textPrimary" align='justify' component="p">
                    {comment}
                </Typography>
            </CardContent>
            <Divider />
            {
                properties &&
                <CardContent>
                    <List>
                        <ListItem button onClick={() => setShowCollapse(!showCollapse)}>
                            <ListItemText primary="Ver mas" />
                            {showCollapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={showCollapse} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    Object.keys(properties.currentRecord).map(
                                        (key, i) =>
                                            <>
                                                <ListItem key={i}>
                                                    <ListItemText
                                                        primary={key}
                                                        secondary={
                                                            <>
                                                                <span>{properties.currentRecord[key]}</span>
                                                                {
                                                                    properties.newRecord[key] &&
                                                                    properties.currentRecord[key] !== properties.newRecord[key] &&
                                                                    <span style={{ color: deepOrange[500] }}>
                                                                        <ChevronRightOutlined />
                                                                        <span>{properties.newRecord[key]}</span>
                                                                    </span>
                                                                }
                                                            </>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider />
                                            </>
                                    )
                                }
                            </List>
                        </Collapse>
                    </List>
                </CardContent>
            }
        </div>
    )
}

export const BeneficiaryTransferRequestDetail = () => {

    const [BeneficiaryRequest, setBeneficiaryRequest] = useState()
    const { name } = useSelector(state => state.auth);
    const [update, setUpdate] = useState(null);
    const { params } = useRouteMatch()

    useEffect(
        () =>
            getRequestBeneficiaryById(+params.id)
                .then(data => { setBeneficiaryRequest(data.Item) }),
        [params]
    )

    useEffect(
        () => {
            if (update) {
                putRequestBeneficiary(update)
                    .then(info => {

                        if (info?.data) {

                            setBeneficiaryRequest({
                                ...BeneficiaryRequest,
                                ...info.data
                            });
                            message.success("Operación exitosa");
                        }

                        if (info?.error) message.error(info.message);

                    })
                    .finally(() => setUpdate(null))
                    .catch((err) => message.error(JSON.stringify(err)));
            }
        },
        [BeneficiaryRequest, update]
    )

    if (!BeneficiaryRequest) {
        return (
            <div style={{ display: "flex", justifyContent: "center", padding: 40 }}>
                <div>
                    <Spin style={{ fontSize: 30, color: "#F16522", fontWeight: "bold" }} size="large" tip="Cargando..." />
                </div>
            </div>
        )
    }

    const requestDate = moment(BeneficiaryRequest.requestDate)

    return (
        <div style={{ paddingBottom: 45 }}>
            <Container fixed>
                <Box my={3}>
                    <Badge.Ribbon text={traslateStateItem(BeneficiaryRequest.state)}>
                        <Card>
                            <BeneficiaryCardDetail
                                date={requestDate.format('YYYY-MM-DD HH:mm:ss')}
                                comment={BeneficiaryRequest.requestComment}
                                properties={BeneficiaryRequest.properties}
                                name={BeneficiaryRequest.userRequesting}
                                state={BeneficiaryRequest.typeRequest}
                            />
                            {
                                BeneficiaryRequest.state !== 'IN_PROGRESS' ?
                                    <Box ml={5}>
                                        <BeneficiaryCardDetail
                                            date={moment(BeneficiaryRequest.attentionDate).format('YYYY-MM-DD HH:mm:ss')}
                                            comment={BeneficiaryRequest.attentionComment}
                                            name={BeneficiaryRequest.userAttends}
                                        />
                                    </Box> :
                                    null
                            }
                        </Card>
                    </Badge.Ribbon>
                </Box>
                <Box>
                    <BeneficiaryTextAreaPanel
                        state={BeneficiaryRequest.state}
                        onComment={
                            (attentionComment, state) => {
                                setUpdate({
                                    schema: BeneficiaryRequest.program,
                                    requestId: +params.id,
                                    userAttends: name,
                                    attentionComment,
                                    state
                                })
                            }
                        }
                    />
                </Box>
            </Container>
        </div>
    )
}