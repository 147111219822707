import { Button, Card, Col, DatePicker, Form, Input, message, Row, Select, Tooltip } from 'antd'
import { getReport } from '../../helpers/reports'
import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import { find, isEmpty } from 'lodash'

export const ParameterizablReport = ({ report, schemas, onDownload }) => {
    const initialState = schemas[0];
    const { agreements } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false)
    const [schema, setSchema] = useState(initialState);
    const [fields, setFields] = useState(null)
    const [filter, setFilter] = useState(null)
    const [url, setUrl] = useState(null)
    const [form] = Form.useForm()

    const generateSchemasOptions = () =>{
        return schemas.map(schema => {
            if(schema.code !== "5-184A") return <Select.Option key={schema.code} value={schema.code}>{schema.archive}</Select.Option>
        });
    }

    const generateAgreementsOptions = () =>{
        return agreements.filter(item => item.program === schema.archive).map(data => <Select.Option key={data.code}>{data.name}</Select.Option>);
    } 
    

    const generateFilterOptions = () => {
        if (schema) {
            const keys = Object.keys(schema.properties)
            return keys.map(
                filter => <Select.Option key={filter}>{filter}</Select.Option>
            )
        }
        else return null
    }

    const onDownloadHandler = (url) => {
        if (onDownload && onDownload instanceof Function) {
            onDownload(url)
            setUrl(null)
        }
    }

    const onSubmitReport = async ({ schema, selected, IdConvenio }) => {
        
        const filters = isEmpty(filter) ? null : filter;

        if(!filters){
            message.info("Debe seleccionar al menos un fitro");
            return;
        }


        if (IdConvenio) filters['ID Convenio'] = IdConvenio;
        else{
            message.info("Debe seleccionar un convenio a filtrar");
            return;
        }

        setLoading(true);
        try {
            const response = await getReport(report, { schema, selected, filter: filters });
            if(response.status){
                const {url} = response;
                setLoading(false);
                setUrl(url);
            }else{
                message.warn((response?.message || response.Error));
                setLoading(false);
            }
            
        } catch (error) {
            setLoading(false);
            console.error(error);
            message.error(error);
        }
    }

    const onChangeSchema = (code) => {
        const schemaResult = find(schemas, { code })
        if (schemaResult) setSchema(schemaResult);
    }

    const onSelectedFilters = (data) => {
        const fields = {}
        data.forEach(
            element => {
                fields[element] = schema.properties[element]
            }
        )
        setFields(fields)
    }


    const onDeselectedFilter = (data) => {
        let aux = { ...fields }
        delete aux[data]
        setFields(aux)
        aux = { ...filter }
        delete aux[data]
        setFilter(aux)
    }

    const generateFilterForm = () => {
        const storage = []
        for (const index in fields) {
            const field = fields[index]
            const options = {
                label: field.title,
                name: index,
                key: index
            }

            switch (field.type) {
                case "string":
                    storage.push(
                        <Form.Item {...options}>
                            {
                                ['date', 'date-time'].includes(field.format) ?
                                    <DatePicker onChange={m => setFilter({ ...filter, [index]: m.toISOString() })} /> :
                                    <Input onChange={s => setFilter({ ...filter, [index]: s.target.value })}/>
                            }
                        </Form.Item>
                    )
                    break

                case "integer":
                case "number":
                    storage.push(
                        <Form.Item {...options}>
                            <Input type='number' onChange={n => setFilter({ ...filter, [index]: +n.target.value })}/>
                        </Form.Item>
                    )
                    break

                default:
                    break
            }

        }
        return storage
    }

    return (
        <Row className="animate__animated animate__fadeIn" style={{paddingBottom:45}}>
            <Col flex='300px'>
                <Card>
                    <Form
                        onFinish={onSubmitReport}
                        layout='vertical'
                        form={form}
                    >
                        <Form.Item
                            label='Programa'
                            name='schema'
                            initialValue = {schema.code}
                        >
                            <Select
                                optionFilterProp="children"
                                style={{ width: '300px' }}
                                onChange={onChangeSchema}
                                placeholder="Programas"
                                showSearch
                                
                            >
                                {generateSchemasOptions()}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label='Convenios'
                            name='IdConvenio'
                        >
                            <Select
                                optionFilterProp="children"
                                style={{ width: '300px' }}
                                onChange={onChangeSchema}
                                placeholder="Convenios"
                                showSearch
                            >
                                {generateAgreementsOptions()}
                            </Select>
                        </Form.Item>

                        {
                            schema &&
                            <Form.Item
                                label='Filtros'
                                name='filter'
                            >
                                <Select
                                    placeholder="Seleccionar filtros"
                                    onDeselect={onDeselectedFilter}
                                    onChange={onSelectedFilters}
                                    optionFilterProp="children"
                                    style={{ width: '300px' }}
                                    mode='multiple'
                                    allowClear
                                    showSearch
                                >
                                    {generateFilterOptions()}
                                </Select>
                            </Form.Item>
                        }

                        {
                            schema &&
                            
                            <Tooltip placement="rightTop" 
                            title="Dejar en blanco para mostrar todos los campos" 
                            color={"#FF9966"} 
                            key={"#FF9966"}>
                                <Form.Item
                                    label='Campos a mostrar'
                                    name='selected'
                                >
                                        <Select
                                            placeholder="Seleccionar campos a mostrar"
                                            optionFilterProp="children"
                                            style={{ width: '300px' }}
                                            mode='multiple'
                                            allowClear
                                            showSearch
                                        >
                                            {generateFilterOptions()}
                                        </Select>
                                </Form.Item>
                            </Tooltip>
                            
                        }

                        <Form.Item>
                            <Button
                                loading={loading}
                                htmlType="submit"
                                type="primary"
                            >
                                Generar informe
                            </Button>
                            {
                                (
                                    url &&
                                    <Button
                                        onClick={() => onDownloadHandler(url)}
                                        htmlType='button'
                                        type="link"
                                    >
                                        Descargar
                                    </Button>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            {
                !isEmpty(fields) &&
                <Col flex='300px'>
                    <Card>
                        <Form layout='vertical'>
                            {generateFilterForm()}
                        </Form>
                    </Card>
                </Col>
            }
        </Row>
    )
}