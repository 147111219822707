import { types } from "../types/types";

const initialState = { isAutenticated: false };

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.login:
            return {
                ...state,
                name: action.payload.name,
                role: action.payload.role,
                agreements: action.payload.agreements,
                isAutenticated: action.payload.isAutenticated,
                openYears: action.payload.openYears,
            }
        case types.updateOpenYears:
            return {
                ...state,
                openYears: action.payload.openYears
            }
        case types.logout:
            return initialState
        default:
            return state;
    }
}