import Swal from "sweetalert2";

export const messageModal = async ({formData, currentRange, schemaForm, currentRecord, typeRequest, name}) =>{  
  
  let properties = {
        currentRecord, 
        newRecord: {range:currentRange, ...formData, Edad:currentRecord.Edad}
  }

    const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Motivo de la solicitud',
        inputPlaceholder: 'Escribe una observación para la solicitud',
        inputAttributes: {
          'aria-label': 'Escribe tu mensaje aqui.'
        },
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`,
    });
    if(text !== undefined && text !== null){
      if (text.trim().length <= 0) {
          Swal.fire("Alerta!!","El mensaje no puede estar vacio", "warning");
          return null;
        }else{

          const fullName = `${currentRecord["Primer Nombre"]} ${currentRecord["Primer Apellido"]} ${(currentRecord["Segundo Apellido"]) || ""}`;

          return {
              requestId: new Date().getTime(),
              year:new Date().getUTCFullYear(),
              requestDate: new Date().toISOString(),
              program: (formData.program || schemaForm.code),
              userRequesting: name,
              requestComment: text,
              state: "IN_PROGRESS",
              typeRequest,
              "ID Convenio": currentRecord["ID Convenio"],
              "fullName": fullName,
              "Nro Identificacion": currentRecord["Nro Identificacion"],
              "Tipo Identificacion":currentRecord["Tipo Identificacion"],
              "range": currentRecord["range"],
              properties
          }
      }
    }
}