import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Select, Card, Button, Form, message} from "antd"
import { CloudUploadOutlined } from "@ant-design/icons"
import Uploader from "./Uploader"
import { putAssistanceFileMicrodata } from "../../../helpers/user/postMicrodata"
import { getQueueMessages } from "../../../helpers/user/getQueueMessages"
import { types } from "../../../types/types"
import { notificationComponent} from "../../generics/notificationComponent"
import "./styles/CompleteMicroData.css"

const AssistencesMicroData = () => {

  const { agreements, name: user, openYears } = useSelector(state => state.auth);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [urlDownload, setUrlDownload] = useState(null);

  const getAgreementsOptions = () =>
    agreements.map(agreement => (
      <Select.Option key={agreement.code} value={agreement.code}>
        {agreement.name}
      </Select.Option>
    ));

  const onDownloadReport = (uri = null) => {
    const link = document.createElement('a');
    link.href = (uri || urlDownload);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setUrlDownload(null);
  }

  const onSubmitForm = async ({ agreement }) => {

    if (!agreement || !uploadedFile) {
      message.info("Debe seleccionar un convenio y adjuntar un archivo");
      return;
    }

    const [result] = agreements.filter(data => data.code === agreement);

    if (!openYears.includes(result.year) || !result.year) {
      result.year ? message.info(`Año y convenio no habilitado para edición (${result.year})`) : message.info(`Reasginar convenio para poder ver el año cerrado`);
      return;
    }

    setLoading(true);
    try {
      const requestCode = new Date().getTime().toString();
      const form = new FormData();

      let [agreementFiltered] = agreements.filter(data => data.code === agreement);
      let schema = null;

      if (agreementFiltered.program === "Atención Integral a la Niñez") schema = "5-186A";

      if (agreementFiltered.program === "Jornada Escolar Complementaria") schema = "5-183A";

      form.append('agreement', agreement);
      form.append('file', uploadedFile);
      form.append('schema', schema);
      form.append('user', user);
      form.append('request_code', requestCode);


      putAssistanceFileMicrodata(form);

      const interval = setInterval(async () => {

        try {

          const { status, messages, error } = await getQueueMessages(user, requestCode, types.updateAsistances);


          if (status) {

            if (messages.length > 0) {
              const [firstMessage, SecondMessage] = messages;
              const messageToUse = SecondMessage || firstMessage;

              if (messageToUse.body.state !== 'PROCESSING') {
                setUploadedFile(null);
                setUrlDownload(messageToUse.body?.uri);
                closeInterval();
              }

              setLoading(false);
              notificationComponent({
                message: messageToUse.body,
                notificationKey: messageToUse.body.state,
                notificationType: messageToUse.body.state,
                onDownloadReport
              });
            }

          } else {
            closeInterval();
            console.error(error);
          }

        } catch (error) {
          console.error(error);
        }

      }, 10000);

      const closeInterval = () => {
        clearInterval(interval);
      }

    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="CompleteMicroDataContainer animate__animated animate__fadeIn">
      <Card className="leftCard">
        <Form
          onFinish={onSubmitForm}
          layout='vertical'
        >
          <Form.Item
            name='agreement'
            label='Seleccionar Convenio'
          >
            <Select
              className="inputForm"
              id="agreementID"
              showSearch
              placeholder="Selecciona un convenio"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getAgreementsOptions()}
            </Select>
          </Form.Item>
          <div className="uploadComponent">
            <Uploader
              setUploadFile={setUploadedFile}
              uploadedFile={uploadedFile}
            />
          </div>

          <Button
            htmlType='submit'
            loading={loading}
            type="primary"
            shape="round"
            icon={
              <CloudUploadOutlined
                style={{
                  fontSize: "20px",
                }}
              />
            }
            size="large"
            style={{
              borderColor: "#345AB3",
              background: "#345AB3",
              marginTop: "30px",
            }}
          >
            {(!loading) ? "Cargar" : "Cargando"}
          </Button>
        </Form>
      </Card>
    </div>
  )
}

export default AssistencesMicroData
