import { getToken } from "../services/HttpService";

export const getSchemaAgreement = async (abortController) =>{
    /* const endPonint = `https://${types.apiID}.execute-api.us-east-1.amazonaws.com/agreementSchema`; */
    const endPonint = `${process.env.REACT_APP_API}/agreementSchema`;
    const response = await getToken();

    return fetch(endPonint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        signal: abortController.signal,
        redirect: "follow"
    })
    .then(result => result.json())
    .then(resp => {return resp})
    .catch( err =>{
        if(err.name === 'AbortError') {return null};
        throw err
    });
    
}