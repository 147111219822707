import { ComboBox } from "../components/generics/autoCompleteField";
import { Tags } from "../components/generics/autoCompleteFIledMultiselect";

export const buildUiSchema = (keys, rules) => {

    let uiSchema = {};
            
    keys.forEach((key) =>{
        if(rules[key].rules.listado){
            const isAutoComplete = rules[key].rules.listado.autocomplete;
            const isRequired = (rules[key].rules.obligatorio)?true:false;
            if(isAutoComplete){
                uiSchema = {...uiSchema,
                    [key]:{
                        "ui:widget":(props) =>(<ComboBox  props={props} isRequired={isRequired} />)
                    }
                }
            }
        }  

        if(rules[key].rules.especial){
            const isAutoComplete = rules[key].rules.especial.autocomplete;
            const isMultiple = rules[key].rules.especial.multiple;
            const isRequired = (rules[key].rules.obligatorio)?true:false;
            if(isAutoComplete && !isMultiple){
                uiSchema = {...uiSchema,
                    [key]:{
                        "ui:widget":(props) =>(<ComboBox props={props} isRequired={isRequired} />)
                    }
                }
            }else if(isAutoComplete && isMultiple){
                uiSchema = {...uiSchema,
                    [key]:{
                        "ui:widget":(props) => (<Tags  props={props} />)
                    }
                }
            }
        }   
    });

    return uiSchema;
   
}
