import React, { useState, useEffect } from 'react'
import { Container } from '@material-ui/core'
import { Tabs } from 'antd'

import { ParameterizablReport } from './ParameterizableReport'
import { reports } from '../../data/reportsType.json'
import { getSchemas } from '../../helpers/reports'
import { DefaultReport } from './DefaultReports'
import { find } from 'lodash-es'

export const ReportsContainer = () => {

    const [schemas, setSchemas] = useState([])

    const onDownloadReport = (url) => {
        const link = document.createElement('a')
        link.href = url
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
    }

    useEffect(
        () => {
            if (schemas.length === 0)
                getSchemas().then(items => {
                    if(items){
                        const itemsFiltered = items.filter(data => data.code !== "5-184A");
                        setSchemas(itemsFiltered);
                    } 
                });
        },
        [schemas]
    )

    return (
        <Container fixed>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Reportes predeterminados" key="1">
                    <DefaultReport
                        onDownload={onDownloadReport}
                        reports={reports}
                        schemas={schemas}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Parametrización de reportes" key="2">
                    <ParameterizablReport
                        onDownload={onDownloadReport}
                        report={find(reports, { name: "Reporte de campos parametrizables" })}
                        schemas={schemas}
                    />
                </Tabs.TabPane>
            </Tabs>
        </Container>
    )
}