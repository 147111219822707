import { useState } from "react"


export const useForm = (initialState = {}) => {

    const [values, setValues] = useState(initialState)

    const reset = () =>{
        setValues(initialState);
    }

    const handleInputChange = (event) =>{

        if(event?.target){
            setValues({
                ...values,
                [event.target.name]:event.target.value
            });
        }else{
            setValues({
                ...values,
                "roleUser":event
            });
        }
    }

    return [values, handleInputChange, reset];
}
