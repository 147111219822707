import { getToken } from "../../services/HttpService"

export const getRequestBeneficiary = async () => {
    const response = await getToken();
    const resp = await fetch(
        `${process.env.REACT_APP_API}/list/beneficiary/transfer/request`,
        {
            headers: {
                "Authorization": `Bearer ${response.token}`
            },
            redirect: "follow"
        }
    )
    return await resp.json()
}

export const getRequestBeneficiaryById = async (requestId) => {
    const response = await getToken();
    const resp = await fetch(
        `${process.env.REACT_APP_API}/get/beneficiary/transfer/request/by/id?requestId=${requestId}`, {
        headers: {
            "Authorization": `Bearer ${response.token}`
        }
    }
    )
    return await resp.json()
}

export const getPaginationRequest = async (pageSize, lastpaginationToken) => {
    const response = await getToken();
    const resp = await fetch(
        `${process.env.REACT_APP_API}/list/beneficiary/transfer/request?pageSize=${pageSize}${lastpaginationToken ? '&paginationToken=' + lastpaginationToken : ''}`, {
        headers: {
            "Authorization": `Bearer ${response.token}`
        }
    }
    )
    return await resp.json()
}

export const putRequestBeneficiary = async (data) => {
    const endPoint = `${process.env.REACT_APP_API}/accepted-or-rejected/beneficiary/transfer/request`;

    const response = await getToken();
    const resp = await fetch(endPoint, {
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data),
        method: 'PUT',
        redirect: "follow"
    })

    return await resp.json();
}