import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Card, Space, Select, Button, Table, message, Input, version } from "antd";
import { getTablesList } from "../../../helpers/admin/getTablesList";
import { putTables } from "../../../helpers/admin/putTables";
import { buildRowsAndColumns } from "../../../services/buildRowsAndColumns.service";
import Swal from "sweetalert2";
import "./styles/CatalogList.css";
import { useSelector } from "react-redux";
import { messageModalGeneric } from "../../../services/messageModalgeneric.service";
import { traslateInfrastructureName } from "../../../services/traslateInfrastructureName.service";
import { getAllInfrastructures } from "../../../helpers/admin/getAllInfrastructures";
import { string } from "yup";
const { Option } = Select;

export const CatalogList = () => {
  const [programList, setProgramList] = useState();
  const [allTables, setAllTables] = useState([]);
  const [addMode, setAddMode] = useState(false);
  const [{ arrayColumns, arrayRows }, setTableProperties] = useState({ arrayColumns: [], arrayRows: [] });
  const [tableName, setTableName] = useState(null);
  const [elementsSelectedList, setElementsSelectedList] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [infraList, setInfraList] = useState([]);
  const { name } = useSelector(state => state.auth);
  const memoAbortController = useMemo(() => new AbortController(), []);
  const memoAbortController2 = useMemo(() => new AbortController(), []);

  const [state, setstate] = useState({
    searchText: '',
    searchedColumn: '',
  });


  const getListInfra = useCallback(() => {
    getAllInfrastructures(memoAbortController2).then((response) => {
      if (response !== null) {
        if (response.status) {
          setInfraList(response.items.allInfrastructures.map(obj => ({
            "tradeName": obj.tradeName,
            "uniqueCodeInfra": obj.uniqueCodeInfra
          })));
        } else {
          message.error(response.Error);
        }
      }
    }).catch(error => console.error(error));
  }, [memoAbortController2]);


  useEffect(() => {
    getListInfra();
    return () => {
      memoAbortController2.abort()
    }
  }, [memoAbortController2, getListInfra]);


  const getLists = useCallback(() => {

    getTablesList(memoAbortController).then((response) => {

      if (response.status) {
        const tables = response.items.tablesCatalog;
        const tablesForSelectControl = tables.map((data, i) => ({ "id": i, "name": data.table }))
          .filter(result => result.name !== "Modalidad");
        setAllTables(tables);
        setProgramList(tablesForSelectControl);
      } else {
        message.error(response.Error);
      }

    }).catch(error => console.error(error));

  }, [memoAbortController]);

  const rowsAndColumns = useCallback((listData) => {

    const { arrayColumns, arrayRows } = buildRowsAndColumns(listData, 150, "antd", state, setstate);
    return { arrayColumns, arrayRows };
  }, [state]);

  useEffect(() => {
    getLists();
    return () => {
      memoAbortController.abort()
    }
  }, [memoAbortController, getLists]);

  useEffect(() => {
    if (tableName) {
      const porpertiesByTable = allTables.filter(data => data.table === tableName);

      const { arrayColumns, arrayRows } = rowsAndColumns(porpertiesByTable[0].properties);
      if (tableName === "Sedes") {

      }
      const newArrayColumns = traslateInfrastructureName(arrayColumns);
      setTableProperties({ arrayColumns: newArrayColumns, arrayRows })
    }

  }, [tableName, allTables, rowsAndColumns]);



  const handleSelectable = (table) => {
    setElementsSelectedList({});
    setTableName(table);
  };

  const handleSubmit = () => {

    if (tableName === null) {
      message.error("Debe seleccionar una tabla a editar");
      return;
    }

    if (arrayRows.length <= 0) {
      message.error("La lista de propiedades esta vacia");
      return;
    }

    let listcontentSedes = (tableName === "Sedes") ? arrayRows.map(data => ({
      code: data.code, name: data.name, infra_tradeName: data.infra_tradeName,
      infra_uniqueCodeInfra: (data.infra_tradeName !== undefined) ? infraList.filter(obj => obj.tradeName === data.infra_tradeName)[0]?.uniqueCodeInfra : ""
    })) : "";
    let listContent =
      (tableName !== "Tipo Identificacion") ? arrayRows.map(data => ({ code: data.code, name: data.name }))
        : arrayRows.map(data => ({ code: data.code, name: data.name, abbreviation: data.abbreviation }));

    const request = (tableName !== "Sedes") ? {
      tableName,
      listContent,
      user: name
    } : {
      tableName,
      listContent: listcontentSedes,
      user: name
    }

    messageModalGeneric(request).then(request => {
      if (request) {
        Swal.fire({
          title: "Catalogo de tablas",
          text: `¿ Desea Editar la tabla ${tableName} ?`,
          footer: "<small>Hay reglas de validación que dependen de estas listas, se recomienda revisar la documentación antes de editar</small>",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Guardar`,
          denyButtonText: `No Guardar`,
        }).then(result => {
          if (result.isConfirmed) {
            putTables(request).then(response => {

              if (response.status) {
                Swal.fire("Tablas", "Guardado correctamente", "success");

                //se limpia formulario
                setTableName(null);
                setTableProperties({ arrayColumns: [], arrayRows: [] });
                setElementsSelectedList({});
                setSelectedRowKeys([]);
              } else {
                Swal.fire("Tablas", response.Error, "error");
              }

            }).catch(error => Swal.fire("Tablas", error, "error"));

          }
        });
      }
    });

  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setElementsSelectedList(selectedRows[0]);
      setAddMode(false);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handlerOnCHange = (event) => {

    if (typeof event !== "string") {
      if (event.target.name === "name")
        setElementsSelectedList({ ...elementsSelectedList, name: event.target.value });
      else if (event.target.name === "code")
        setElementsSelectedList({ ...elementsSelectedList, code: event.target.value });
      else
        setElementsSelectedList({ ...elementsSelectedList, abbreviation: event.target.value });

    } else if (typeof event === "string") {
      setElementsSelectedList({ ...elementsSelectedList, infra_tradeName: event });
    }
  }


  const handlerOnCLick = () => {

    if (elementsSelectedList.key === undefined) {
      message.error("No hay propiedades seleccionados");
      return;
    }

    if (!elementsSelectedList?.key.toString()) {
      message.error("No hay propiedades seleccionados");
      return;
    }

    if (elementsSelectedList.name.trim() === "") {
      message.error("Debe agregar un nombre para el campo");
      return;
    }

    if (tableName === "Tipo Identificacion" && elementsSelectedList?.abbreviation.trim() === "") {
      message.error("Debe agregar una abreviatura para el campo");
      return;
    }

    const newRows = [...arrayRows];
    let isCodeRepeat = false;

    //valida si el codigo a insertar ya existe
    const [row] = newRows.filter(data => data.code === elementsSelectedList.code)
      .flatMap(data => {
        isCodeRepeat = true;
        return { key: data.key, newRow: { ...newRows[data.key], name: elementsSelectedList.name, code: elementsSelectedList.code, abbreviation: elementsSelectedList.abbreviation, infra_tradeName: elementsSelectedList.infra_tradeName } }
      })

    if (row) newRows[row.key] = row.newRow;

    //valida si la abreviatura ya existe
    const abbreviationFinded = newRows.filter(data => data?.abbreviation && data.abbreviation === elementsSelectedList.abbreviation)
      .filter(data => data.code !== elementsSelectedList.code);

    if (abbreviationFinded.length > 0) {
      message.error(`La abreviaura ya existe para ${abbreviationFinded[0].name}`);
      return;
    }
    else if (!addMode) {
      (!isCodeRepeat) && (newRows[elementsSelectedList.key] = elementsSelectedList);
      setTableProperties({ arrayColumns, arrayRows: newRows });
    } else {
      elementsSelectedList.name = elementsSelectedList.name.trim();
      (!isCodeRepeat) && newRows.push(elementsSelectedList);
      setTableProperties({ arrayColumns, arrayRows: newRows });
      setElementsSelectedList({});
      setAddMode(false);
    }

    setSelectedRowKeys([]); //se borra selección del table
  }

  const handlerAddElement = () => {
    if (tableName === null) {
      message.error("Debe seleccionar una tabla a editar");
      return;
    }

    if (arrayRows.length <= 0) {
      message.error("La lista de propiedades esta vacia");
      return;
    }
    setAddMode(true);
    setElementsSelectedList({ key: arrayRows.length, code: (arrayRows.length + 1).toString(), name: "", abbreviation: "" });
  }
  const onSearch = (value) => {

  };

  return (
    <Fragment>
      <div className="CatalogListContainer animate__animated animate__fadeIn">
        <Card className="leftCard">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="textLabel">Selecciona una tabla</span>
            <Select
              id="agreementID"
              className="inputForm separated"
              showSearch
              placeholder="Tablas"
              optionFilterProp="children"
              onChange={handleSelectable}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {programList &&
                programList.map((programOption) => (
                  <Option key={programOption.id} value={programOption.name}>
                    {programOption.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div>
            <span className="textLabel">Propiedades</span>
            <div className="tableContainer">
              <Table
                className="tableScrollable"
                dataSource={arrayRows}
                columns={arrayColumns}
                bordered
                rowSelection={{ type: "radio", ...rowSelection, selectedRowKeys }}
                scroll={{ y: 500 }}
              />
            </div>
          </div>

          <div className="footerButtons">
            <Button
              type="primary"
              shape="round"
              onClick={handleSubmit}
              size="medium"
              className="buttonBlue"
              style={{
                marginRight: "30px",
              }}
            >
              Guardar
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={handlerAddElement}
              size="medium"
              className="buttonBlue"
            >
              Agregar
            </Button>
          </div>

        </Card>


        <Card className="rightCard">
          <Space direction="vertical">
            <p className="textLabelUpload separated">
              Editar / agregar variable
            </p>

            <span className="textLabel">Código</span>

            <Input autoComplete="off"
              name="code"
              style={{ minWidth: "280px" }}
              onChange={handlerOnCHange}
              value={(elementsSelectedList?.code || "")} />

            <span className="textLabel">Nombre</span>

            <Input
              autoComplete="off"
              name="name"
              value={(elementsSelectedList?.name || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ minWidth: "280px" }}
            />

            {(tableName === "Tipo Identificacion") && <span className="textLabel">Abreviatura</span>}

            {(tableName === "Tipo Identificacion") && (
              <Input
                autoComplete="off"
                name="abbreviation"
                value={(elementsSelectedList?.abbreviation || "")}
                onChange={handlerOnCHange}
                className="separated"
                style={{ minWidth: "280px" }}
              />)}
            {(tableName === "Sedes") && <span className="textLabel">Asignar Infraestructura</span>}
            {(tableName === "Sedes") && (
              <Select
                placeholder="Selecciona una infraestructura"
                name="infra_tradeName"
                value={(elementsSelectedList?.infra_tradeName || "")}
                onSearch={onSearch}
                onChange={handlerOnCHange}

                optionFilterProp="children"
                showSearch
                className="separated"

                style={{ minWidth: "280px" }}

                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {infraList &&
                  infraList.map((v) => (
                    <Option key={v.uniqueCodeInfra} value={v.tradeName}>
                      {v.tradeName}
                    </Option>
                  ))}
              </Select>
            )}
            <Button
              type="primary"
              shape="round"
              onClick={handlerOnCLick}
              size="medium"
              className="buttonBlue"
            >
              {(addMode) ? "Inserta" : "Guardar"}
            </Button>
          </Space>
        </Card>


      </div>
    </Fragment>
  );
};
