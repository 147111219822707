import { getToken } from "../../services/HttpService";

export const getAllInfrastructures = async (abortController) =>{

    const endPonint = `${process.env.REACT_APP_API}/infrastructures`;
    const response = await getToken();

    return fetch(endPonint,
        {
            headers: {
                Authorization: `Bearer ${response.token}`,
                "Content-Type": "application/json"
            },
            signal: abortController.signal,
    })
    .then(result => result.json())
    .then(resp => { return resp})
    .catch( err =>{
        if(err.name === 'AbortError') {return null};
        throw err
    });
}