export const traslateStateItem = (state) => {
    switch (state) {
        case "REJECTED":
            return "RECHAZADO";
        case "ACCEPTED":
            return "ACEPTADO";
        case "IN_PROGRESS":
            return "EN PROGRESO";
        default:
            return ""
    }
}