import Swal from "sweetalert2";

export const messageModalGeneric = async (request) =>{


    const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Motivo del cambio',
        inputPlaceholder: 'Escribe una observación para la Edición',
        inputAttributes: {
          'aria-label': 'Escribe tu mensaje aqui.'
        },
        showCancelButton: true
    });
    if(text !== undefined && text !== null){
      if (text.trim().length <= 0) {
          Swal.fire("Alerta!!","El mensaje no puede estar vacio", "warning");
          return null;
        }else{
          request = {...request, message:text}
          return request;
      }
    }
}