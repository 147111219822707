import React, {useState, useEffect, useCallback, useMemo } from 'react'
import { types } from '../../types/types';
import { useForm } from '../../hooks/useForm';
import { useSelector } from 'react-redux';
import { buildRowsAndColumns } from '../../services/buildRowsAndColumns.service';
import { createNewUser } from '../../helpers/admin/postNewUser';
import { Table, message, Button, Select, Input } from 'antd';
import Swal from 'sweetalert2';
import { getAgreements } from '../../helpers/getAgreements';
import './styles/createUsers.css'

export const CreateUsers = (props) => {

    const {role} = useSelector(state => state.auth);
    
    const [state, setstate] = useState({
        searchText: '',
        searchedColumn: '',
    });

    const [{arrayColumns, arrayRows}, setColumnsAndRows] = useState({arrayColumns:[], arrayRows:[]});

    const [values, handlerInputChange, reset] = useForm({
        name:"",
        userId:"",
        roleUser: types.REGISTER_CONTROL_ROLE.name,
        email:""
    });
    
    const {name, userId, roleUser, email} = values;
    const [agrementsSelecteds, setAgrementsSelecteds] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const { Option } = Select;
    const memoAbortController = useMemo(() => new AbortController(), [] );

    
    const rowsAndColumns = useCallback( (listData) =>{
        const {arrayColumns, arrayRows} = buildRowsAndColumns(listData, 150, "antd", state, setstate);
        return { arrayColumns, arrayRows};
    }, [state]); 
    

    const fetchAgreements = useCallback( async () =>{

        getAgreements({role}, memoAbortController).then( allAgreements =>{
            if(allAgreements !== null){

                if(allAgreements.items.length <= 0){
                    message.info("No hay convenios creados");
                    return
                }

                const listAgreementsCleaned =  allAgreements.items.map(agreement => ({
                    "ID Convenio": agreement["ID Convenio"],
                    "Nombre Convenio": agreement["Nombre Convenio"], 
                    "ID Programa": agreement["ID Programa"],
                    "Municipio": agreement["Municipio"],
                    "Programa": agreement["ID Programa"],
                }))
                
                const {arrayColumns, arrayRows} = rowsAndColumns(listAgreementsCleaned);
                setColumnsAndRows({arrayColumns, arrayRows});
            }
        }).catch(error => console.error(error));

    }, [role, memoAbortController, rowsAndColumns]);
    
    useEffect(() => {
        fetchAgreements();

        return () =>{
            memoAbortController.abort();
        }

    }, [fetchAgreements, memoAbortController]);


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setAgrementsSelecteds(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    const handlerOnSubmit = (e) =>{
        e.preventDefault();
        
        if(userId.trim() ==="" || name.trim() ==="" || email.trim() === ""){
            message.error("Hay Campos Vacios");
            return
        } 

        if(agrementsSelecteds.length <= 0 && roleUser === types.REGISTER_CONTROL_ROLE.name){
            message.error("Debe haber almenos un convenio seleccionado");
            return
        }
        
        let listAgreements
        
        listAgreements = agrementsSelecteds.map(agreement => ({code:agreement["ID Convenio"]})); 
        
        if(roleUser !== types.REGISTER_CONTROL_ROLE.name) listAgreements = [];
        
        const request = {
            userId:userId.trim(),
            name:name.trim(),
            email:email.trim(),
            role: roleUser,
            state: "active",
            agreements: listAgreements
        }

        Swal.fire({
            title: 'Usuarios',
            text: '¿ Desea Crear este usuario ?',
            footer:"<small>Recuerda que cada usuario creado debe autenticarse tambien en el directorio activo</small>",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Crear`,
            denyButtonText: `No Crear`,
          }).then((result) => {
            if (result.isConfirmed) {
                createNewUser(request)
                .then((response) => {
                    if(response.status){
                        Swal.fire('Guardado!', "Guardado Exitoso", 'success');
                        reset();
                        setSelectedRowKeys([]);
                        setAgrementsSelecteds([]);
                    }
                    else{
                        Swal.fire('Error!',"'"+ response.error?.message + "'", 'error');
                        reset();
                        setSelectedRowKeys([]);
                        setAgrementsSelecteds([]);
                    } 
                })
            } else if (result.isDenied) {
              Swal.fire('No Guardado', '', 'info');
            }
        }).catch(error =>  Swal.fire('Error!',"'"+ error + "'", 'error'));
    }

    return (
            <div className="divCreateUser animate__animated animate__fadeIn">
                <form className="createUserForm" id="createUserForm" autoComplete="off" onSubmit={handlerOnSubmit}>

                <div className="allFomrGroup">
                    
                    <div className="formGroup">
                        <label htmlFor="userId">Usuario de Aplicación*</label>
                        <Input 
                        required
                        id = "userIdControl"
                        className="userControl"
                        name="userId"
                        value={userId}
                        onChange={handlerInputChange}
                        placeholder="Usuario de aplicación" />
                    </div>   

                    <div className="formGroup">
                        <label htmlFor="name">Nombre de usuario*</label>
                        <Input 
                        required
                        id = "userNameControl"
                        className="userControl"
                        name="name"
                        value={name}
                        onChange={handlerInputChange}
                        placeholder="Jhon Dane" />

                    </div>

                    <div className="formGroup">
                        <label htmlFor="email">Correo Corporativo*</label>
                        <Input 
                        required
                        id = "userEmailControl"
                        className="userControl"
                        name="email"
                        value={email}
                        onChange={handlerInputChange}
                        placeholder="nombre@compensar.com" />

                    </div>

                    <div className="formGroup">
                    <label htmlFor="userRole">Usuario de Registro*</label>
                        <Select className="userControl" id="userRole" value={roleUser} name="roleUser" onChange={handlerInputChange}>
                            <Option value={types.REGISTER_CONTROL_ROLE.name}>{types.REGISTER_CONTROL_ROLE.pseudoName}</Option>
                            <Option value={types.GLOBAL_CONTROL_ROLE.name}>{types.GLOBAL_CONTROL_ROLE.pseudoName}</Option>
                            <Option value={types.TOTAL_CONTROL_ROLE.name}>{types.TOTAL_CONTROL_ROLE.pseudoName}</Option>
                        </Select>
                    </div>

                </div>
                    <Button
                        type="primary"
                        shape="round"
                        onClick={handlerOnSubmit}
                        size="large"
                        style={{
                            width: 150,
                            background: "#345AB3",
                            borderColor: "#345AB3",
                            marginTop: "30px",
                            marginLeft:"45px"
                        }}
                        >
                        Cargar
                    </Button>

                </form>
        
        
                {
                    (roleUser === types.REGISTER_CONTROL_ROLE.name) &&
                    (<Table rowSelection={{
                        type:"checkbox",
                        ...rowSelection, 
                        selectedRowKeys
                    }} columns={arrayColumns} dataSource={arrayRows} style={{marginTop:62, marginLeft:45, marginRight:45}} scroll={{ y: 240 }} />)
                }
            </div>
    )
}
