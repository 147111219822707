import {getToken } from "../services/HttpService";

const endPonint = `${process.env.REACT_APP_API}/agreementlist`;

export const getAgreements = async (role, abortController) =>{

    const response = await getToken();

    return fetch(endPonint,
    {
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method:'POST',
        body: JSON.stringify(role),
        redirect: "follow"
    }).then(result => result.json())
    .then(resp => {
        return resp;
    }).catch( err =>{
        if(err.name === 'AbortError') {return null};
        throw err
    });

}
