import React from 'react';
import { ConfigProvider, Tabs } from 'antd';
import esES from 'antd/es/locale/es_ES';
import 'antd/dist/antd.css';


export const TabSelect = ({listContentTab}) => {

  const { TabPane } = Tabs;
 
  return (
    <Tabs type="line" defaultActiveKey="0" style={{paddingLeft:10}}>
        {
           listContentTab.map((data, i) =>(
              <TabPane tab={data.title} key={i}>
                {<ConfigProvider locale={esES}>< data.component /></ConfigProvider>}
              </TabPane>
           ))
        }
    </Tabs>
  
  );
}
