import { Transfer } from 'antd';


export const TransferComponent  = ({source, handlerOnSetLastAgreements, targetKeys, setTargetKeys, selectedKeys, setSelectedKeys, titles }) => {
    
    const onChange = (nextTargetKeys, direction, moveKeys) => {
      handlerOnSetLastAgreements(nextTargetKeys, direction, moveKeys)
      .then(haveContinue => {
        (haveContinue) && setTargetKeys(nextTargetKeys)
      });
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    return (
    <Transfer
        dataSource={source}
        titles={[titles.left, titles.right]}
        listStyle={{
            width: 350,
            height: 300,
            marginLeft: 45,
            marginTop:60
          }}
        showSearch
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={item => item.title}
    />
    );
    
}