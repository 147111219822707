import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Select, Card, Button, Form, notification } from "antd"
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons"
import Uploader from "./Uploader"
import { putFileMicrodata } from "../../../helpers/user/postMicrodata"
import { types } from "../../../types/types"
import { getQueueMessages } from "../../../helpers/user/getQueueMessages"
import "./styles/CompleteMicroData.css"

const CompleteMicroData = () => {

  const { agreements, name: user } = useSelector(state => state.auth);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAgreementsOptions = () =>
    agreements.map(agreement => (
      <Select.Option key={agreement.code} value={agreement.code}>
        {agreement.name}
      </Select.Option>
    ));

  const onSubmitForm = async ({ agreement }) => {
    setLoading(true);
    const requestCode = new Date().getTime().toString();
    const form = new FormData()
    let [agreementFiltered] = agreements.filter(data => data.code === agreement);
    let schema = null;
    if (agreementFiltered.program === "Atención Integral a la Niñez") schema = "5-186A";
    else if (agreementFiltered.program === "Jornada Escolar Complementaria") schema = "5-183A"
    form.append('agreement', agreement);
    form.append('file', uploadedFile);
    form.append('schema', schema);
    form.append('user', user);
    form.append('request_code', requestCode);
    console.log(agreement)
    try {

      putFileMicrodata(form);

      const interval = setInterval(async () => {

        ;

        try {

          const response = await getQueueMessages(user, requestCode, types.updateFields);

          if (response.status) {

            if (response.messages.length === 1) {

              if (response.messages[0]?.Recived) {
                setLoading(false);

                notification.open({
                  duration: 0,
                  message: `Procesando archivo`,
                  description: `${response.messages[0].Recived.StringValue}`,
                  key: "1",
                  icon: <LoadingOutlined />,
                });

              } else {
                setLoading(false);
                closeInterval();
                notification.open({
                  style: {
                    width: 300,
                  },
                  onClose: notification.close("DOWNLOAD_NOTIFICATION"),
                  duration: 0,
                  message:
                    `Carga de registros completada`,
                  description:
                    `Actualización finalizada correctamente: 
                       Fallidos: ${response.messages[0].body.failed} \n
                       Guardados: ${response.messages[0].body.saved} \n
                       Total: ${response.messages[0].body.total} `
                });
              }
            }

            if (response.messages.length === 2) {
              setLoading(false);
              closeInterval();
              notification.open({
                style: {
                  width: 300,
                },
                onClose: notification.close("DOWNLOAD_NOTIFICATION"),
                duration: 0,
                message:
                  `Carga de registros completada`,
                description:
                  `Actualización finalizada correctamente: 
                    Fallidos: ${response.messages[1].body.failed}\n
                    Guardados:  ${response.messages[1].body.saved}\n
                    Total:  ${response.messages[1].body.total}`
              });
            }

          }
        } catch (error) {
          console.error(error);
          closeInterval()
        }


      }, 10000);

      const closeInterval = () => {
        clearInterval(interval);
      }
      /* setCount(response.count) */
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="CompleteMicroDataContainer animate__animated animate__fadeIn">
      <Card className="leftCard">
        <Form
          onFinish={onSubmitForm}
          layout='vertical'
        >
          <Form.Item

            name='agreement'
            label='Seleccionar Convenio'
          >
            <Select
              className="inputForm separated"
              id="agreementID"
              showSearch
              placeholder="Selecciona un convenio"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getAgreementsOptions()}
            </Select>

            {/* <Select
              placeholder="Selecciona un convenio"
              className="inputForm separated"
            >
              {
                getAgreementsOptions()
              }
            </Select> */}

          </Form.Item>

          <div className="uploadComponent">
            <Uploader
              setUploadFile={setUploadedFile}
              uploadedFile={uploadedFile}
            />
          </div>

          <Button
            htmlType='submit'
            loading={loading}
            type="primary"
            shape="round"
            icon={
              <CloudUploadOutlined
                style={{
                  fontSize: "20px",
                }}
              />
            }
            size="large"
            style={{
              borderColor: "#345AB3",
              background: "#345AB3",
              marginTop: "30px",
            }}
          >
            {(!loading) ? "Cargar" : "Cargando"}
          </Button>
        </Form>
      </Card>
    </div>
  )
}

export default CompleteMicroData
