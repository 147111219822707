import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { find } from 'lodash-es'
import moment from 'moment'
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Table, Divider } from 'antd'
import { getReport } from '../../helpers/reports'
import { buildRowsAndColumns } from '../../services/buildRowsAndColumns.service'
import { types } from '../../types/types'
import { getQueueMessagesReport } from '../../helpers/reports/getQueueMessagesReport'

// poner estas opciones en reportsType.json si se aceptan reportes de auditorias
/* {
    "name": "Reporte auditoria infraestructuras",
    "service": "/infrastructureReportAudit",
    "method": "POST",
    "properties": {
        "year": "number"
    }
},
{
    "name": "Reporte auditoria Tablas",
    "service": "/tableCatalogReportAudit",
    "method": "POST",
    "properties": {
        "year": "number"
    }
},
{
    "name": "Reporte auditoria Convenios",
    "service": "/agreementReportAudit",
    "method": "POST",
    "properties": {
        "year": "number"
    }
} */

export const DefaultReport = ({ schemas, onDownload, reports }) => {

    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState(null);
    const [url, setUrl] = useState(null);
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [agreementsSelecteds, setAgreementsSelecteds] = useState([]);
    const [serchState, setserchState] = useState({
        searchText: '',
        searchedColumn: '',
    });
    const {agreements, role} = useSelector(state => state.auth);
    const [{arrayColumns, arrayRows}, setRowsAndColumns] = useState({arrayColumns:[], arrayRows:[]});

    useEffect(() => {
        const listAgreements = agreements.map(data => ({programa: data.program, codigo: data.code, nombre: data.name }));
        const {arrayColumns, arrayRows} = buildRowsAndColumns(listAgreements, 150, "antd", serchState, setserchState);

        setRowsAndColumns({arrayColumns, arrayRows});
    }, [serchState, setserchState ]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setAgreementsSelecteds(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        }
    };
    
    const onSubmitReport = async (values) => {
        setLoading(true);
        delete values.report;

        if (report) {
            if (values.year && values.year instanceof moment)
                values.year = values.year.year()
            try {
                const requestCode = new Date().getTime().toString();

                if(report.name === "Reporte de asistencias" || report.name === "Reporte de campos faltantes"){
                    (agreementsSelecteds.length > 0)?
                    values = {...values, agreements: agreementsSelecteds, requestCode}:
                    (function(){ message.warn("No hay convenios seleccionados"); setLoading(false) })();
                } 

                if(report.name === "Reporte de campos faltantes" && values.schema === undefined){
                    message.warn("Debe seleccionar un programa");
                    setLoading(false)
                    return;
                }

                if(report.name === "Reporte de campos faltantes" && values.agreements.length <= 0){
                    message.warn("Debe seleccionar almenos un convenio");
                    setLoading(false)
                    return;
                }

                if(report.name === "Reporte de beneficiario único" && !values.idBeneficiary ){
                    message.warn("Debe agregue el ID del beneficiario");
                    setLoading(false)
                    return;
                }

                if(typeof values.year !== "number"){
                    values={...values, year: new Date(values.year).getUTCFullYear()}
                }

                if(report.name === "Reporte de campos faltantes") values = {...values, year:new Date().getFullYear()}
          
                
                if(report.name === "Reporte de asistencias" || report.name === "Reporte de campos faltantes"){
                    getReport(report, values);
                    setSelectedRowKeys([]);
                    setAgreementsSelecteds([]);

                    const interval = setInterval(async() => {

                        try{
                            const messagesResponse = await getQueueMessagesReport(requestCode);
                            if(messagesResponse?.status){
                                if(messagesResponse.messages.length > 0){
                                    setUrl(messagesResponse.messages[0].uri.StringValue);
                                    setLoading(false);
                                    clearInterval(interval);
                                }
                            }else{
                                setLoading(false);
                                clearInterval(interval);
                                message.error(messagesResponse.Error);
                            }
                            
                        }catch(e){
                            setLoading(false);
                            clearInterval(interval);
                            message.error(e.message || e);
                        }
                    }, 5000)

                }else{
                    const response = await getReport(report, values);

                    if(response?.status){
                        setUrl(response.uri || response.url);
                        setSelectedRowKeys([]);
                        setAgreementsSelecteds([]);
                    }
                    else{
                        (response?.Error) && message.warning(response.Error);
                        (response?.message) && message.warning(response.message);
                    }
                }
            
            } catch (error) {
                console.error(error)
                message.error(error);
                setSelectedRowKeys([]);
                setAgreementsSelecteds([]);
            }
        }

        if(report.name !== "Reporte de asistencias" && report.name !== "Reporte de campos faltantes"){
            setLoading(false);
        }
    }

    const onDownloadHandler = (uri) => {
        if (onDownload && onDownload instanceof Function) {
            onDownload(uri)
            setUrl(null)
        }
    }

    const onChangeReport = (name) => {
        const reportFinded = find(reports, { name });
        if (reportFinded) setReport(reportFinded);
    }

    const generateTypeReports = () =>
        reports.map(rep =>{
                if(rep.properties){
                    if((role === types.REGISTER_CONTROL_ROLE.name) && rep.name === "Reporte de convenios") return null;
                    if((role === types.REGISTER_CONTROL_ROLE.name) && rep.name === "Reporte de novedades") return null;
                    // habilitar estas lineas si se haceptan reportes de auditorias
                    /* if((role === types.registerRole || role === types.globalRole ) && rep.name === "Reporte auditoria infraestructuras") return null;
                    if((role === types.registerRole || role === types.globalRole ) && rep.name === "Reporte auditoria Tablas") return null;
                    if((role === types.registerRole || role === types.globalRole ) && rep.name === "Reporte auditoria Convenios") return null; */

                    return <Select.Option key={rep.name}>{rep.name}</Select.Option> 
                }
            }
        )

    const generateSchemasOptions = () =>
        schemas.map(schema =>
            <Select.Option key={schema.code}>{schema.archive}</Select.Option>
        )

    const onChangeProgram = (value) =>{
        if(report.name === "Reporte de campos faltantes"){
            const listAgreements = agreements.map(data => ({programa: data.program, codigo: data.code, nombre: data.name }));
            const {arrayColumns, arrayRows} = buildRowsAndColumns(listAgreements, 150, "antd", serchState, setserchState);
            let auxArrayRows = [...arrayRows];
            const newRows = auxArrayRows.filter(data =>{
                if((data.programa === "Jornada Escolar Complementaria" && value === "5-183A") || (data.programa === "Atención Integral a la Niñez" && value === "5-186A") ){
                    return true;
                }
                return false;
            })
            setRowsAndColumns({arrayColumns, arrayRows:newRows});
        }
    }


    return (
        <Row className="animate__animated animate__fadeIn" style={{display: 'flex', justifyContent:"space-between"}}>
            <Col flex='300px'>
            <Divider orientation="left">Elige las opciones de reporte</Divider>
                <Form
                    onFinish={onSubmitReport}
                    form={form}
                >
                    <Form.Item
                        label='Tipo de reporte'
                        name='report'
                    >
                        <Select
                            placeholder="tipo de reporte"
                            optionFilterProp="children"
                            style={{ width: '300px' }}
                            onChange={onChangeReport}
                            showSearch
                        >
                            {generateTypeReports()}
                        </Select>
                    </Form.Item>

                    {
                        report && (report.properties.schema || report.properties.program) ?
                            <Form.Item
                                name={(report.properties.schema) ? 'schema' : 'program'}
                                label='Programas'
                            >
                                <Select
                                    optionFilterProp="children"
                                    style={{ width: '300px' }}
                                    placeholder="Programas"
                                    onChange={onChangeProgram}
                                    showSearch
                                >
                                    {generateSchemasOptions()}
                                </Select>
                            </Form.Item> :
                            null
                    }

                    {
                        report && report.properties.period ?
                            <Form.Item
                                label='Periodo'
                                name='period'
                            >
                                <Select
                                    optionFilterProp="children"
                                    style={{ width: '300px' }}
                                    placeholder="periodo"
                                    showSearch
                                >
                                    {
                                        report.properties.period.map(
                                            (value, key) =>
                                                <Select.Option key={key} value={value}>
                                                    {value}
                                                </Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item> :
                            null
                    }

                    {
                        report && report.properties.indicator ?
                            <Form.Item
                                label='Indicador'
                                name='indicator'
                            >
                                <Select
                                    optionFilterProp="children"
                                    style={{ width: '300px' }}
                                    placeholder="indicador"
                                    showSearch
                                >
                                    {
                                        report.properties.indicator.map(
                                            (value, key) => <Select.Option key={key} value={value}>
                                                {value}
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item> :
                            null
                    }
                    {
                        report && report.properties.year ?
                            <Form.Item
                                label='Año'
                                name='year'
                            >
                                <DatePicker style={{ width: '300px' }} picker='year' />
                            </Form.Item> :
                            null
                    }
                    {
                        report && report.properties.idBeneficiary ?
                            <Form.Item
                                label='ID beneficiario'
                                name='idBeneficiary'
                            >
                                <Input name='idBeneficiary' type='text' placeholder="ID beneficiario" />
                            </Form.Item> :
                            null
                    }

                   {(report) && <Form.Item>
                        <Button
                            loading={loading}
                            htmlType="submit"
                            type="primary"
                        >
                           { (loading)?"Generando reporte":"Generar reporte"}
                        </Button>
                        {
                            (
                                url &&
                                <Button
                                    onClick={() => onDownloadHandler(url)}
                                    htmlType='button'
                                    type="link"
                                >
                                    Descargar
                                </Button>
                            )
                        }
                    </Form.Item>}
                </Form>
            </Col>

            {
                report && (report.name === "Reporte de asistencias" || report.name === "Reporte de campos faltantes") &&
                <>
                    <Col flex='600px'>
                    <Divider orientation="left">Selecciona un convenio</Divider>
                        <Table rowSelection={{
                            type:"checkbox",
                            ...rowSelection
                            ,selectedRowKeys
                        }} pagination={{ pageSize: 100 }} columns={arrayColumns} dataSource={arrayRows} scroll={{ y: 350 }} />
                    </Col>
                
                </> 
            }

        </Row>
    )

}