import { getToken } from "../../services/HttpService";



export const getOneMicrodata = async (model, isFormAssistant = false) =>{

    
    const endPonint = `${process.env.REACT_APP_API}/microdatarecord`;
    const response = await getToken();

    const resp = await fetch(endPonint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method:"POST",
        body: JSON.stringify({...model, isFormAssistant})
    });
    const data = await resp.json();
    return data;
}