import { getToken } from "../../services/HttpService";

export const deleteBatchMicrodata = async ({ requests }) => {

    let token2 = localStorage.getItem("token");
    const response = await getToken();
    const objRequest = requests.map(requests => ({ requestId: requests.requestId, name_agreement: requests.name_agreement, uploadDate: requests.uploadDate }))
    const objEntero = {
        requests: objRequest,
        token: token2
    }

    const resp = await fetch(`${process.env.REACT_APP_API}/delete/batch/microdata`,
        {
            headers: {
                Authorization: `Bearer ${response.token}`
            },
            method: 'DELETE',
            body: JSON.stringify(objEntero)
        }
    )

    return await resp.json();
}

export const listBatchMicrodata = async () => {
    const response = await getToken();
    const resp = await fetch(`${process.env.REACT_APP_API}/list/batch/microdata`,
        {
            headers: {
                Authorization: `Bearer ${response.token}`
            }
        }
    )

    return await resp.json();
}
