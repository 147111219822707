import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Container, Box, Card, CardActions, Button } from '@material-ui/core'
import { MoreHoriz, Clear, Check } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { Badge, Select, Space } from 'antd'
import moment from 'moment'
import "../admin/styles/listRequest.css"
import { getPaginationRequest, getRequestBeneficiary } from '../../helpers/admin/getRequestBeneficiary'
import { BeneficiaryCardDetail } from './BeneficiaryTransferRequestDetail'
import { FilterOutlined } from '@ant-design/icons'
import { types } from '../../types/types'
import { traslateStateItem } from '../../services/traslateStateName.service'

export const BeneficiaryTransferRequestsStatus = ({ status }) => {
    switch (status) {
        case "IN_PROGRESS":
            return <MoreHoriz fontSize='large' color='primary' />
        case 'ACCEPTED':
            return <Check fontSize='large' color='action' />
        case 'REJECTED':
            return <Clear fontSize='large' color='error' />
        default:
            return null
    }
}
const { Option } = Select;



export const BeneficiaryTransferRequestsItems = ({ item }) => {
    const date = moment(item.requestDate)
    return (
        <Box className="animate__animated animate__fadeInUpBig" component='section' mb={3}>
            <Badge.Ribbon text={traslateStateItem(item.state)}>
                <Card>
                    <BeneficiaryCardDetail
                        date={date.format('YYYY-MM-DD HH:mm:ss')}
                        comment={item.requestComment}
                        name={item.userRequesting}
                        state={item.state}
                    />
                    <CardActions>
                        <Link to={`/user/beneficiary/transfer/request/${item.requestId}`}>
                            <Button variant='outlined'>ver mas</Button>
                        </Link>
                    </CardActions>
                </Card>
            </Badge.Ribbon>
        </Box>
    )
}

export const ListOfBeneficiaryTransferRequests = () => {

    const [listBeneficiaryRequest, setListBeneficiaryRequest] = useState([]);
    const [listBeneficiaryRequestFiltered, setListBeneficiaryRequestFiltered] = useState([]);
    const [filtersAplicated, setFiltersAplicated] = useState({ user: null, date: null, state: null });
    const [loading, setLoading] = useState(false);
    const [tokenNext, setNextToken] = useState(undefined);
    const [lastTokenId, setLastTokenId] = useState(undefined);
    const [tokenPrev, setPrevToken] = useState(undefined);
    const [paginationSize, setPaginationSize] = useState(20);
    const { agreements } = useSelector(state => state.auth);

    /* NUEVO */
    const [currentList, setCurrentList] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);


    /* NUEVO */


    useEffect(
        () => {
            getRequestBeneficiary()
                .then(
                    requestData => {
                        const listCodeAgreements = agreements.map(data => data.code);
                        setLoading(true)
                        setListBeneficiaryRequest(requestData.Items.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate)).filter(item => listCodeAgreements.includes(item["ID Convenio"])));
                        setListBeneficiaryRequestFiltered(requestData.Items.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate)).filter(item => listCodeAgreements.includes(item["ID Convenio"])));
                        setCurrentList(requestData.Items.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate)).filter(item => listCodeAgreements.includes(item["ID Convenio"])).slice(0, 10));
                    }
                )
                .finally(() => setLoading(false));
        },
        []
    )

    useEffect(() => {

        let listRequets = [...listBeneficiaryRequest];

        if (filtersAplicated.user)
            listRequets = listRequets.filter(record => record["userRequesting"] === filtersAplicated.user);

        if (filtersAplicated.date) {


            listRequets = listRequets.filter(record => {

                const recortShortDate = `${new Date(record["requestDate"]).getUTCFullYear()}-${new Date(record["requestDate"]).getUTCMonth() + 1}`
                return (recortShortDate === filtersAplicated.date)
            });
        }

        if (filtersAplicated.state)
            listRequets = listRequets.filter(record => record["state"] === filtersAplicated.state);
        setListBeneficiaryRequestFiltered(listRequets);
        setPageNumber(0);
        setCurrentList(listRequets.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate)).slice(0, paginationSize));
    }, [filtersAplicated, paginationSize])

    const handlerOnFilterSelected = (data, allData) => {
        if (data) {

            const nameKey = allData.key.substring(0, allData.key.indexOf("-"));

            if (nameKey === "userRequesting")
                setFiltersAplicated({ ...filtersAplicated, user: data });
            else if (nameKey === "requestDate") {
                setFiltersAplicated({ ...filtersAplicated, date: data });
            }
            else
                setFiltersAplicated({ ...filtersAplicated, state: data });

            if (typeof data === "number") {

            }
        }
    }

    const handlerOnFilterSelectedPag = (data) => {

        if (data) {

            if (typeof data === "number") {

            }
            setPaginationSize(data);
        }
    }
    if (loading && ListOfBeneficiaryTransferRequests.length === 0)
        return (<strong>Cargando...</strong>)

    const generateList = (list) => {
        return list.map(
            (request, index) => {
                const listCodeAgreements = agreements.map(data => data.code);
                if (listCodeAgreements.includes(request["ID Convenio"])) {
                    return <BeneficiaryTransferRequestsItems key={`${index}`} item={request} />
                }
            }
        )
    }

    /*const generatePagination = (listBeneficiaryRequestFiltered) => {
        const buttonCount = Math.ceil(listBeneficiaryRequestFiltered.length / paginationSize);
        const totalPages = [];
        for (let i = 0; i < buttonCount; i++) {
            totalPages.push(1);
        }
        return totalPages.map((item, index) => {
            return <button className={index === pageNumber ? 'currentPage paginationPage' : 'paginationPage'} onClick={() => { setPageNumber(index); setCurrentList(listBeneficiaryRequestFiltered.slice(index * paginationSize, paginationSize * (index + 1))) }}>{index + 1}</button>
        })
    }*/

    const generatePagination = (listBeneficiaryRequestFiltered) => {
        const totalPages = Math.ceil(listBeneficiaryRequestFiltered.length / paginationSize);
        const maxButtons = 10; // Número máximo de botones a mostrar
        const halfMaxButtons = Math.floor(maxButtons / 2);
        let startPage = 0;
        let endPage = totalPages - 1;

        // Ajustar el rango de páginas a mostrar según la página actual
        if (pageNumber > halfMaxButtons) {
            startPage = pageNumber - halfMaxButtons;
            endPage = pageNumber + halfMaxButtons;

            if (endPage > totalPages - 1) {
                endPage = totalPages - 1;
            }
        } else {
            endPage = Math.min(maxButtons - 1, totalPages - 1);
        }

        const paginationButtons = Array.from({ length: endPage - startPage + 1 }, (_, index) => {
            const page = startPage + index;
            return (
                <button
                    key={page}
                    className={page === pageNumber ? 'currentPage paginationPage' : 'paginationPage'}
                    onClick={() => {
                        setPageNumber(page);
                        setCurrentList(listBeneficiaryRequestFiltered.slice(page * paginationSize, (page + 1) * paginationSize));
                    }}
                >
                    {page + 1}
                </button>
            );
        });

        // Agregar botón para ir al inicio
        paginationButtons.unshift(
            <button
                key="start"
                className="paginationPage"
                disabled={pageNumber === 0}
                onClick={() => {
                    setPageNumber(0);
                    setCurrentList(listBeneficiaryRequestFiltered.slice(0, paginationSize));
                }}
            >
                &lt;&lt;
            </button>
        );

        // Agregar botón para ir al final
        paginationButtons.push(
            <button
                key="end"
                className="paginationPage"
                disabled={pageNumber === totalPages - 1}
                onClick={() => {
                    setPageNumber(totalPages - 1);
                    setCurrentList(listBeneficiaryRequestFiltered.slice((totalPages - 1) * paginationSize, totalPages * paginationSize));
                }}
            >
                &gt;&gt;
            </button>
        );

        return paginationButtons;
    };



    const unicValues = (key) => {
        if (key === "requestDate") {
            const values = listBeneficiaryRequest.map(data => `${new Date(data[key]).getUTCFullYear()}-${new Date(data[key]).getUTCMonth() + 1}`);
            return [...new Set(values)];
        } else if (key === "userRequesting") {
            const values = listBeneficiaryRequest.map(data => data[key]);
            return [...new Set(values)];
        } else {
            const values = listBeneficiaryRequest.map(data => data[key]);
            return [...new Set(values)].map(state => types[state]);
        }
    }

    const onClearUser = (e) => {
        setFiltersAplicated({ ...filtersAplicated, user: null });
    }
    const onClearDate = (e) => {
        setFiltersAplicated({ ...filtersAplicated, date: null });
    }
    const onClearState = (e) => {
        setFiltersAplicated({ ...filtersAplicated, state: null });
    }

    return (
        <Container className="animate__animated animate__fadeIn" fixed>
            {
                (listBeneficiaryRequest.length > 0) &&
                (
                    <div style={{ display: 'flex', padding: 8, backgroundColor: "rgba(0,0,0,0.1)" }}>
                        <Space size="middle">
                            <div style={{ width: 276 }}>
                                <label htmlFor="user"><FilterOutlined /> Usuario</label>
                                <Select
                                    placeholder="Filtrar por usuario"
                                    onChange={(data, allData) => handlerOnFilterSelected(data, allData)}
                                    style={{ width: "100%" }}
                                    className="userControl"
                                    id="user"
                                    name="userRequesting"
                                    allowClear
                                    onClear={onClearUser}>
                                    {
                                        unicValues("userRequesting").map((data, i) =>
                                            (<Option key={`userRequesting-${i}`} value={data}>{data}</Option>)
                                        )
                                    }
                                </Select>
                            </div>

                            <div style={{ width: 276 }}>
                                <label htmlFor="date"><FilterOutlined /> Fecha</label>
                                <Select
                                    placeholder="Filtrar por fecha"
                                    onChange={(data, allData) => handlerOnFilterSelected(data, allData)}
                                    style={{ width: "100%" }}
                                    className="dateControl"
                                    id="date"
                                    name="requestDate"
                                    allowClear
                                    onClear={onClearDate}>
                                    {
                                        unicValues("requestDate").map((data, i) =>
                                            (<Option key={`requestDate-${i}`} value={data}>{data}</Option>)
                                        )
                                    }
                                </Select>
                            </div>

                            <div style={{ width: 276 }}>
                                <label htmlFor="state"><FilterOutlined /> Estado</label>
                                <Select
                                    placeholder="Filtrar por estado"
                                    onChange={(data, allData) => handlerOnFilterSelected(data, allData)}
                                    style={{ width: "100%" }}
                                    className="stateControl"
                                    id="state"
                                    name="state"
                                    allowClear
                                    onClear={onClearState}
                                >
                                    {
                                        unicValues("state").map((data, i) =>
                                            (<Option key={`state-${i}`} value={data.name}>{data.pseudoName}</Option>)
                                        )
                                    }
                                </Select>
                            </div>
                            <div style={{ width: 276 }}>
                                <label htmlFor="state"><FilterOutlined />Cantidad de items</label>
                                <Select
                                    placeholder="Filtrar por estado"
                                    onChange={(data) => handlerOnFilterSelectedPag(data)}
                                    style={{ width: "100%" }}
                                    className="stateControl"
                                    id="state"
                                    name="state"
                                    allowClear
                                    onClear={onClearState}
                                    defaultValue={paginationSize}
                                >

                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Select>
                            </div>
                        </Space>

                    </div>
                )
            }
            <Box component='div' my={3}>
                {generateList(currentList)}
                {(currentList.length > 0) && generatePagination(listBeneficiaryRequestFiltered)}
            </Box>


        </Container>
    )
}
