import { getToken } from "../../services/HttpService";

export const getAvailableYear  = async () => {

    const response = await getToken();
    const resp = await fetch(
        `${process.env.REACT_APP_API}/availableYear`,
         {
             headers: {
                 "Authorization": `Bearer ${response.token}`
             },
             redirect: "follow" 
         }
    )
    return await resp.json()
}

