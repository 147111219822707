import { getToken } from "../../services/HttpService";

export const confirmLogin = async (token, isLoginProcess) => {

    const endPoint = `${process.env.REACT_APP_API}/auth`;
    const response = await getToken();

    let myHeaders = new Headers();

    myHeaders.append("token", token);
    myHeaders.append("isLoginProcess", isLoginProcess);
    myHeaders.append("Authorization", `Bearer ${response.token}`);
    myHeaders.append("Content-Type", "application/json");

    const resp = await fetch(endPoint, {
        headers: myHeaders,
        redirect: "follow"
    })

    return await resp.json();
}