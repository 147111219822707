import XLSX from 'xlsx';

export const extractColumns = (file) =>{

    return new Promise((resolve, reject) =>{
        
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (data) =>{
            const bufferArray = data.target.result;
            
            try {
                const workBook = XLSX.read(bufferArray, {type:'buffer'});
                const workSheetName = workBook.SheetNames[0];
                const workSheet = workBook.Sheets[workSheetName];
        
                const listData = XLSX.utils.sheet_to_json(workSheet, {header:1})[0];
                listData ?  resolve(listData) : reject("No hay encabezados");  
            } catch (error) {
                reject(error)
            }
        }
    });
}

