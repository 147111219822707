import React, { useEffect, useState } from 'react'
import  message  from 'antd/lib/message';
import Swal from 'sweetalert2';
import { getAvailableYear } from '../../helpers/admin/getAvailableYears';
import { putAvailableYears } from '../../helpers/admin/putAvailableYears';
import { buildTransferAvalableYearData } from '../../services/buildTransferGenericData.service';
import { TransferComponent } from '../generics/TransferComponent'
import { useDispatch } from 'react-redux';
import { updateOpenYears } from '../../actions/auth';

export const ClousureOfYear = () => {

    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [{source}, setSource] = useState({initialKeys:[], source:[]});
    const [openYears, setOpenYears] = useState([]);

    const dispatch = useDispatch();

    const getYears = async () => {
        const {payload} = await getAvailableYear();
        const {source, initialTargetKeys:initialKeys} = buildTransferAvalableYearData(payload.years_available, []);
        setOpenYears(payload.years_available);
        setTargetKeys(payload.closured_years);
        setSource({initialKeys, source});
    }

    useEffect(() => {
      getYears();
    }, [])
    
    const handlerOnSetLastAgreements = async (event, direction, moveKeys) =>{
      
        let yearsFiltered = event.filter(data => data === new Date().getFullYear())

        if(yearsFiltered.length >= 1){
          message.warn("No es posible cerrar el año en curso");
          return false;
        };

        const {popupText, poppupConfirmButton} = (direction === 'right')?
        {popupText:"¿Está segur@ de cerrar el año?", poppupConfirmButton:"Cerrar"}:
        {popupText:"¿Está segur@ de abrir el año?", poppupConfirmButton:"Abrir"}

        const result = await Swal.fire({
          icon : 'warning',
          text:popupText,
          showDenyButton: true,
          confirmButtonText: poppupConfirmButton,
          denyButtonText: `Cancelar`,
        });

        if(result.value) await putAvailableYears({closuredYears:event});
        dispatch(updateOpenYears(openYears.filter(year => !event.includes(year))));
        return result.value
    }

  return (
    
    <div className="divTransfer">
        <TransferComponent 
        source ={source} 
        handlerOnSetLastAgreements = {handlerOnSetLastAgreements} 
        targetKeys = {targetKeys}
        setTargetKeys = {setTargetKeys}
        selectedKeys = {selectedKeys}
        setSelectedKeys = {setSelectedKeys}
        titles = {{left:"Años abiertos", right:"Años cerrados"}}/>
    </div>
  )
}
