import { getToken } from "../../services/HttpService";


export const getItems = async (abortController) =>{

    const endPonint = `${process.env.REACT_APP_API}/schemas`;
    const response = await getToken();

    return fetch(endPonint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        signal: abortController.signal,
        redirect: "follow"
    })
    .then(resp => resp.json())
    .then(result => {return result})
    .catch( err =>{
        if(err.name === 'AbortError') {return null};
        throw err
    });
}