import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Switch from '@material-ui/core/Switch';
import moment from 'moment-timezone';
import { Table, message, Input, Select, Button, Space } from 'antd';
import {  FormControlLabel} from '@material-ui/core';
import { getAllUsers} from '../../helpers/admin/getAllUsers';
import { getAgreements} from '../../helpers/getAgreements';
import { putUser } from '../../helpers/admin/putUser';
import { buildRowsAndColumns } from '../../services/buildRowsAndColumns.service';
import { types } from '../../types/types';
import { TransferComponent } from '../generics/TransferComponent';
import { buildTransferGenericData } from '../../services/buildTransferGenericData.service';
import { renameRole } from '../../services/renameRoles.service';
import 'antd/dist/antd.css';
import './styles/editUsers.css';

export const EditUsers = () => {

    const initialState = {
      name:  "",
      roleUser: types.REGISTER_CONTROL_ROLE.name,
      email:  "",
      state: true
    }

    const [serchState, setserchState] = useState({
      searchText: '',
      searchedColumn: '',
    });

    const [{columnsUsers, rowsUsers}, setUsers] = useState({columnsUsers:[], rowsUsers:[]});
    const [listUsers, setListUsers] = useState([]);
    const [agreementsActualSelection, setagreementsActualSelection] = useState(null);
    const [allAgreements, setAllAgreements] = useState([]);
    const [userSelected, setUserSelected] = useState(initialState);
    const [isRowSelected, setIsRowSelected] = useState(false)
    const [{source}, setSource] = useState({initialKeys:[], source:[]});
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    const { Option } = Select;

    const {role} = useSelector(state => state.auth);
    const memoAbortController = useMemo(() => new AbortController(), [] );

    const rowsAndColumns = useCallback( (listData) =>{
      const {arrayColumns, arrayRows} = buildRowsAndColumns(listData, 150, "antd", serchState, setserchState);
      return { arrayColumns, arrayRows};
    }, [serchState]); 

    const retrieveUsers = useCallback( async (current = 1, pageSize = 10, filters)=> {
      setLoading(true);
      const results = await getAllUsers(memoAbortController, pageSize, current, (filters["Nombre de usuario"] || ""), (filters["Usuario de aplicación"] || ""));
      
        const {items, count} = results.payload;

        if(count === 0){
          message.info("No hay Usuarios para listar");
          setLoading(false);
          return;
        }

        setListUsers(items);

        const listUserCleaned = items.map((user) => {
          let dateTime = ""
          if(user.lastConnection){
            dateTime = moment(user.lastConnection).format('YYYY MM DD, h:mm:ss a');
          }
          return {
            "Usuario de aplicación": user.userId,
            "Nombre de usuario": user.name, 
            Rol:types[user.role].pseudoName, 
            Correo: user.email, 
            Estado: types[user.state].pseudoName, 
            "Ultima conexión":dateTime
          };
        });

        const {arrayColumns:ColumnsUsers, arrayRows: RowsUsers} = rowsAndColumns(listUserCleaned);

        setUsers({
          columnsUsers:ColumnsUsers,
          rowsUsers:RowsUsers
        });

        setPagination({
          current,
          pageSize,
          total: count,
        });

        setLoading(false);

    },[rowsAndColumns]) 
  

    const fetchData = useCallback( async (current = 1, pageSize = 10) =>{
      const results = await Promise.all([getAgreements({role},memoAbortController), getAllUsers(memoAbortController, pageSize, current)])
      
        const [allAgreements, users] = results;
        
        if(allAgreements !== null && users !== null ){
          if(allAgreements.items.length<=0){
            message.info("No hay convenios creados");
            return;
          }
    
          if(users.payload.count === 0){
            message.info("No hay Usuarios para listar");
            return;
          }
          setListUsers(users.payload.items);
          setAllAgreements(allAgreements.items);
          const listUserCleaned = users.payload.items.map((user) => {
            let dateTime = ""
            if(user.lastConnection){
              dateTime = moment(user.lastConnection).format('YYYY MM DD, h:mm:ss a');
            }
            return {
              "Usuario de aplicación": user.userId,
              "Nombre de usuario": user.name, 
              Rol:types[user.role].pseudoName, 
              Correo: user.email, 
              Estado: types[user.state].pseudoName, 
              "Ultima conexión":dateTime
            };
          });
          const {arrayColumns:ColumnsUsers, arrayRows: RowsUsers} = rowsAndColumns(listUserCleaned);
    
          setUsers({
              columnsUsers:ColumnsUsers,
              rowsUsers:RowsUsers
          });

          setPagination({
            current,
            pageSize,
            total: users.payload.count,
          });

          setLoading(false);
        }
    }, [role, memoAbortController]);

    useEffect(() => {
        fetchData();
        return () => {
          memoAbortController.abort();
        }
    }, [memoAbortController, fetchData]);

    useEffect(() => {
      if(userSelected.name !== ""){
        const agreementsByUser = listUsers.filter((user) => userSelected.userId ===  user.userId);
        setagreementsActualSelection(agreementsByUser[0].agreements);
      }
    }, [userSelected, listUsers]);

    useEffect(() => {
      if(agreementsActualSelection){
        const {source, initialTargetKeys:initialKeys} = buildTransferGenericData(allAgreements, agreementsActualSelection);
        setSource({initialKeys, source});
        setTargetKeys(initialKeys);
        setSelectedKeys([]);
      }
    },[agreementsActualSelection, allAgreements])

    


    const handlerInputChange = (event)=>{
      if(event?.target){
        if(event.target.name === "state"){
          setUserSelected({...userSelected, [event.target.name]:event.target.checked});
        }else{
          setUserSelected({...userSelected, [event.target.name]:event.target.value});
        }
      }else{
        setUserSelected({...userSelected, "roleUser":event});
      }
    }
    
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {

        let selected = {};
         
        selected = (selectedRows[0].Estado === types.active.pseudoName) ? 
        selected = {...selectedRows[0], Estado: true }:
        selected = {...selectedRows[0], Estado: false }
        
        let roleName = renameRole(selected.Rol);
        selected = {...selected, roleUser:roleName, Rol:roleName}
        
        const traslated = {
          userId: selected["Usuario de aplicación"],
          name: selected["Nombre de usuario"],
          email: selected.Correo,
          roleUser:selected.Rol,
          role: selected.Rol,
          state: selected.Estado,
          lastConnection: selected["Ultima conexión"]
        }
 
        setUserSelected(traslated);
        setIsRowSelected(true);
        setSelectedRowKeys(selectedRowKeys);
      }
    };

    const handleTableChange = (pagination, filters) => {
      if(isRowSelected){
        setUserSelected(initialState);
        setIsRowSelected(false);
        setSelectedRowKeys([]);
      }
      retrieveUsers(pagination.current, pagination.pageSize, filters);
    };

    const handlerOnSetLastAgreements = async (event, direction, moveKeys) =>{
      if(event.length <= 0){
        message.warn("El usuario debe tener al menos un convenio asignado");
        return false;
      };
      return true;
    }

    const handlerOnSubmit = (e) => {
      e.preventDefault();

      if(userSelected.name.trim() === "" || userSelected.email.trim() === "" ){
        message.warning("Hay campos obligatorios sin diligenciar ");
        return;
      }

      let agreementsToSave = [];
      let request;
      userSelected.role = userSelected.roleUser;
      
      if(targetKeys.length > 0){
        targetKeys.forEach(agreementId => {
          allAgreements.filter(value => value["ID Convenio"] === agreementId).forEach(result => {
            agreementsToSave.push({code: result["ID Convenio"]}); 
          })
        });
        
        request = {
          ...userSelected,
          agreements:agreementsToSave
        }
      }else{
        request ={...userSelected, agreements: agreementsActualSelection}
      }
      
      Swal.fire({
        title: 'Usuarios',
        text:"¿ Desea realizar este cambio ?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Enviar`,
        denyButtonText: `No Enviar`,
      }).then((result) => {
        if (result.isConfirmed) {
          putUser(request)
            .then((resp) => {
                if(resp.status){
                  Swal.fire('Editado!!', "Cambio Realizado", 'success');
                  // se limpia pantalla
                  setIsRowSelected(false);
                  setSelectedRowKeys([]);
                  setUserSelected(initialState);
                  fetchData();

                }else{
                    Swal.fire('Error !!', resp.Error, "error");
                } 
            }).catch(error =>{
              Swal.fire('Error !!', error, "error");
            })
        } else if (result.isDenied) {
          Swal.fire('No editado', '', 'info');
        }
      });

    }

    return (
        <div className="animate__animated animate__fadeIn" style = {{ paddingBottom: 35}}>

            <div className="divTableUsers">
              <Table rowSelection={{
                  type:"radio",
                  ...rowSelection
                  ,selectedRowKeys
              }} 
              columns={columnsUsers} 
              dataSource={rowsUsers} 
              scroll={{ x:'100vw', y: 240 }} 
              loading={loading}
              pagination={pagination}
              onChange={handleTableChange} />
            </div>

          
            <div className="divTableUsers">

              {
                (selectedRowKeys.length > 0) && (

                  <form className="editUserForm" id="editUserForm" autoComplete="off" onSubmit={handlerOnSubmit}>


                    <div className="divSwich">
                      <FormControlLabel
                        label="Estado"
                        control ={
                          <Switch
                            checked={userSelected.state}
                            color="primary"
                            name="state"
                            onChange={handlerInputChange}

                          />
                        }
                      />
                    </div>

                    <div className="allFormGroup">
                      <Space size="large">
                        <div>
                          <label htmlFor="userId">Nombre de usuario*</label>
                          <Input 
                          required
                          id = "userNameControl"
                          className="userControl"
                          name="name"
                          value={userSelected.name}
                          onChange={handlerInputChange}
                          placeholder="Jhon Dane" />
                        </div>

                        <div >
                            <label htmlFor="email">Correo Corporativo*</label>
                            <Input 
                            required
                            id = "userEmailControl"
                            className="userControl"
                            name="email"
                            value={userSelected.email}
                            onChange={handlerInputChange}
                            placeholder="name@dominio.com" />
                        </div>

                        <div >
                          <label htmlFor="userRole">Usuario de Registro</label>
                          <Select className="userControl" id="userRol" value={userSelected.roleUser} defaultValue={userSelected.roleUser} name="roleUser" onChange={handlerInputChange}>
                              <Option value={types.REGISTER_CONTROL_ROLE.name}>{types.REGISTER_CONTROL_ROLE.pseudoName}</Option>
                              <Option value={types.GLOBAL_CONTROL_ROLE.name}>{types.GLOBAL_CONTROL_ROLE.pseudoName}</Option>
                              <Option value={types.TOTAL_CONTROL_ROLE.name}>{types.TOTAL_CONTROL_ROLE.pseudoName}</Option>
                          </Select>
                        </div>

                      </Space>

                    </div>

                    <Button
                    type="primary"
                    shape="round"
                    onClick={handlerOnSubmit}
                    size="large"
                    style={{
                        width: 150,
                        background: "#345AB3",
                        borderColor: "#345AB3",
                        marginTop: 30,
                        marginLeft: 20
                    }}
                    >
                    Cargar
                    </Button>
    
                  </form>
                )
              }
               {(source.length > 0 && isRowSelected) &&
               <div className="divTransfer">
                 <TransferComponent 
                 source ={[...source]} 
                 handlerOnSetLastAgreements = {handlerOnSetLastAgreements} 
                 targetKeys = {targetKeys}
                 setTargetKeys = {setTargetKeys}
                 selectedKeys = {selectedKeys}
                 setSelectedKeys = {setSelectedKeys}
                 titles = {{left:"Disponibles", right:"Asignados"}}/>
               </div>
              }
            </div>
        </div>
    )
}
