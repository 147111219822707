import React, { Fragment, useState } from "react";
import { Card, Space, Button, Table, message, Input } from "antd";
import "./styles/CatalogList.css";
import { postNewTable } from "../../../helpers/admin/postNewTable";
import Swal from "sweetalert2";

const initialColumns = [
    {
        title:"Nombre",
        dataIndex:"name",
        key:"name"
    },
    {
        title:"Código",
        dataIndex:"code",
        key:"code"
    }
]

export const CreateList = () => {
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [{arrayColumns, arrayRows}, setTableProperties] = useState({arrayColumns:initialColumns, arrayRows:[]});
  const [tableName, setTableName] = useState(null);
  const [elementsSelectedList, setElementsSelectedList] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleSubmit = () => {

    if(tableName === "" || tableName === null){
        message.error("Debe agregar un nombre a la tabla");
        return;
    }

    if(arrayRows.length <= 0){
        message.error("La lista de propiedades esta vacia");
        return;
    }

    let listContent = arrayRows.map(data => ({code:data.code, name:data.name}));

    const request = {
        tableName,
        listContent
    }

    Swal.fire({
        title: `¿ Desea crear la tabla ${tableName} ?`,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Guardar`,
        denyButtonText: `No Guardar`,
      }).then(result =>{
          if(result.isConfirmed){
              postNewTable(request).then(response =>{
                  if(response.status){
                      Swal.fire("Tablas", "Tabla creada", "success");
                      setTableProperties({arrayColumns: initialColumns, arrayRows:[]});
                      setTableName(null);
                  }else{
                      Swal.fire("Tablas", response.Error, "error");
                  }
              }).catch(error => Swal.fire("Tablas", error, "error"));
              
          }
      });


  };

 
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setElementsSelectedList(selectedRows[0]);
      setAddMode(false);
      setIsRowSelected(true);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handlerOnCHange = (event) =>{
    if(event.target.name === "name")
        setElementsSelectedList({...elementsSelectedList, name:event.target.value});
    else
        setElementsSelectedList({...elementsSelectedList, code:event.target.value});
  }

  const handlerOnCLick = () =>{

    if(elementsSelectedList.code === undefined || elementsSelectedList.name === undefined){
      message.error("Debes seleccionar el boton agregar para insertar un registro");
      return;
    }

    if(!isRowSelected && !addMode){
      message.error("Debes seleccionar o crear una fila para editar");
      return;
    }

    if(elementsSelectedList.name.trim() === "" || elementsSelectedList.code.trim() === ""){
      message.error("Debe agregar nombre y código para el campo");
      return;
    }

    const newRows = [...arrayRows];
    let isCodeRepeat = false;
    //valida si el codigo a insertar ya existe
    newRows.forEach(data => {
        if(data.code === elementsSelectedList.code){
            newRows[data.key] = {...newRows[data.key], name: elementsSelectedList.name, code: elementsSelectedList.code};
            isCodeRepeat = true;
        }
        });

    
    if(!addMode){
        
        (!isCodeRepeat) && (newRows[elementsSelectedList.key] = elementsSelectedList);
        setTableProperties({arrayColumns, arrayRows:newRows});
        setIsRowSelected(false);
      
    }else{
        (!isCodeRepeat) && newRows.push(elementsSelectedList);
        setTableProperties({arrayColumns, arrayRows:newRows});
        setElementsSelectedList({});
        setAddMode(false);
    }
    //retira la selección de filas en la tabla
    setSelectedRowKeys([]);
    setElementsSelectedList({})
  }

  const handlerAddElement = () => {
    setAddMode(true);
    setElementsSelectedList({key: arrayRows.length, code:(arrayRows.length+1).toString(), name:""});
  }

  const hitName = (event) =>{
    const name = event.target.value;
    let re = new RegExp('^[a-zA-ZñÑ ]*$');
    
    if(re.test(name)){
        setTableName(name);
    }else{
        message.error("El nombre de la tabla no permite caracteres especiales");
    }

  }

  return (
    <Fragment>
      <div className="CatalogListContainer animate__animated animate__fadeIn">
        <Card className="leftCard">
         
            <div style={{display:"flex", flexDirection:"column"}}>
                <span className="textLabel">Nombre de tabla</span>
                <Input
                autoComplete="off"
                value={tableName}
                placeholder="Escribe un nombre de tabla"
                onChange={hitName}
                className="inputForm separated"
                style={{width:280}}
                />
            </div>
            <span className="textLabel">Propiedades</span>
            <div className="tableContainer">
              <Table
                className="tableScrollable"
                dataSource={arrayRows}
                columns={arrayColumns}
                bordered
                rowSelection={{type:"radio",...rowSelection, selectedRowKeys}}
                scroll={{ y: 500 }}
              />
            </div>
            <div className="footerButtons">
              <Button
                type="primary"
                shape="round"
                onClick={handleSubmit}
                size="medium"
                className="buttonBlue"
                style={{
                  marginRight: "30px",
                }}
              >
                Guardar
              </Button>
              <Button
                type="primary"
                shape="round"
                onClick={handlerAddElement}
                size="medium"
                className="buttonBlue"
                style={{
                    marginRight: "30px",
                  }}
              >
                Agregar
              </Button>

              <Button
                type="primary"
                shape="round"
                onClick={() => setTableProperties({arrayColumns: initialColumns, arrayRows:[]})}
                size="medium"
                className="buttonBlue"
              >
                Limpiar
              </Button>
            </div>
        </Card>
        
      
           <Card className="rightCard">
            <Space direction="vertical">
              <p className="textLabelUpload separated">
                Editar / agregar variable
              </p>
              <span className="textLabel">Código</span>
              <Input
              autoComplete="off"
              name="code" 
              style={{ minWidth: "280px" }} 
              value={(elementsSelectedList?.code||"")}
              onChange ={handlerOnCHange} />
              <span className="textLabel">Nombre</span>
              <Input
                autoComplete="off"
                name="name"
                value={(elementsSelectedList?.name||"")}
                onChange ={handlerOnCHange}
                className="separated"
                style={{ minWidth: "280px" }}
              />
              <Button
                type="primary"
                shape="round"
                onClick={handlerOnCLick}
                size="medium"
                className="buttonBlue"
              >
                {(addMode)?"Inserta":"Guardar"}
              </Button>
            </Space>
          </Card>
        
       
      </div>
    </Fragment>
  );
};
