import React, { useEffect, useMemo, useState } from 'react';
import Form from '@rjsf/material-ui';
import Swal from 'sweetalert2';
import {message, Select, Button, Empty  } from 'antd';
import { createSchema } from '../../helpers/admin/postSchemas';
import {extractColumns} from '../../services/extractColumns.service';
import { buildFields } from '../../services/buildFields.service';
import { buildPropertiesAndRules } from '../../services/buildPropertiesAndRules.service';
import { getStructure } from '../../helpers/admin/getStructures';
import { CloudUploadOutlined} from "@ant-design/icons";
import { UploadButton } from '../generics/UploadButton';
import './styles/editSchemaProgram.css'

export const SchemaPrograms = () => {

    const [{fields, archive}, setDataJson] = useState({archive:"", fields:[]});
    const [schema, setSchema] = useState();
    const [properties, setProperties] = useState();
    const [structures, setStructure] = useState([]);
    const [archiveControl, setArchiveControl] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const { Option } = Select;
    const memoAbortController = useMemo(() => new AbortController(), [] );

    const uiSchemaConfig = {
        "ui:options":{
            "ui:options": {
                orderable:false
              }
        }
    }

    useEffect(() => {
        
        getStructure(memoAbortController).then(resp =>{
            (resp) && 
            setStructure(resp.items);
        });
        
        return () => {
            memoAbortController.abort()
        }
        
    }, [memoAbortController]);

    useEffect(() => {
        setSchema({
            "title":"Creación de Esquema",
            "type":"object",
            "properties":properties
        });
    }, [properties, archive])

    const handlerSubmit =  (e) =>{
        e.preventDefault();
        if(!archiveControl){
            message.error("Debe seleccionar un Programa");
            return;
        }

        if(!uploadedFile){
            message.error("Hay archivos sin cargar");
            return;
        }


        const file = uploadedFile;
        const structure = structures.filter((value) => value.code === archiveControl);
        //reading excel colummuns file
        extractColumns(file).then((dataFields) =>{
            
            setDataJson({
                archive: structure[0].archive,
                fields: dataFields
            });
            buildFields(dataFields, archiveControl).then(values => setProperties(values));
            
        }).catch(Error => console.error({Error, message:"Archivo no valido"}));
        
    }

    
    const getUploadedFile = (file) => {
        // use here the command to upload and process the file
        // and create a download link
        // and set the reportUpload data
        setUploadedFile(file);
    };

    const handleLoadData = (e) => {

        const {formData} = e;
        const schemaForm = e.schema;

        const values = buildPropertiesAndRules(formData);

        const {rules, schemaProperties, requireds} = values;
        
        const structure = structures.filter((value) => value.archive === archive);

        Swal.fire({
            title: 'Recuerde que los campos obligatorio no podran ser modificados, Deseas cargar la estructura ?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Save`,
            denyButtonText: `Don't save`,
          }).then((result) => {
  
            if (result.isConfirmed) {

                const finalSchema = {
                    code: structure[0].code,
                    archive: structure[0].archive,
                    properties: schemaProperties,
                    required: requireds,
                    rules,
                    uiSchema:{
                        "ui:order":fields
                    },
                    schema: schemaForm
                }
                createSchema(finalSchema)
                .then((resp) => {
                    resp.status ?
                    Swal.fire('Guardado!!', finalSchema, 'success')
                    : Swal.fire('Error!',"'"+ resp.result + "'", 'error')
                })
            } else if (result.isDenied) {
              Swal.fire('Estructura No guardada', '', 'info')
            }
          })

        
        setProperties({});
    } 

    const  handlerCleanSchema = (e) =>{
        setSchema({});
        setArchiveControl(e);
    }


    //TODO: refactorizar, entra dos veces a este metodo
    const validateErrors = (formData, errors) =>{      
        const listKey = Object.keys(formData);
        let rulesExtrateds = {};
        

        listKey.forEach(value =>{
            let unicos = [];
            if(formData[value].rules){
                let result =[];
                formData[value].rules.map(val => result.push(val.rule));
                rulesExtrateds = {...rulesExtrateds, [value]:result}
                unicos = [...new Set(rulesExtrateds[value])];
                //valida si se duplican reglas
                if(unicos.length !== formData[value].rules.length){
                    errors[value].addError("Duplicidad de reglas no permitida")
                }

                //valida si esta vacia la tabla de referencia en la regla de listado
                if(result.indexOf("listado") >= 0){
                    const index = result.indexOf("listado");
                    if(!formData[value].rules[index].bindDataSource){
                        errors[value].addError("La tabla de referencia es obligatoria")
                    }
                }
                return errors;
            }
        });

        return errors;
    }

    return (

        <div className="animate__animated animate__fadeIn containerEditSchema">
        
            <div className="boxFomLeft">

                    <form autoComplete="off">

                        <div className="formControl">
                            <label htmlFor = "archive">Formulario</label>
                            <Select className="schemaEditControl" id="archive" name="archive" onChange={handlerCleanSchema}>
                                {
                                    structures.map((option, i) =>
                                        (<Option key={i} value={option.code}>{option.archive}</Option>)
                                    )
                                }
                            </Select>
                        </div>



                        <div className="formControl">

                            <UploadButton
                                setUploadFile={getUploadedFile}
                                uploadedFile={uploadedFile}
                            />
                    
                        </div>


                        <Button
                        id="buttonUpload"
                        type="primary"
                        shape="round"
                        onClick={handlerSubmit}
                        icon={
                            <CloudUploadOutlined
                            style={{
                                fontSize: "20px",
                            }}
                            />
                        }
                        size="large"
                        style={{
                            background: "#345AB3",
                            borderColor: "#345AB3",
                            marginTop: "30px",
                        }}
                        >
                        Cargar
                        </Button>
                    </form>
               

            </div>

            <div className="boxFomRight">
                {
                fields.length > 0  && schema.properties  ? 
                <Form schema={schema} uiSchema={uiSchemaConfig} onSubmit={handleLoadData} validate={validateErrors} liveValidate autoComplete="off">
                    <Button
                    type="primary"
                    shape="round"
                    size="large"
                    htmlType={'submit'}
                    style={{
                        width: 130,
                        background: "#345AB3",
                        borderColor: "#345AB3",
                        marginTop: "30px",
                        textAlign: "center"
                    }}
                    >
                    Enviar
                    </Button>
                </Form>
                :
                 <Empty imageStyle={{
                    height: 250,
                  }} description={<span>No hay datos</span>} />
                 } 
            </div>
        </div>

        
    )
}
