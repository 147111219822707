const instrastructureName = {
    typeInfraestructureId: "Tipo de infraestructura",
    Location: "Localización",
    category: "Categoria",
    municipalityId: "Código municipio",
    tradeName: "Nombre infraestructura",
    uniqueCodeInfra: "Código infraestructura",
    address: "Dirección",
    id: "Código tipo de infrastructura",
    DANECode: "Codigo DANE",
    municipalityName: "Municipio",
    name: "Nombre",
    code: "Código",
    infra_tradeName: "Nombre Infraestructura",
    abbreviation: "Abreviatura"
}

export const traslateInfrastructureName = (arraycolumns) => {
    const userIndex = arraycolumns.findIndex(item => item.key === "infra_uniqueCodeInfra");
    arraycolumns.splice(userIndex, 1)
    const codeIndex = arraycolumns.findIndex(item => item.key === "code");
    const codeObject = arraycolumns.splice(codeIndex, 1)[0];
    arraycolumns.splice(1, 0, codeObject);

    return arraycolumns.map(column => {
        column.title = instrastructureName[column.title];
        return column;
    });

}

