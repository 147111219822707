import { getToken } from "../../services/HttpService";

export const getQueueMessages = async (user, request_code, typeMessage) =>{
    
    /* const endPonint = `https://${types.apiID}.execute-api.us-east-1.amazonaws.com/queue/messages`; */
    const endPonint = `${process.env.REACT_APP_API}/queue/messages`;
    const response = await getToken();

    const resp = await fetch(endPonint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method:"POST",
        body: JSON.stringify({user, request_code, typeMessage})
    });

    return resp.json();
}