import { getToken } from "../../services/HttpService";



export const getAllUsers  = async (abortController, limit, page, name='', id='') => {

    const response = await getToken();

    const params = new URLSearchParams({limit,page,name,id});
    let endPonint = `${process.env.REACT_APP_API}/users?${params.toString()}`;

    return fetch(endPonint,
         {
            headers: {
                Authorization: `Bearer ${response.token}`
            },
            method:'GET',
            redirect: "follow"
        })
    .then(result => result.json())  
    .then(resp => { return resp})
    .catch(err =>{
        if(err.name === 'AbortError'){return null};
        throw err
    });
}

