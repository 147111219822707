import { getToken } from "../../services/HttpService";

export const putInfrastructures = async (request) => {
    const endPoint = `${process.env.REACT_APP_API}/infrastructures`; 
    const response = await getToken();

    const resp = await fetch(endPoint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method: 'PUT',
        body: JSON.stringify(request)
    })

    return await resp.json();
}
