import React from 'react'
import { CompensarIcon } from '../../assets/images/Custom/CompensarIcon'
import './styles/MainScreen.css'

export const MainScreen = () => {
    return (
        <div className="animate__animated animate__fadeIn">
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                padding:20
                }}>
                
                    <CompensarIcon />   
            
            </div>
        </div>
    )
}
