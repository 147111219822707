import React from 'react'
import { Provider } from 'react-redux'
import { AppRouter } from '../routes/AppRouter'
import { store } from '../store/store'
import { useIdleTimer } from 'react-idle-timer'
import { closeSession } from '../services/closeSession.service'
import { WebSocketClient } from '../websocket/webSocketClient'

const wsClient = new WebSocketClient(process.env.REACT_APP_WSCONNECT);

const ActivityTrackService = (timeInMinutes = 20) => {

    const onIdle = () => {
        const token = localStorage.getItem("token");
        if (token && token !== '') {
            console.info('user idle, sesion closed');
            closeSession(wsClient);
        }
    }

    useIdleTimer({ onIdle, timeout: 1000 * 60 * timeInMinutes })
}

export const AppEducation = () => {

    ActivityTrackService()

    return (
        <Provider store={store}>
            <AppRouter wsClient={wsClient} />
        </Provider>
    )
}
