import { getToken } from "../../services/HttpService";

export const getStructure = async (abortController) =>{

    const response = await getToken();
    const endPonint = `${process.env.REACT_APP_API}/archives`;

    return fetch(endPonint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        signal: abortController.signal
    }).then( result => result.json())
    .then(response => {return response})
    .catch( err =>{
        if(err.name === 'AbortError') {return null};
        throw err
    });
   
}