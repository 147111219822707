import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { startLoginUserPassword } from '../../actions/auth';
import { Input, Button, Space, message, Select  } from 'antd';
import { CompensarIcon2 } from '../../assets/images/Custom/CompensarIcon2';
import { UnlockTwoTone, IdcardTwoTone } from '@ant-design/icons';
import "./loginForm.css"

export const LoginForm = () => {

    const { loading } = useSelector(state => state.uiLogin);
    const { Option, OptGroup } = Select;
    const [accesType, setAccesType] = useState("INTERNAL_ACCESS");

    const dispatch = useDispatch();

    /* 
        LDAP_SERVACCOUNT_DN
        cn=FONNINEZPRU,ou=sa, o=compensar / contraseña:F0nini3zPRU2021
        cn=EDUREPORTERPRU,ou=sa, o=compensar / contraseña:3duc4ci0nPRU2021
        cn=SVCEDUCACIONPRU ,ou=sa, o=compensar / contraseña:3duc4ci0nComp2021
        cn=39551166 /contraseña: Prueba2021
    */

    const [values, handlerInputChange] = useForm({
        loginUser: "",
        loginPassword: ""
    });

    const { loginUser, loginPassword } = values;

    const handlerOnSubmit = (e) => {
        e.preventDefault();
        if (loginUser.trim() === "" || loginPassword.trim() === "") {
            message.info("Debe diligenciar todos los campos");
            return;
        }
        dispatch(startLoginUserPassword(loginUser, loginPassword, accesType)); //se realiza dispach de la acción login
    }

    const handlerSelectChange = (data) =>{
        setAccesType(data);
    }

    return (
        <div className="uiCard">
            <div>
                <p className="titleLogin">Reportes Foniñez</p>
                <p className="subtitleLogin">Inicio de sesión</p>
                <form className="loginForm" id="loginForm" autoComplete="off" onSubmit={handlerOnSubmit}>
                    <Space style={{ width: "100%" }} size="middle" direction="vertical">
                        <Input
                            prefix={<IdcardTwoTone twoToneColor="#345AB3" />}
                            autoComplete="off"
                            required
                            id="loginUser"
                            className="loginControll"
                            name="loginUser"
                            value={loginUser}
                            placeholder="Usuario de inicio"
                            onChange={handlerInputChange}
                        />
                        <Input
                            prefix={<UnlockTwoTone twoToneColor="#345AB3" />}
                            required
                            autoComplete="off"
                            id="loginPassword"
                            className="loginControll"
                            name="loginPassword"
                            value={loginPassword}
                            type="password"
                            placeholder="Contraseña"
                            onChange={handlerInputChange}
                        />

                        <Select size="large" defaultValue="INTERNAL_ACCESS" style={{ width: "100%", fontSize: 14}} onChange={handlerSelectChange}>
                            <OptGroup label="Internos">
                                <Option value="INTERNAL_ACCESS">Acceso Usuarios</Option>
                            </OptGroup>
                            <OptGroup label="Servicio">
                                <Option value="SERVICE_ACCESS">Acceso Servicios</Option>
                            </OptGroup>
                        </Select>

                        <Button
                            type="primary"
                            disabled={loading}
                            loading={loading}
                            shape="round"
                            size="large"
                            htmlType={'submit'}
                            style={{
                                width: "100%",
                                background: "#345AB3",
                                borderColor: "#345AB3",
                                textAlign: "center",
                                color: (loading) ? "rgba(0,0,0,0.5)" : "white"
                            }}
                        >
                            Ingresar
                        </Button>

                    </Space>

                    <footer>
                        <small className="copyRight">Creado por</small>
                        <CompensarIcon2 />
                    </footer>
                </form>
            </div>
        </div>
    )
}
