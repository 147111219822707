
export const buildTransferGenericData = (allData, currentData) => {
    const source = allData.map((data) => ({key: data["ID Convenio"],title: data["Nombre Convenio"],description: data["ID Programa"]}))
    const initialTargetKeys = currentData.map(data => data.code);

    return {
        source,
        initialTargetKeys
    }
}

export const buildTransferAvalableYearData = (allData, currentData) => {
    const source = allData.map((year) => ({key: year,title: year.toString(),description: year.toString()}))
    const initialTargetKeys = currentData.map(data => data.code);

    return {
        source,
        initialTargetKeys
    }
}
