import { message } from "antd";
import { getAvailableYear } from "../helpers/admin/getAvailableYears";
import { postLogin } from "../helpers/auth/authLogin";
import { types } from "../types/types"
import { finishLoading, startLoading } from "./uiLogin";

export const startLoginUserPassword = (user, password, accesType) => {
    return (dispatch) => {

        dispatch(startLoading());

        Promise.all([postLogin({ "uid": user, password, accesType }), getAvailableYear()])
            .then(resp => {

                const [authResponse, yearsResponse] = resp;

                if (authResponse.status && yearsResponse.status) {
                    const { role, name, token, agreements } = authResponse.payload.item;
                    const { years_available, closured_years } = yearsResponse.payload;
                    const openYears = years_available.filter(year => !closured_years.includes(year));
                    localStorage.setItem("token", token);
                    localStorage.setItem("isLoginProcess", "true");
                    dispatch(login(name, role, agreements, true, openYears));
                    dispatch(finishLoading());
                    window.location = "/";
                } else {
                    if (authResponse?.error?.message) message.warning(authResponse?.error?.message);
                    else message.error(authResponse?.error?.Error);
                    dispatch(finishLoading());
                }

            }).catch(error => {
                console.error(error);
                dispatch(finishLoading());
                message.error(error.message);
            });
    }
}

export const login = (name, role, agreements, isAutenticated, openYears) => ({
    type: types.login,
    payload: {
        name,
        role,
        agreements,
        isAutenticated,
        openYears
    }
});


export const updateOpenYears = (openYears) => ({
    type: types.updateOpenYears,
    payload: {
        openYears
    }
});
