import { getToken } from "../../services/HttpService";

export const createNewUser = async (user) =>{


    const endPoint = `${process.env.REACT_APP_API}/users`; 
    const response = await getToken();
    const resp = await fetch(endPoint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(user)
    })

    return await resp.json();
}