import React from 'react'
import { TabSelect } from '../../components/generics/TabSelect'
import {componentsAndNamesByReportsPath} from '../../services/componentsAndNamesByPaths.service'

export const ReportsPage = (props) => {

    let listContentTab = componentsAndNamesByReportsPath(props.history.location.pathname, props.match.params.id);
    return (
        <>
            <TabSelect
            listContentTab={listContentTab}
            /> 
        </>
    )
}
