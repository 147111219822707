import { getToken } from "../../services/HttpService";


export const postItemsAdded = async (model) => {

     /* const endPoint = `https://${types.apiID}.execute-api.us-east-1.amazonaws.com/schemaedit`;  */
     const endPoint = `${process.env.REACT_APP_API}/schemaedit`; 
     const response = await getToken();
     const resp = await fetch(endPoint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(model)
     })
 
     return await resp.json();
}
