import { getToken } from "../../services/HttpService";


export const postAsistances = async (asistences) => {

    /* const endPoint = `https://${types.apiID}.execute-api.us-east-1.amazonaws.com/microdaAsistances`;  */
    const endPoint = `${process.env.REACT_APP_API}/microdaAsistances`;
    const response = await getToken(); 

    const resp = await fetch(endPoint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method: 'PUT',
        body: JSON.stringify(asistences)
    })

    return await resp.json();
}
