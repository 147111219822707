import { getToken } from "../../services/HttpService";


export const postRequestChange = async (request) => {

    /* const endPoint = `https://${types.apiID}.execute-api.us-east-1.amazonaws.com/requestChanges`;  */
    const endPoint = `${process.env.REACT_APP_API}/requestChanges`; 
    const response = await getToken();

    const resp = await fetch(endPoint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(request)
    })

    return await resp.json();
}
