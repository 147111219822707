import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Space, Button, Table, message, Input } from "antd";
import Swal from "sweetalert2";
import { buildRowsAndColumns } from "../../../services/buildRowsAndColumns.service";
import { getAllInfrastructures } from "../../../helpers/admin/getAllInfrastructures";
import { putInfrastructures } from "../../../helpers/admin/putInfrastructures";
import { messageModalGeneric } from "../../../services/messageModalgeneric.service";
import 'antd/dist/antd.css';
import "./styles/editInfrstructures.css";
import { traslateInfrastructureName } from "../../../services/traslateInfrastructureName.service";


export const EditInfrastructures = () => {
  const [{ arrayColumns, arrayRows }, setTableProperties] = useState({ arrayColumns: [], arrayRows: [] });
  const [elementsSelectedList, setElementsSelectedList] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [loading, setLoading] = useState(true);

  const memoAbortController = useMemo(() => new AbortController(), []);

  const { name } = useSelector(state => state.auth);

  const [state, setstate] = useState({
    searchText: '',
    searchedColumn: '',
  });

  const rowsAndColumns = useCallback((listData) => {
    const { arrayColumns, arrayRows } = buildRowsAndColumns(listData, 150, "antd", state, setstate);
    return { arrayColumns, arrayRows };
  }, [state]);

  const getLists = useCallback(() => {
    getAllInfrastructures(memoAbortController).then((response) => {
      if (response !== null) {
        if (response.status) {
          const listInfrastructures = response.items.allInfrastructures;
          const { arrayColumns, arrayRows } = rowsAndColumns(listInfrastructures);
          const newArrayColumsn = traslateInfrastructureName(arrayColumns);
          setTableProperties({ arrayColumns: newArrayColumsn, arrayRows });
          setLoading(false);
        } else {
          message.error(response.Error);
        }
      }
    }).catch(error => console.error(error));
  }, [memoAbortController, rowsAndColumns]);


  useEffect(() => {
    getLists();
    return () => {
      memoAbortController.abort()
    }
  }, [memoAbortController, getLists]);


  const handleSubmit = () => {

    if (!elementsSelectedList?.uniqueCodeInfra) {
      message.error("No ha seleccionado ninguna infraestructura");
      return;
    }

    let content = { ...elementsSelectedList, user: name };

    delete content.key;

    messageModalGeneric(content).then(request => {
      if (request) {
        Swal.fire({
          title: "Infraestructuras",
          text: `¿ Desea Editar la infrastructura ${content.uniqueCodeInfra}`,
          footer: "<small>Solo se editara la ultima infraestructura seleccionada</small>",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Guardar`,
          denyButtonText: `No Guardar`,
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.isConfirmed) {
            putInfrastructures(request).then(response => {

              if (response.status) {

                Swal.fire("Infrastructuras", "Editada correctamente", "success");

                //se limpia formulario
                setElementsSelectedList({});
                setSelectedRowKeys([]);
              } else {
                Swal.fire("Intrasestructuras", response.Error, "error");
              }

            }).catch(error => Swal.fire("Intrasestructuras", error, "error"));

          }
        });
      }
    });

  };


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setElementsSelectedList(selectedRows[0]);
      setSelectedRowKeys(selectedRowKeys);
      setIsRowSelected(true);
    },
  };

  const handlerOnCHange = (event) => {

    switch (event.target.name) {
      case "DANECode":
        setElementsSelectedList({ ...elementsSelectedList, DANECode: event.target.value });
        return;
      case "Location":
        setElementsSelectedList({ ...elementsSelectedList, Location: event.target.value });
        return;
      case "address":
        setElementsSelectedList({ ...elementsSelectedList, address: event.target.value });
        return;
      case "category":
        setElementsSelectedList({ ...elementsSelectedList, category: event.target.value });
        return;
      case "id":
        setElementsSelectedList({ ...elementsSelectedList, id: event.target.value });
        return;
      case "municipalityId":
        setElementsSelectedList({ ...elementsSelectedList, municipalityId: event.target.value });
        return;
      case "municipalityName":
        setElementsSelectedList({ ...elementsSelectedList, municipalityName: event.target.value });
        return;
      case "tradeName":
        setElementsSelectedList({ ...elementsSelectedList, tradeName: event.target.value });
        return;
      case "typeInfraestructureId":
        setElementsSelectedList({ ...elementsSelectedList, typeInfraestructureId: event.target.value });
        return;
      case "uniqueCodeInfra":
        setElementsSelectedList({ ...elementsSelectedList, uniqueCodeInfra: event.target.value });
        return;

      default:
        return;
    }
  }

  const handlerOnCLick = () => {

    if (elementsSelectedList.key === undefined) {
      message.error("No hay infrastructura seleccionada");
      return;
    }

    if (!elementsSelectedList?.key.toString()) {
      message.error("No hay infrastructura seleccionada");
      return;
    }

    const listRecordValues = Object.values(elementsSelectedList);
    const listRecordKeys = Object.keys(elementsSelectedList);
    let elementSelectedCleaned = {};
    let isEmpty = false;
    listRecordValues.forEach((data, i) => {

      if (listRecordKeys[i] !== "key") {
        if (data.toString().trim() === "" || data === undefined) {
          isEmpty = true;
          return;
        }

      }

      //quita los espacios vacios que vengan de formulario
      if (listRecordKeys[i] !== "key") {
        elementSelectedCleaned = { ...elementSelectedCleaned, [listRecordKeys[i]]: listRecordValues[i].toString().trim() }
      } else {
        elementSelectedCleaned = { ...elementSelectedCleaned, [listRecordKeys[i]]: listRecordValues[i] }
      }

    });

    if (isEmpty) {
      message.error("Todos los campos deben estar diligenciados");
      return;
    };

    const newRows = [...arrayRows];

    let isCodeRepeat = false;

    //valida si el codigo a insertar ya existe
    newRows.forEach(data => {
      if (data.uniqueCodeInfra === elementSelectedCleaned.uniqueCodeInfra) {
        newRows[data.key] = {
          ...newRows[data.key],
          DANECode: elementSelectedCleaned.DANECode,
          Location: elementSelectedCleaned.Location,
          address: elementSelectedCleaned.address,
          category: elementSelectedCleaned.category,
          id: elementSelectedCleaned.id,
          municipalityId: elementSelectedCleaned.municipalityId,
          municipalityName: elementSelectedCleaned.municipalityName,
          tradeName: elementSelectedCleaned.tradeName,
          typeInfraestructureId: elementSelectedCleaned.typeInfraestructureId,
          uniqueCodeInfra: elementSelectedCleaned.uniqueCodeInfra,
        };
        isCodeRepeat = true;
      }
    });

    (!isCodeRepeat) && (newRows[elementSelectedCleaned.key] = elementSelectedCleaned);
    setTableProperties({ arrayColumns, arrayRows: newRows });

    setSelectedRowKeys([]); //se borra selección del table
    setIsRowSelected(false);
  }

  const handlerOnCLose = () => {
    setSelectedRowKeys([]);
    setIsRowSelected(false);
  };

  return (
    <>
      <div className="infrastructureContainer animate__animated animate__fadeIn">
        <div className="leftBox">
          <span className="textLabel">Infraestructuras</span>
          <div className="tableContainer">
            <Table
              loading={loading}
              dataSource={arrayRows}
              columns={arrayColumns}
              bordered
              rowSelection={{ type: "radio", ...rowSelection, selectedRowKeys }}
              style={{ width: "100%" }}
              scroll={{ x: 1500, y: 300 }}
            />
          </div>
          <div className="footerButtons">
            <Button
              type="primary"
              shape="round"
              onClick={handleSubmit}
              size="medium"
              className="buttonBlue"
            >
              Guardar
            </Button>
          </div>
        </div>

        <Drawer
          title="Editar infraestructura"
          width={500}
          onClose={handlerOnCLose}
          visible={isRowSelected}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={handlerOnCLose} style={{ marginRight: 8 }}>
                Cancelar
              </Button>
              <Button onClick={handlerOnCLick} type="primary">
                Guardar
              </Button>
            </div>
          }
        >
          <Space direction="vertical">
            <span className="textLabel">Código infraestructura / uniqueCodeInfra</span>
            <Input
              autoComplete="off"
              name="uniqueCodeInfra"
              disabled
              placeholder="CCF024-26-01464"
              value={(elementsSelectedList?.uniqueCodeInfra || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Nombre infraestructura / tradeName</span>
            <Input
              autoComplete="off"
              name="tradeName"
              placeholder="COLEGIO LOS TEJARES"
              value={(elementsSelectedList?.tradeName || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel"> Tipo de infrastructura / typeInfraestructureId</span>
            <Input
              autoComplete="off"
              name="typeInfraestructureId"
              placeholder="00"
              value={(elementsSelectedList?.typeInfraestructureId || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Código tipo de infrastructura / id</span>
            <Input
              autoComplete="off"
              name="id"
              placeholder="01464"
              value={(elementsSelectedList?.id || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Código municipio / municipalityId</span>
            <Input
              autoComplete="off"
              placeholder="25875"
              name="municipalityId"
              value={(elementsSelectedList?.municipalityId || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Municipio / municipalityName</span>
            <Input
              autoComplete="off"
              placeholder="BOGOTÁ"
              name="municipalityName"
              value={(elementsSelectedList?.municipalityName || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />


            <span className="textLabel">Categoria / category</span>
            <Input
              autoComplete="off"
              placeholder="IED BOGOTA"
              name="category"
              value={(elementsSelectedList?.category || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Localización / Location</span>
            <Input
              autoComplete="off"
              placeholder="CLL 00 N° 00-00"
              name="Location"
              value={(elementsSelectedList?.Location || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Dirección / address</span>
            <Input
              autoComplete="off"
              placeholder="Institución en convenio"
              name="address"
              value={(elementsSelectedList?.address || "")}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />


            <span className="textLabel">Codigo DANE / DANECode</span>
            <Input autoComplete="off"
              name="DANECode"
              placeholder="111769003360"
              style={{ width: 380 }}
              onChange={handlerOnCHange}
              value={(elementsSelectedList?.DANECode || "")} />

          </Space>
        </Drawer>

      </div>

    </>

  );
};
