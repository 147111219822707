import { getToken } from "../../services/HttpService";

const { REACT_APP_API } = process.env

export const getSchemas = async () => {
    try{
        /* const response = await getToken(); */
        const resp = await fetch(`${REACT_APP_API}/get/schemas`);
        const { Items } = await resp.json();
        return Items
    }catch (error) {
        console.error(error);
    }
}

export const getReport = async (report, body) => {

    const response = await getToken();
    try {
        const resp = await fetch(`${REACT_APP_API}${report.service}`, {
            headers: {
                Authorization: `Bearer ${response.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
            method: 'POST',
            redirect: "follow"
        })
        return await resp.json()
    } catch (error) {
        throw new Error(error.message || error);
    }
}