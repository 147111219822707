import React, { useState } from "react";
import { Drawer, Space, Button, Table, message, Input } from "antd";
import Swal from "sweetalert2";
import { postInfrastructures } from "../../../helpers/admin/postInfrastructures";
import "./styles/editInfrstructures.css";
import "antd/dist/antd.css";

const initialState = [
  {
    title: "Código infraestructura",
    dataIndex: "uniqueCodeInfra",
    key: "uniqueCodeInfra",
  },
  {
    title: "Nombre infraestructura",
    dataIndex: "tradeName",
    key: "tradeName",
  },
  {
    title: "Tipo de infrastructura",
    dataIndex: "typeInfraestructureId",
    key: "typeInfraestructureId",
  },
  {
    title: "Código tipo de infrastructura",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Código municipio",
    dataIndex: "municipalityId",
    key: "municipalityId",
  },
  {
    title: "Municipio",
    dataIndex: "municipalityName",
    key: "municipalityName",
  },
  {
    title: "Categoria",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Localización",
    dataIndex: "Location",
    key: "Location",
  },
  {
    title: "Dirección",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Código DANE",
    dataIndex: "DANECode",
    key: "DANECode",
  },
];

export const PostInfrastructure = () => {
  const [addMode, setAddMode] = useState(false);
  const [{ arrayColumns, arrayRows }, setTableProperties] = useState({
    arrayColumns: initialState,
    arrayRows: [],
  });
  const [elementsSelectedList, setElementsSelectedList] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);

  const handleSubmit = () => {
    if (arrayRows.length <= 0) {
      message.error("La lista de infraestructuras esta vacia");
      return;
    }

    let listContent = arrayRows.map((data) => ({
      DANECode: data.DANECode,
      Location: data.Location,
      address: data.address,
      category: data.category,
      id: data.id,
      municipalityId: data.municipalityId,
      municipalityName: data.municipalityName,
      tradeName: data.tradeName,
      typeInfraestructureId: data.typeInfraestructureId,
      uniqueCodeInfra: data.uniqueCodeInfra,
    }));

    const request = {
      listAddInfrastructure: listContent,
    };

    Swal.fire({
      title: `Infraestructuras`,
      text: `¿ Se agregaran ${listContent.length} Infrastructuras ?`,
      footer:
        "<small>Si las infrasestructuras agregadas ya existen reemplazaran a las ateriores</small>",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Agregar`,
      denyButtonText: `No Agregar`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        postInfrastructures(request)
          .then((response) => {
            if (response.status) {
              Swal.fire("Tablas", "Agregadas correctamente", "success");
              //se limpia formulario
              setTableProperties({ arrayColumns: initialState, arrayRows: [] });
              setElementsSelectedList({});
              setSelectedRowKeys([]);
            } else {
              Swal.fire("Tablas", response.Error, "error");
            }
          })
          .catch((error) => Swal.fire("Tablas", error, "error"));
      }
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setElementsSelectedList(selectedRows[0]);
      setAddMode(false);
      setSelectedRowKeys(selectedRowKeys);
      setIsRowSelected(true);
    },
  };

  const handlerOnCHange = (event) => {
    switch (event.target.name) {
      case "DANECode":
        setElementsSelectedList({
          ...elementsSelectedList,
          DANECode: event.target.value,
        });
        return;
      case "Location":
        setElementsSelectedList({
          ...elementsSelectedList,
          Location: event.target.value,
        });
        return;
      case "address":
        setElementsSelectedList({
          ...elementsSelectedList,
          address: event.target.value,
        });
        return;
      case "category":
        setElementsSelectedList({
          ...elementsSelectedList,
          category: event.target.value,
        });
        return;
      case "id":
        setElementsSelectedList({
          ...elementsSelectedList,
          id: event.target.value,
        });
        return;
      case "municipalityId":
        setElementsSelectedList({
          ...elementsSelectedList,
          municipalityId: event.target.value,
        });
        return;
      case "municipalityName":
        setElementsSelectedList({
          ...elementsSelectedList,
          municipalityName: event.target.value,
        });
        return;
      case "tradeName":
        setElementsSelectedList({
          ...elementsSelectedList,
          tradeName: event.target.value,
        });
        return;
      case "typeInfraestructureId":
        setElementsSelectedList({
          ...elementsSelectedList,
          typeInfraestructureId: event.target.value,
        });
        return;
      case "uniqueCodeInfra":
        setElementsSelectedList({
          ...elementsSelectedList,
          uniqueCodeInfra: event.target.value,
        });
        return;

      default:
        return;
    }
  };

  const handlerOnCLick = () => {
    if (elementsSelectedList.key === undefined) {
      message.error("No hay propiedades seleccionados");
      return;
    }

    if (!elementsSelectedList?.key.toString()) {
      message.error("No hay propiedades seleccionados");
      return;
    }

    const listRecordValues = Object.values(elementsSelectedList);
    const listRecordKeys = Object.keys(elementsSelectedList);
    let elementSelectedCleaned = {};
    let isEmpty = false;

    listRecordValues.forEach((data, i) => {
      if (listRecordKeys[i] !== "key") {
        if (data.trim() === "" || data === undefined) {
          isEmpty = true;
          return;
        }
      }

      //quita los espacios vacios que vengan de formulario
      if (listRecordKeys[i] !== "key") {
        elementSelectedCleaned = {
          ...elementSelectedCleaned,
          [listRecordKeys[i]]: listRecordValues[i].trim(),
        };
      } else {
        elementSelectedCleaned = {
          ...elementSelectedCleaned,
          [listRecordKeys[i]]: listRecordValues[i],
        };
      }
    });

    if (isEmpty) {
      message.error("Todos los campos deben estar diligenciados");
      return;
    }

    const newRows = [...arrayRows];

    let isCodeRepeat = false;

    //valida si el codigo a insertar ya existe
    newRows.forEach((data) => {
      if (data.uniqueCodeInfra === elementSelectedCleaned.uniqueCodeInfra) {
        newRows[data.key] = {
          ...newRows[data.key],
          DANECode: elementSelectedCleaned.DANECode,
          Location: elementSelectedCleaned.Location,
          address: elementSelectedCleaned.address,
          category: elementSelectedCleaned.category,
          id: elementSelectedCleaned.id,
          municipalityId: elementSelectedCleaned.municipalityId,
          tradeName: elementSelectedCleaned.tradeName,
          typeInfraestructureId: elementSelectedCleaned.typeInfraestructureId,
          uniqueCodeInfra: elementSelectedCleaned.uniqueCodeInfra,
        };
        isCodeRepeat = true;
      }
    });

    if (!addMode) {
      !isCodeRepeat &&
        (newRows[elementSelectedCleaned.key] = elementSelectedCleaned);
      setTableProperties({ arrayColumns, arrayRows: newRows });
    } else {
      !isCodeRepeat && newRows.push(elementSelectedCleaned);
      setTableProperties({ arrayColumns, arrayRows: newRows });
      setElementsSelectedList({});
      setAddMode(false);
    }

    setSelectedRowKeys([]); //se borra selección del table
    setIsRowSelected(false);
  };

  const handlerAddElement = () => {
    setAddMode(true);
    setIsRowSelected(true);
    setElementsSelectedList({
      key: arrayRows.length,
      DANECode: "",
      Location: "",
      address: "",
      category: "",
      id: "",
      municipalityId: "",
      tradeName: "",
      typeInfraestructureId: "",
      uniqueCodeInfra: "",
    });
  };

  const handlerOnCLose = () => {
    setSelectedRowKeys([]);
    setIsRowSelected(false);
  };

  return (
    <>
      <div className="infrastructureContainer animate__animated animate__fadeIn">
        <div className="leftBox">
          <span className="textLabel">Infraestructuras</span>
          <div className="tableContainer">
            <Table
              dataSource={arrayRows}
              columns={arrayColumns}
              bordered
              rowSelection={{ type: "radio", ...rowSelection, selectedRowKeys }}
              style={{ width: "100%" }}
              scroll={{ x: 1500, y: 300 }}
            />
          </div>
          <div className="footerButtons">
            <Button
              type="primary"
              shape="round"
              onClick={handleSubmit}
              size="medium"
              className="buttonBlue"
              style={{
                marginRight: "30px",
              }}
            >
              Guardar
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={handlerAddElement}
              size="medium"
              className="buttonBlue"
            >
              Agregar
            </Button>
          </div>
        </div>

        <Drawer
          title="Agregar infraestructura"
          width={500}
          onClose={handlerOnCLose}
          visible={isRowSelected}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={handlerOnCLose} style={{ marginRight: 8 }}>
                Cancelar
              </Button>
              <Button onClick={handlerOnCLick} type="primary">
                {addMode ? "Insertar" : "Guardar"}
              </Button>
            </div>
          }
        >
          <Space direction="vertical">
            <span className="textLabel">
              Código infraestructura / uniqueCodeInfra
            </span>
            <Input
              autoComplete="off"
              name="uniqueCodeInfra"
              placeholder="CCF024-26-01464"
              value={elementsSelectedList?.uniqueCodeInfra || ""}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">
              Nombre infraestructura / tradeName
            </span>
            <Input
              autoComplete="off"
              name="tradeName"
              placeholder="COLEGIO LOS TEJARES"
              value={elementsSelectedList?.tradeName || ""}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">
              {" "}
              Tipo de infrastructura / typeInfraestructureId
            </span>
            <Input
              autoComplete="off"
              name="typeInfraestructureId"
              placeholder="00"
              value={elementsSelectedList?.typeInfraestructureId || ""}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">
              Código tipo de infrastructura / id
            </span>
            <Input
              autoComplete="off"
              name="id"
              placeholder="01464"
              value={elementsSelectedList?.id || ""}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Código municipio / municipalityId</span>
            <Input
              autoComplete="off"
              placeholder="25875"
              name="municipalityId"
              value={elementsSelectedList?.municipalityId || ""}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Municipio / municipalityName</span>
            <Input
              autoComplete="off"
              placeholder="BOGOTÁ"
              name="municipalityName"
              value={elementsSelectedList?.municipalityName || ""}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Categoria / category</span>
            <Input
              autoComplete="off"
              placeholder="IED BOGOTA"
              name="category"
              value={elementsSelectedList?.category || ""}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Localización / Location</span>
            <Input
              autoComplete="off"
              placeholder="CLL 00 N° 00-00"
              name="Location"
              value={elementsSelectedList?.Location || ""}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Dirección / address</span>
            <Input
              autoComplete="off"
              placeholder="Institución en convenio"
              name="address"
              value={elementsSelectedList?.address || ""}
              onChange={handlerOnCHange}
              className="separated"
              style={{ width: 380 }}
            />

            <span className="textLabel">Codigo DANE / DANECode</span>
            <Input
              autoComplete="off"
              name="DANECode"
              placeholder="111769003360"
              style={{ width: 380 }}
              onChange={handlerOnCHange}
              value={elementsSelectedList?.DANECode || ""}
            />
          </Space>
        </Drawer>
      </div>
    </>
  );
};
