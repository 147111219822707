import { getToken } from "../services/HttpService";


export const putAgreement = async (model) => {

      /* const endPoint = `https://${types.apiID}.execute-api.us-east-1.amazonaws.com/agreement`;  */
      const endPoint = `${process.env.REACT_APP_API}/agreement`;
      const response = await getToken();

      const resp = await fetch(endPoint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
          method: 'PUT',
          body: JSON.stringify(model)
      })
  
      return await resp.json();
}
