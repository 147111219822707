import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Menu, Dropdown, Badge, Divider, Space } from 'antd';
import Avatar from 'antd/lib/avatar';
import { UserOutlined, BellOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getPaginationRequest, getRequestBeneficiary } from '../../helpers/admin/getRequestBeneficiary';
import { CompensarIcon2 } from '../../assets/images/Custom/CompensarIcon2';
import { closeSession } from '../../services/closeSession.service';
import './styles/NavBar.css'


export const NavBar = ({ userName, wsClient }) => {

  const [notification, setNotification] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const { agreements } = useSelector(state => state.auth);

  /*useEffect(() => {
    getRequestBeneficiary().then(response => {
      if (response.Count > 0) {
        for (let i = 0; i < response.Items.length; i++) {
          if (response.Items[i].state === "ACCEPTED" || response.Items[i].state === "REJECTED") {
            if(notification !== 0) setNotification(0);
          } else {
            setNotification(1);
            return
          }
        }
      }
    });
  }, []);*/

  useEffect(() => {
    const fetchBeneficiaryRequests = async () => {
      try {
        const listCodeAgreements = agreements.map(data => data.code);
        const response = await getRequestBeneficiary();
        if (response.Count > 0) {
          const hasInProgressState = response.Items.filter(item => listCodeAgreements.includes(item["ID Convenio"])).some(item => item.state === "IN_PROGRESS");
          if (hasInProgressState) {
            setNotification(1);
            setShowNotification(true); // Mostrar el símbolo de notificación
          } else {
            setNotification(0);
            setShowNotification(false); // Ocultar el símbolo de notificación
          }
        }
      } catch (error) {
        // Manejo de errores
      }
    };

    fetchBeneficiaryRequests();
  }, []);

  const onCLick = () => {
    closeSession(wsClient);
  }

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={onCLick}>
        <Link to="/"> Cerrar Sesión </Link>
      </Menu.Item>
    </Menu>
  );

  const DropdownMenu = () => (
    <Dropdown key="more" overlay={menu}>
      <Button
        style={{
          border: 'none',
          padding: 0,
          backgroundColor: "#FFF",
          margin: 0
        }}
      >
        {userName}
      </Button>
    </Dropdown>
  );

  return (
    <div style={{ position: "fixed", width: "100%", zIndex: 1000, top: 0, overflow: "hidden" }}>
      <nav
        className="site-page-header"
        style={{ backgroundColor: "#FFF", display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <div style={{ marginLeft: 25, display: "flex", alignItems: "center" }}>
          <Link to="/"><CompensarIcon2 /></Link>
          <Divider type="vertical" /> <span style={{ marginBottom: 8 }} className="appName">Reportes Foniñez</span>
        </div>
        <div style={{ marginRight: 25, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Space>
            <Link to="/user/list/beneficiary/transfer/requets">
              <Button style={{ marginRight: 34, marginTop: 2, border: "none" }} shape="circle" icon={
                <Badge key={1} style={{ marginRight: 34, marginTop: 2 }} count={showNotification ? notification : 0} dot> <BellOutlined style={{ marginRight: 30, fontSize: 22 }} /> </Badge>
              } size="small" /></Link>
            <Avatar key={2} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            <DropdownMenu />
          </Space>
        </div>

      </nav>

    </div>
  )
}
