
import { CloseCircleOutlined, FileExcelTwoTone, InfoCircleTwoTone, LoadingOutlined } from '@ant-design/icons';

import { Button, notification } from 'antd';
import React from 'react'

export const notificationComponent = ({ message: body, notificationKey, notificationType = 'processing', onDownloadReport }) => {

  const btn = (uri) => {
    return uri ? (
      <Button type="primary" size="small" onClick={() => {
        onDownloadReport(uri);
      }}>
        Descargar
      </Button>
    ) : <></>
  };

  const notificationResult = {
    style: {
      width: 450,
    },
    onClose: notification.close(notificationKey),
    duration: 0,
    key: notificationKey,
    btn: btn(body?.uri?.StringValue),
    message:
      `Carga de registros completada`,
    description:
      `El archivo de errores esta disponible para su descarga:\nFallidos: ${body?.failed?.StringValue} \n
          Guardados:  ${body?.saved?.StringValue} \n
          Total:  ${body?.total?.StringValue} `,
    icon: body?.saved?.StringValue === body?.total?.StringValue ? <FileExcelTwoTone twoToneColor='#00913f' /> : <FileExcelTwoTone twoToneColor='#ea3e23' />,
  };

  const notificationProcessing = {
    duration: 0,
    message: `Procesando archivo`,
    description: `${body?.Recived?.StringValue}`,
    key: notificationKey,
    icon: <LoadingOutlined />,
  };

  const notificationNoProcessed = {
    duration: 0,
    message: `Archivo no procesado`,
    description: `${body.message}`,
    key: notificationKey,
    icon: <InfoCircleTwoTone />,
  };

  const newNotificationResult = {
    style: {
      width: 450,
    },
    onClose: notification.close(notificationKey),
    duration: 0,
    key: notificationKey,
    btn: btn(body?.uri),
    message:
      `Carga de registros completada`,
    description:
      `El archivo de errores esta disponible para su descarga:\nFallidos: ${body?.failed} \n
        Guardados:  ${body?.saved} \n
        Total:  ${body?.total} `,
    icon: body?.saved === body?.total ? <FileExcelTwoTone twoToneColor='#00913f' /> : <FileExcelTwoTone twoToneColor='#ea3e23' />,
  };

  const newNotificationProcessing = {
    duration: 0,
    message: `Procesando archivo`,
    description: `${body?.message}`,
    key: notificationKey,
    icon: <LoadingOutlined />,
  };

  const errorNotification = {
    duration: 0,
    message: `Error`,
    description: `${body?.error}`,
    key: notificationKey,
    icon: <CloseCircleOutlined />,
  };

  switch (notificationType) {
    case 'processing':
      return (notification.open(notificationProcessing))
    case 'NO PROCESSED':
      return (notification.open(notificationNoProcessed))
    case 'PROCESSING':
      return (notification.open(newNotificationProcessing))
    case 'PROCESSED':
      return (notification.open(newNotificationResult))
    case 'ERROR':
      return (notification.open(errorNotification))
    default:
      return (notification.open(notificationResult))
  }
}

export const notificationErrorComponent = ({ error, notificationKey }) => {

  const notificationResult = {
    style: {
      width: 450,
    },
    onClose: notification.close(notificationKey),
    duration: 0,
    key: notificationKey,
    message:
      `Carga de registros incompleta`,
    description:
      `Errores al procesar el archivo \n`,
    icon: <CloseCircleOutlined style={{ color: '#ed4337' }} />,
  };

  return notification.open(notificationResult);
}
