import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, message, Button } from 'antd';
import Form from '@rjsf/material-ui';
import Swal from 'sweetalert2';
import { SaveOutlined } from "@ant-design/icons";
import { getSchemaAgreement } from '../../helpers/getSchemaAgreement';
import { buildUiSchema } from '../../services/buildUiSchema.service';
import { putAgreement } from '../../helpers/putAgreement';
import { buildRowsAndColumns } from '../../services/buildRowsAndColumns.service';
import { getAgreements } from '../../helpers/getAgreements';
import { transformError } from '../../services/transformError';
import './styles/editAgreements.css'
import { traslateInfrastructureName } from '../../services/traslateInfrastructureName.service';

export const EditAgreements = () => {
  const initialState = { rows: [], columns: [] }
  const initialStateInfrastructures = { rowsInfrastructures: [], columnsInfrastructures: [] }
  const [{ rows, columns }, setlistAgreements] = useState(initialState);
  const [{ RowsInfrastructures, ColumnsInfrastructure }, setlistInfrastructures] = useState(initialStateInfrastructures);
  const [schemaForm, setSchemaForm] = useState({});
  const [dataQuery, setDataQuery] = useState({});
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [infrastructureNames, setinfrastructureNames] = useState([]);
  const [state, setstate] = useState({
    searchText: '',
    searchedColumn: '',
  });
  const { role, agreements, name } = useSelector(state => state.auth);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const memoAbortController = useMemo(() => new AbortController(), []);

  const rowsAndColumns = useCallback((listData) => {
    const { arrayColumns, arrayRows } = buildRowsAndColumns(listData, 200, "antd", state, setstate);
    return { arrayColumns, arrayRows };
  }, [state]);

  useEffect(() => {
    const fechData = async (role, agreements) => {

      Promise.all([getSchemaAgreement(memoAbortController), getAgreements({ role, agreements }, memoAbortController)])
        .then(response => {

          const [schemaAgreement, listAgreements] = response;

          if (schemaAgreement !== null && listAgreements !== null) {

            if (listAgreements.items.length <= 0) {
              message.info("No hay convenios creados");
              return;
            }

            setDataQuery(schemaAgreement.dataQuery);
            const { arrayColumns, arrayRows } = buildRowsAndColumns(listAgreements.items, 300, "antd", state, setstate, true);
            const { arrayColumns: ColumnsInfrastructure, arrayRows: RowsInfrastructures } = rowsAndColumns(schemaAgreement.dataQuery.infrastructures);

            setlistAgreements({
              columns: arrayColumns,
              rows: arrayRows
            });

            const newArrayColumns = traslateInfrastructureName(ColumnsInfrastructure);

            setlistInfrastructures({
              ColumnsInfrastructure: newArrayColumns,
              RowsInfrastructures
            });

            const rulesKey = Object.keys(schemaAgreement.item.rules);
            const rules = schemaAgreement.item.rules;
            const uiSchemaForm = buildUiSchema(rulesKey, rules);

            setSchemaForm(
              {
                schema: {
                  title: schemaAgreement.item.archive,
                  type: "object",
                  properties: schemaAgreement.item.properties,
                  required: schemaAgreement.item.required
                },
                uiSchema: { ...schemaAgreement.item.uiSchema, ...uiSchemaForm },
                rules: schemaAgreement.item.rules
              },
            );
          }
        }).catch(err => console.error(err));
    }

    fechData(role, agreements);

    return () => {
      memoAbortController.abort();
    }

  }, [role, agreements, memoAbortController, rowsAndColumns]);

  const handlerRowSelected = (data) => {

    const properties = { ...data };
    delete properties.key;
    delete properties["Código de Municipio"];
    const keyProperties = Object.keys(properties);
    const schema = { ...schemaForm };

    if (properties["ID Programa"] === "Atención Integral a la Niñez") {
      const ainData = dataQuery.modalidad.AIN;
      const listAin = ainData.map(value => value.name);
      schema.schema.properties["Modalidad"] = { type: "string", enum: listAin }
    }

    if (properties["ID Programa"] === "Jornada Escolar Complementaria") {
      const jecData = dataQuery.modalidad.JEC;
      const listJec = jecData.map(value => value.name);
      schema.schema.properties["Modalidad"] = { type: "array", title: "Modalidad", uniqueItems: true, items: { type: "string", enum: listJec } }
    }


    //agrega valores por defecto al formulario con los datos de la fila seleccionada

    keyProperties.forEach(value => {
      if (schema.rules[value]) {
        if (schema.rules[value].rules.listado) {
          if (schema.rules[value].rules.listado.generateCode && schema.schema.properties[value].enumNames) {

            schema.schema.properties[value] = { ...schema.schema.properties[value], default: data[schema.rules[value].rules.listado.generateCode] }

          } else if (schema.schema.properties[value].enumNames) {
            const oneEnumName = data[value];
            const index = schema.schema.properties[value].enumNames.indexOf(oneEnumName);
            const oneEnumValue = schema.schema.properties[value].enum[index];
            schema.schema.properties[value] = { ...schema.schema.properties[value], default: oneEnumValue }
          } else {
            const result = (typeof data[value] !== 'string')
              ? data[value].filter(dataSaved => schema.schema.properties[value]?.items.enum.includes(dataSaved))
              : data[value];
            schema.schema.properties[value] = { ...schema.schema.properties[value], default: result }
          }
        } else {
          schema.schema.properties[value] = { ...schema.schema.properties[value], default: data[value] }
        }
      }
    });


    schema.uiSchema = {
      ...schema.uiSchema,
      "ID Convenio": {
        "ui:readonly": "readOnly"
      },
      "ID Programa": {
        "ui:readonly": "readOnly"
      }
    }

    let copyRowsInfrastructures = [...RowsInfrastructures];
    const listInfrastructuresName = copyRowsInfrastructures.map(data => data.tradeName);

    const listIndexInfraestructures = data["Infraestructuras"].map(info => {
      const index = listInfrastructuresName.indexOf(info);
      return index !== -1 ? index : null;
    }).filter(index => index !== null);

    let listIndex = [];


    for (let index = 0; index < data["Infraestructuras"].length; index++) {
      const listIndexValue = listIndexInfraestructures[index];

      if (listIndexValue !== null && listIndexValue < copyRowsInfrastructures.length) {
        const aux = copyRowsInfrastructures[index];
        const dataAtIndex = copyRowsInfrastructures[listIndexValue];

        copyRowsInfrastructures[index] = dataAtIndex;
        copyRowsInfrastructures[listIndexValue] = aux;
        copyRowsInfrastructures[index].key = index;
        copyRowsInfrastructures[listIndexValue].key = listIndexValue;
        listIndex.push(index);
      }
    }

    setIsRowSelected(true);
    setinfrastructureNames(data["Infraestructuras"]);
    setSchemaForm(schema);
    setlistInfrastructures({ RowsInfrastructures: copyRowsInfrastructures, ColumnsInfrastructure });
    setSelectedRowKeys(listIndex);

  }

  const handlerOnSubmitForm = ({ formData }) => {

    if (selectedRowKeys.length <= 0) {

      message.error("Debes agregar al menos una infraestructura");
      return;

    }

    const listSchemaKey = Object.keys(schemaForm.rules);

    listSchemaKey.forEach(value => {

      if (schemaForm.rules[value].rules.listado) {
        if (schemaForm.rules[value].rules.listado.generateCode && schemaForm.schema.properties[value].enumNames) {

          const index = schemaForm.schema.properties[value].enum.indexOf(formData[value]);
          const resultNames = schemaForm.schema.properties[value].enumNames[index];
          formData = {
            ...formData,
            [schemaForm.rules[value].rules.listado.generateCode]: formData[value],
            [value]: resultNames
          }
        } else if (schemaForm.schema.properties[value].enumNames) {
          const index = schemaForm.schema.properties[value].enum.indexOf(formData[value]);
          const resultNames = schemaForm.schema.properties[value].enumNames[index];
          formData = {
            ...formData,
            [value]: resultNames
          }
        }
      }

    });


    modalMessage().then((text) => {

      if (text !== undefined && text !== null) {

        formData = { ...formData, changes: { user: name, comment: text } };
        formData.Infraestructuras = infrastructureNames;

        Swal.fire({
          title: 'Convenios',
          text: '¿ Estas seguro de realizar este cambio ?',
          showDenyButton: true,
          showCancelButton: true,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Guardar`,
          denyButtonText: `No Guardar`,
        }).then((result) => {

          if (result.isConfirmed) {

            putAgreement(formData).then((response) => {
              if (response.status) {
                Swal.fire("Guardado", `convenio ${formData["ID Convenio"]} se actualizó correctamente`, "success");
                //Se limpia formulario
                getAgreements({ role, agreements }, memoAbortController).then(agreements => {
                  const { arrayColumns, arrayRows } = buildRowsAndColumns(agreements.items, 300, "antd", state, setstate, true);
                  setlistAgreements({
                    columns: arrayColumns,
                    rows: arrayRows
                  });

                }).catch(value => console.error("ERROR!!", value));
              } else {
                Swal.fire("Error al Guardar", `convenio ${formData["ID Convenio"]} no se actualizó correctamente`, "error");
              }
            }).catch(error => console.error("ERROR!!", error));

            setIsRowSelected(false);

          } else if (result.isDenied) {
            setIsRowSelected(false);
            Swal.fire('Cambios desechados', '', 'info');
          }
        });

      }
    })

  }

  const modalMessage = async () => {

    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Motivo del Cambio ?',
      inputPlaceholder: 'Porque estas realizando el cambio ?',
      inputAttributes: {
        'aria-label': 'Escribe tu mensaje aqui.'
      }
    });


    if (text === undefined) {
      return null;
    }

    if (text.trim().length <= 0) {
      message.warn("El mensaje no puede estar vacio");
      return null;
    }

    return text;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let listInfrastructureNames = selectedRows.map((infra) => infra.tradeName);
      setinfrastructureNames(listInfrastructureNames);
      setSelectedRowKeys(selectedRowKeys);
    }
  }

  const rowSelectionAgreements = {
    onChange: (selectedRowKeys, selectedRows) => {
      const [result] = selectedRows;

      result.Infraestructuras.forEach((data1, index) => {
        if(!RowsInfrastructures.find(data => data1 === data.tradeName))
          result.Infraestructuras.splice(index,1);
      })

      handlerRowSelected(result);
    }
  }

  const onChangeForm = (e) => {
    let keys = Object.keys(e.formData);
    keys.forEach(key => {
      e.schema.properties[key].default = e.formData[key];
    });

    setSchemaForm({ ...schemaForm, schema: e.schema })
  }

  return (
    <div className="divEditAgreements">

      <div style={{ height: 450, width: "96%" }}>
        <Table rowSelection={{
          type: "radio",
          ...rowSelectionAgreements
        }} tableLayout="fixed" columns={columns} dataSource={rows} scroll={{ x: '100vw', y: 400 }} size="small" />

        {/* <DataGrid onRowSelected={handlerRowSelected} rowHeight={25} columns={columns} rows={rows}/> */}
      </div>
      {
        (isRowSelected) &&
        (<div className="divFormEditAgreement">
          <div className="mainForm">
            {(schemaForm.schema) &&
              <Form
                noHtml5Validate={true}
                onSubmit={handlerOnSubmitForm}
                schema={schemaForm.schema}
                uiSchema={schemaForm.uiSchema}
                onChange={onChangeForm}
                liveValidate
                transformErrors={transformError}
                showErrorList={false}
              >
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType={'submit'}
                  icon={
                    <SaveOutlined
                      style={{
                        fontSize: "20px",
                      }}
                    />
                  }
                  style={{
                    width: 130,
                    background: "#345AB3",
                    borderColor: "#345AB3",
                    marginTop: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Guardar
                </Button>
              </Form>}
          </div>

          <div className="divInfrastructure">
            <Table rowSelection={{
              type: "checkbox",
              ...rowSelection,
              selectedRowKeys
            }}
              columns={ColumnsInfrastructure}
              dataSource={RowsInfrastructures}
              style={{ marginRight: 45 }}
              scroll={{ y: 500 }} />
          </div>


        </div>)
      }

    </div>
  );
}
