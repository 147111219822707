import React from 'react'
import { TabSelect } from '../../components/generics/TabSelect'
import { componentsAndNamesByPaths } from '../../services/componentsAndNamesByPaths.service'


export const AdminSchema = (props) => {
    let {listContentTab} = componentsAndNamesByPaths(props.history.location.pathname);
    return (
        <TabSelect
            listContentTab={listContentTab}
        /> 
    )
}
