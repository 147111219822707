
import { Input, Space, Button, Badge } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export const buildRowsAndColumns = (listObjects, width = 100, type = "material", state, setstate, isElipsis = false, hasState = false) => {
  if (listObjects) {
    if (listObjects.length > 0) {

      const listAgreementKeys = Object.keys(listObjects[0]);

      if (type === "material") {


        let arrayColumns = [{ field: "id", hide: true }]; //primera columna del datagrid

        listAgreementKeys.forEach(value => {
          arrayColumns.push({ field: value, width: width }); //las demas columnas del datagrid
        });

        const arrayRows = listObjects.map((value, i) => ({ id: i, ...value })); //filas del datagrid
        return { arrayColumns, arrayRows }
      } else {
        let arrayColumns = [];
        let searchInput;

        let getColumnSearchProps = dataIndex => ({
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={node => {
                  searchInput = node;
                }}
                placeholder={`Buscar`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Limpiar
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({ closeDropdown: false });
                    setstate({
                      searchText: selectedKeys[0],
                      searchedColumn: dataIndex,
                    });
                  }}
                >
                  Filtrar
                </Button>
              </Space>
            </div>
          ),
          filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
          onFilter: (value, record) =>
            record[dataIndex]
              ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
              : '',
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => searchInput.select(), 100);
            }
          },
          render: text =>
            state.searchedColumn === dataIndex ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            ) : (
              text
            ),
        });

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
          confirm();
          setstate({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
          });
        };

        const handleReset = clearFilters => {
          clearFilters();
          setstate({ searchText: '' });
        };

        listAgreementKeys.forEach(value => {

          let structureColumn = { title: value, dataIndex: value, key: value, ...getColumnSearchProps(value), ellipsis: isElipsis, width: width };
          if (value === "downloadErrors") structureColumn = { ...structureColumn, render: (text) => <a href={text}> {text === '' ? "" : 'Descargar'}</a> }
          if (value === "Estado" && hasState) structureColumn = {
            ...structureColumn, render: (text) =>
            (<span><Badge
              text={(text === 'active') ? 'Activo' : 'Trasladado'}
              status={(text === 'active') ? 'success' : 'error'}
            /></span>)
          }

          arrayColumns.push(structureColumn); //las columnas del datagrid
        });

        const arrayRows = listObjects.map((value, i) => ({ key: i, ...value })); //filas del datagrid
        return { arrayColumns, arrayRows }
      }

    }

  }

}
