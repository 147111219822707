export const types = {
    login: '[Auth] Login',
    logout: '[Auth] logout',
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',
    updateOpenYears: '[Years] Update OpenYears',
    TOTAL_CONTROL_ROLE: { name: "TOTAL_CONTROL_ROLE", pseudoName: "Usuario de Control" },
    GLOBAL_CONTROL_ROLE: { name: "GLOBAL_CONTROL_ROLE", pseudoName: "Usuario de Consulta" },
    REGISTER_CONTROL_ROLE: { name: "REGISTER_CONTROL_ROLE", pseudoName: "Usuario de Registro" },
    uploadRecord: "UPLOAD_RECORDS",
    updateAsistances: "UPDATE_ASSISTANCES",
    updateFields: "UPDATE_FIELDS",
    active: { name: 'active', pseudoName: 'Activo' },
    inactive: { name: 'inactive', pseudoName: 'Inactivo' },
    ACCEPTED: { name: 'ACCEPTED', pseudoName: 'ACEPTADO' },
    IN_PROGRESS: { name: 'IN_PROGRESS', pseudoName: 'EN PROGRESO' },
    REJECTED: { name: 'REJECTED', pseudoName: 'RECHAZADO' }
}