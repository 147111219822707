import React from 'react'
import { Menu } from 'antd';
import {SettingOutlined, StockOutlined, FormOutlined, StarOutlined } from '@ant-design/icons';
import { Link} from 'react-router-dom';
import {types} from '../../types/types'

export const AsideMenu = ({role}) => {

    const { SubMenu } = Menu;
    
    return (
        <div style={{position: 'fixed',  height: '100%'}}>
            <Menu
                style={{ width: 224, height: '100%'}}
                mode="inline"
            >
                {
                    (role === types.TOTAL_CONTROL_ROLE.name ) &&
                    (<SubMenu key="admin" icon={<SettingOutlined />} title="Administración">
                        <Menu.Item key="/admin/users"><Link to="/admin/users">Usuarios</Link></Menu.Item>
                        <Menu.Item key="/admin/tables"><Link to="/admin/tables">Catalogo de tablas</Link></Menu.Item>
                        <Menu.Item key="/admin/schema"><Link to="/admin/schema">Gestión de formularios</Link></Menu.Item>
                        <Menu.Item key="/admin/agreements"><Link to="/admin/agreements">Gestión Convenios</Link></Menu.Item>
                        <Menu.Item key="/admin/delete"><Link to="/admin/delete">Borrado microdato</Link></Menu.Item>
                        <Menu.Item key="/admin/clousure"><Link to="/admin/clousure">Cierre de año</Link></Menu.Item>
                    </SubMenu>)
                }
                {
                    (role !== types.GLOBAL_CONTROL_ROLE.name ) &&
                    (<SubMenu key="user" icon={<FormOutlined />} title="Microdato">
                        <Menu.Item key="/user/microdat"><Link to="/user/microdata">Registro microdato</Link></Menu.Item>
                        <Menu.Item key="/user/updatemicrodata"><Link to="/user/updatemicrodata">Completar microdato</Link></Menu.Item>
                    </SubMenu>)
                }
                {
                    (role !== types.GLOBAL_CONTROL_ROLE.name ) &&
                    <SubMenu key="news" icon={<StarOutlined />} title="Novedades">
                        <Menu.Item key="/user/list/beneficiary/transfer/requets"><Link to="/user/list/beneficiary/transfer/requets">Atender solicitudes</Link></Menu.Item>
                    </SubMenu>
                }

                <SubMenu key="reports" icon={<StockOutlined />} title="Reportes">
                    <Menu.Item key="/reports/data"><Link to="/reports/data">Opciones de reportes</Link></Menu.Item>
                </SubMenu>
            </Menu>
        </div>
    )
}
