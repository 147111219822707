const errorUploadNames = {
    uploadDate: "Fecha de carga",
    numberRecords: "Registros cargados",
    validRecords: "Registros validos",
    user: "Usuario",
    id_agreement: "ID Convenio",
    name_agreement: "Nombre Convenio",
    errorRecords: "Registros fallidos",
    downloadErrors: "Enlace de descarga",
    requestId: "Id solicitud",
}

export const traslateErrorUpload = (arraycolumns) => {

    const userIndex = arraycolumns.findIndex(item => item.key === "user");
    const userObject = arraycolumns.splice(userIndex, 1)[0];
    arraycolumns.splice(1, 0, userObject);

    const convenioIndex = arraycolumns.findIndex(item => item.key === "name_agreement");
    const convenioObject = arraycolumns.splice(convenioIndex, 1)[0];
    arraycolumns.splice(2, 0, convenioObject);
    return arraycolumns.map(column => {

        column.title = errorUploadNames[column.title];
        return column;
    });

}

