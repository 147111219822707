import React, { Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ReportsPage } from '../pages/reports/ReportsPage'

export const ReportsRouter = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path="/reports/data" component={ReportsPage} />
                <Redirect to="/" />
            </Switch>
        </Fragment>
    )
}
