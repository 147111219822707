import React, { useEffect, useState } from 'react';
import { buildRowsAndColumns } from '../../services/buildRowsAndColumns.service';
import { Modal, Select, Table, Space } from 'antd';
import Swal from 'sweetalert2';
import 'antd/dist/antd.css';
import { traslateInfrastructureName } from '../../services/traslateInfrastructureName.service';

const archiveSchemas = [
    {
        value: '5-183A',
        label: 'Jornada Escolar Complementaria',
    },
    {
        value: '5-186A',
        label: 'Atención Integral a la Niñez',
    }
];

const filterAgreementsByProgramId = (agreements, programId) => {
    return agreements.filter(data => {
        let [programName] = archiveSchemas.filter(program => program.value === programId);
        return (programName.label === data.program);
    });
}

export const ModalTraslateBeneficiary = ({ open, setOpen, listAgrements, programID, infrastructures, state, setstate, setTraslates }) => {


    const [{ arrayColumns, arrayRows }, setInfrastructuresByAgreement] = useState({ arrayColumns: [], arrayRows: [] });
    const [infrastructureChoosed, setInfrastructureChoosed] = useState(undefined);
    const [agreementChoosed, setAgreementChoosed] = useState(undefined);
    const [agreementChoosedYear, setAgreementChoosedYear] = useState(undefined);
    const [programIDChoosed, setProgramIDChoosed] = useState(programID);

    const [listAgreementsFiltered, setListAgreementsFiltered] = useState(filterAgreementsByProgramId(listAgrements, programIDChoosed))


    useEffect(() => {
        setInfrastructuresByAgreement({ arrayColumns: [], arrayRows: [] });
        setInfrastructureChoosed(undefined);
        setAgreementChoosed(undefined);
        setAgreementChoosedYear(undefined);
        setListAgreementsFiltered(filterAgreementsByProgramId(listAgrements, programIDChoosed));
    }, [setInfrastructuresByAgreement, setInfrastructureChoosed, setAgreementChoosed, setProgramIDChoosed, programID, programIDChoosed, setAgreementChoosedYear]);

    const { Option } = Select;

    const handleOnChangeprogramID = (value) => {
        setProgramIDChoosed(value);
    }

    const handleOnChangeAgreement = (value) => {
        const yearAgremente = parseInt(value.split("~")[0]);
        const code = value.split("~")[1]
        const listInfrastructures = listAgrements.filter(data => data.code === code).map(result => result.infrastructures);
        let infrastructuresFiltered = [];

        if (listInfrastructures[0] === undefined) { //valida que tenga infraestructuras asignadas
            Swal.fire({
                title: 'Traslados',
                text: "No hay infraestructuras asignadas al convenio",
                footer: "<small>si ya asigno la infraestructura recarge la pagina</small>",
                icon: "info"
            })
            return;
        }

        listInfrastructures[0].forEach(value => {

            const [infrastructureObj] = infrastructures.filter(data => data.tradeName === value);
            infrastructuresFiltered.push(infrastructureObj)
        });

        if (infrastructuresFiltered.length > 0) {

            const { arrayColumns, arrayRows } = buildRowsAndColumns(infrastructuresFiltered.filter(value => value !== undefined), 150, "antd", state, setstate);
            setAgreementChoosed(code);
            setAgreementChoosedYear(yearAgremente);
            const newArrayColumns = traslateInfrastructureName(arrayColumns);
            setInfrastructuresByAgreement({ arrayColumns: newArrayColumns, arrayRows });
        }
    }

    const handleClose = (e) => {
        setOpen(false);
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setInfrastructureChoosed(selectedRows);
        }
    };

    const handleOnOk = (e) => {
        if (infrastructureChoosed === undefined || agreementChoosed === undefined) {
            Swal.fire("Atención !!", "Hay almenos una infraestructura o convenio sin seleccionar", "warning");
            return;
        }

        delete infrastructureChoosed[0].key;

        // se establece cambio de infraestructura, programa o convenio
        setTraslates({
            "Codigo Institucion": parseInt(infrastructureChoosed[0].DANECode),
            "Codigo Infraestructura": infrastructureChoosed[0].uniqueCodeInfra,
            "Nombre Infraestructura": infrastructureChoosed[0].tradeName,
            program: programIDChoosed,
            "ID Convenio": agreementChoosed,
            "AniooDeConvenio": agreementChoosedYear
        });
        setOpen(false);
    }

    return (
        <>
            <Modal title="Traslados" visible={open} onOk={handleOnOk} onCancel={handleClose} centered width={1000}>

                <Space size="large">
                    <Select defaultValue={programID} style={{ width: 300 }} onChange={handleOnChangeprogramID}>
                        {archiveSchemas.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>

                    <Select
                        id="agreementID"
                        showSearch
                        style={{ width: 300 }}
                        placeholder="Selecciona un convenio"
                        optionFilterProp="children"
                        onChange={handleOnChangeAgreement}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {listAgreementsFiltered.map((option) => (
                            <Option key={option.code} value={option.year + '~' + option.code}>
                                {option.name}
                            </Option>
                        ))}
                    </Select>
                </Space>

                {
                    (arrayColumns.length > 0) &&
                    <Table rowSelection={{
                        type: "radio",
                        ...rowSelection
                    }} style={{ marginTop: 30 }} columns={arrayColumns} dataSource={arrayRows} />
                }
            </Modal>
        </>
    );

}