import { getToken } from "../../services/HttpService";

export const postNewTable = async (tableData) =>{

    /* const endPoint = `https://${types.apiID}.execute-api.us-east-1.amazonaws.com/users`;  */
    const endPoint = `${process.env.REACT_APP_API}/tables`; 
    const response = await getToken();
    const resp = await fetch(endPoint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(tableData)
    })

    return await resp.json();
}