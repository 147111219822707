import { CreateUsers } from "../components/admin/CreateUsers";
import { EditInfrastructures } from "../components/user/catalogList/EditInfrastructures";
import { EditSchemaProgram } from "../components/admin/EditSchemaProgram";
import { EditUsers } from "../components/admin/EditUsers";
import { SchemaPrograms } from "../components/admin/SchemaPrograms";
import { CatalogList } from "../components/user/catalogList/CatalogList";
import { CreateList } from "../components/user/catalogList/CreateList";
import { EditAgreements } from "../components/user/EditAgreements";
import { EditMonthAsistance } from "../components/user/EditMonthAsistance";
import { EditNonRequireds } from "../components/user/EditNonRequireds";
import RegisterMicroData from "../components/user/microData/Register";
import { PostAgreement } from "../components/user/postAgreement";
import { SchemaUpload } from "../components/user/SchemaUpload";
import { PostInfrastructure } from "../components/user/catalogList/PostInfrstructures";
import { ListOfBeneficiaryTransferRequests } from "../components/admin/listOfBeneficiaryTransferRequests";
import { ReportsContainer } from "../components/reports";
import { BeneficiaryTransferRequestDetail } from "../components/admin/BeneficiaryTransferRequestDetail";
import CompleteMicroData from "../components/user/microData/Complete";
import AssistancesMicroData from "../components/user/microData/Assistences";
import { DeleteBatchMicrodata } from "../components/user/DeleteBatchMicrodata";
import { ClousureOfYear } from "../components/admin/ClousureOfYear";

export const componentsAndNamesByPaths = (path) => {

    let listContent;

    switch (path) {
        case "/admin/schema":
            listContent = {
                listContentTab: [
                    { title: "Modificar formulario", component: EditSchemaProgram },
                    { title: "Crear formulario", component: SchemaPrograms }
                ],
                path: "/admin/schema"
            }
            return listContent;

        case "/admin/users":

            listContent = {
                listContentTab: [
                    { title: "Editar usuarios", component: EditUsers },
                    { title: "Crear usuarios", component: CreateUsers },
                ],
                path: "/admin/users"
            }
            return listContent;

        case "/admin/agreements":

            listContent = {
                listContentTab: [
                    { title: "Editar convenios", component: EditAgreements },
                    { title: "Convenios", component: PostAgreement }
                ],
                path: "/admin/agreements"
            }
            return listContent;

        case "/admin/tables":

            listContent = {
                listContentTab: [
                    { title: "Editar listas", component: CatalogList },
                    { title: "Crear lista", component: CreateList },
                    { title: "Editar infraestructuras", component: EditInfrastructures },
                    { title: "Agregar infraestructuras", component: PostInfrastructure },
                ],
                path: "/admin/tables"
            }
            return listContent;

        case "/admin/delete":
            listContent = {
                listContentTab:[
                    { title: "Borrar por lote registrado", component: DeleteBatchMicrodata },
                ],
                path: "/admin/delete"
            }
                
            return listContent;

        case "/admin/clousure":
            listContent = {
                listContentTab:[
                    { title: "Cierre de año", component: ClousureOfYear },
                ],
                path: "/admin/clousure"
            }
                
            return listContent;

        default:
            return null;
    }
}


export const componentsAndNamesByUserPath = (path, id) => {
    let listContentTab;
    switch (path) {
        case "/user/microdata":
            listContentTab = [
                { title: "Formulario", component: SchemaUpload },
                { title: "Registrar archivo", component: RegisterMicroData },
            ]
            return listContentTab;

        case "/user/updatemicrodata":
            listContentTab = [
                { title: "Editar / Solicitar", component: EditNonRequireds },
                { title: "Formulario de asistencias", component: EditMonthAsistance },
                { title: "Archivo de campos faltantes", component: CompleteMicroData },
                { title: "Archivo para actualizar asistencias", component: AssistancesMicroData },
            ]
            return listContentTab;

        case "/user/list/beneficiary/transfer/requets":
            listContentTab = [
                { title: "Aceptar solucitudes", component: ListOfBeneficiaryTransferRequests }
            ]
            return listContentTab;

        case `/user/beneficiary/transfer/request/${id}`:
            listContentTab = [
                { title: "Solicitudes", component: BeneficiaryTransferRequestDetail }
            ]
            return listContentTab;
        
        default:
            return null;
    }
}

export const componentsAndNamesByReportsPath = (path, id) => {

    let listContentTab;

    if(path === "/reports/data"){
        listContentTab = [
            { title: "Reportes", component: ReportsContainer }
        ]
        return listContentTab;
    }else{
        return null;
    }
}
