import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


export const Tags = ({props}) => {
  return (
      <Autocomplete
        multiple
        disableListWrap
        onChange={(event, result) =>{ 
          let listInfraestruturaLabel = [];
          result.map(value => listInfraestruturaLabel.push(value.label));
          return props.onChange(listInfraestruturaLabel);
        }}
        id={props.id}
        getOptionSelected ={(option, value) => option.value === value.value}
        options={props.options.enumOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={props.schema.title}
            placeholder={props.schema.title}
          />
        )}
      />
  );
}
