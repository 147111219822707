import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { getOneMicrodata } from '../../helpers/user/getOneMicrodata';
import { postAsistances } from '../../helpers/user/postAsistances';
import { message, Input, Select, Button, Space, Tag } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { useForm } from '../../hooks/useForm';
import './styles/editMonthAsistance.css';
import Swal from 'sweetalert2';

export const EditMonthAsistance = () => {

    //TODO:COnvenrtir en arreglo para que el formulario este en orden al cargar los datos
    const initialState = {
        enero: 0,
        febrero: 0,
        marzo: 0,
        abril: 0,
        mayo: 0,
        junio: 0,
        julio: 0,
        agosto: 0,
        septiembre: 0,
        octubre: 0,
        noviembre: 0,
        diciembre: 0
    };

    const [assistance, setAssistance] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [originalAssistance, setOriginalAssistance] = useState(initialState);
    const [beneficiaryName, setBeneficiaryName] = useState("");
    const [agreementSelected, setAgreementSelected] = useState({ name: null, code: null })
    const [{ id, agreementID }, setIdAndAgreementId] = useState({ agreementID: "", id: "" });
    const [[sortKey, userId, enrollDate], setUserRecord] = useState([null, null]);
    const { agreements, openYears } = useSelector(state => state.auth);

    const [values, handlerInputChange, reset] = useForm({
        idBeneficiary: ""
    });

    const { idBeneficiary, } = values;


    useEffect(() => {
        const fetchData = async () => {
            if (id !== "" && agreementID !== "") {
                setLoading(true);

                const microData = await getOneMicrodata({ id, agreementID }, true);
                setLoading(false);
                const [microdata] = microData.payload.items;
                let isClosedYear = microData.payload.isClosedYear;
                const [result] = agreements.filter(data => data.code === agreementID);
                if (isClosedYear) {
                    message.info(`Año y convenio no habilitado para edición (${result.year})`);
                    setAgreementSelected({ name: null, code: null });
                    reset();
                    return;
                }
                if (microdata) {
                    const { assistances, range } = microdata;
                    const identityNumber = microdata["Nro Identificacion"];
                    setUserRecord([range, identityNumber, microdata.properties["Fecha Vinculacion"]]);
                    setAssistance(assistances);
                    setOriginalAssistance(assistances);
                    setAgreementSelected({ name: null, code: null });
                    setBeneficiaryName(`${microdata.properties["Primer Nombre"]} ${microdata.properties["Primer Apellido"]}`)
                    reset();
                } else {
                    message.info("No se encontraron registros, verifique el id o el convenio");
                    setAgreementSelected({ name: null, code: null });
                    reset();
                }
            }
        }
        fetchData();
    }, [id, agreementID, setLoading]);

    const handlerOnSubmitFilter = () => {

        if (idBeneficiary === "") {
            message.warning("El ID del Beneficiario esta vacio");
            return;
        }

        if (agreementSelected.code === null) {
            message.warning("Debe seleccionar un convenio");
            return;
        }
        setIdAndAgreementId({
            agreementID: agreementSelected.code,
            id: idBeneficiary
        });

    }

    const onSetAgreement = (event) => {
        const [agreementFiltered] = agreements.filter(data => data.code === event);
        setAgreementSelected(agreementFiltered);
    }

    const handlerOnSubmitAssistance = () => {
        const request = {
            id: userId,
            dates: assistance,
            range: sortKey,
            enrollDate
        }
        Swal.fire({
            title: "Asistencias",
            confirmButtonText: "Aceptar",
            text: "Recuerda que solo se guardaran las asistencias en los meses que cumplan con las politicas",
            icon: "info"
        }).then(() => {
            postAsistances(request).then((response) => {
                if (response.status) {
                    Swal.fire("Asistencias", "Asistencias guardadas!!", "success");
                    setUserRecord([null, null]);
                    setAgreementSelected({ name: null, code: null });
                    setIdAndAgreementId({ agreementID: "", id: "" });
                    reset();
                }
            })
                .catch(error => {
                    Swal.fire("Asistencias", error.Error, "error");
                });
        });
    }


    const handlerOnChangeAsistance = (e) => {
        if (e.target.value) {
            const asistanceNumber = e.target.value;
            const newNumber = Number.parseInt(asistanceNumber);
            const oldNumber = Number.parseInt(originalAssistance[e.target.id]);
            let addNumber = oldNumber + newNumber;
            if (addNumber < 0) addNumber = 0;
            if (addNumber > daysBYMonth(e.target.id, new Date().getUTCFullYear())) {
                message.error(`${e.target.id} tiene ${daysBYMonth(e.target.id, new Date().getUTCFullYear())} días, no puedes agregar más `);
                return
            }
            setAssistance({ ...assistance, [e.target.id]: addNumber });
        }
    }

    const daysBYMonth = (month, year) => {
        const arrayMonths = Object.keys(initialState);
        const monthNumber = arrayMonths.indexOf(month) + 1;
        return new Date(year, monthNumber, 0).getDate();
    }

    return (
        <div className="containerMonthAsistances animate__animated animate__fadeIn">

            <div className="boxAssistanceLeft">

                <Space size="large" direction="vertical">
                    <div style={{ width: 276 }}>
                        <label htmlFor="agreementID">Convenio</label>
                        <Select
                            className="controlAssistance"
                            id="agreementID"
                            showSearch
                            value={agreementSelected.code}
                            style={{ width: "100%" }}
                            placeholder="Selecciona un convenio"
                            optionFilterProp="children"
                            onChange={onSetAgreement}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {agreements.map((value) =>
                                (<Select.Option key={value.code} value={value.code}>{value.name}</Select.Option>)
                            )}
                        </Select>
                    </div>

                    <div style={{ width: 276 }}>
                        <label htmlFor="idBeneficiary">ID del beneficiario</label>
                        <Input
                            autoComplete="off"
                            onChange={handlerInputChange}
                            value={idBeneficiary}
                            className="controlAssistance"
                            name="idBeneficiary"
                            id="idBeneficiary"
                            style={{ width: "100%" }}
                            placeholder="Esciba el ID" />
                    </div>
                </Space>

                <Button
                    id="btnLogin"
                    loading={loading}
                    className="btnLogin"
                    type="primary"
                    shape="round"
                    onClick={handlerOnSubmitFilter}
                    size="large"
                    icon={<SearchOutlined style={{ fontSize: "20px" }} />}
                    style={{
                        width: "100%",
                        background: "#345AB3",
                        borderColor: "#345AB3",
                        marginTop: "30px"
                    }}
                >
                    {(!loading) ? "Filtrar" : "Filtrando"}
                </Button>

            </div>
            {
                (userId) &&
                (
                    <div className="boxAssistanceRight">
                        <Tag style={{ fontSize: "14px", marginBottom: "10px" }} color="rgba(241, 101, 34,0.7)">
                            {`${beneficiaryName} - ID: ${id}`}
                        </Tag>
                        <div className="individualMonthContainer">

                            {
                                Object.keys(initialState).map((value, i) =>

                                (
                                    <div className="individualMonth" key={i}>
                                        <label htmlFor={value}>{value}</label>
                                        <Input
                                            prefix={<Tag color="#87d068">Asistencias {assistance[value]}</Tag>}
                                            min={-31}
                                            max={31}
                                            type="number"
                                            id={value}
                                            className="monthField"
                                            name={value}
                                            onChange={handlerOnChangeAsistance}
                                            defaultValue={0} />
                                    </div>)
                                )
                            }
                            <Button
                                id="btnLogin"
                                className="btnLogin"
                                type="primary"
                                shape="round"
                                onClick={handlerOnSubmitAssistance}
                                size="large"
                                style={{
                                    width: 200,
                                    background: "#345AB3",
                                    borderColor: "#345AB3",
                                    marginTop: "30px",
                                    left: 200
                                }}
                            >
                                Guardar asistencias
                            </Button>
                        </div>

                    </div>

                )
            }

        </div>
    )
}
