import React, { Fragment } from "react";
import CloudUpload from "../../assets/images/cloudUpload.svg";
import { Button, Upload, message, Space, Typography } from "antd";
import { FileExcelTwoTone } from "@ant-design/icons";
const { Title } = Typography;

const { Dragger } = Upload;

export const UploadButton = ({ setUploadFile, uploadedFile }) => {

  const draggerProps = {
    name: "file",
    accept: ".xls, .xlsx",
    showUploadList: false,
    beforeUpload: (file) => {
      if (file.size < 100000) {
        return true;
      } else {
        return false;
      }
    },
    onStart: (file) => {
      return true;
    },
    customRequest: (info) => {
      const { size } = info.file;
      if (size > 100000) {
        message.error(`El archivo ${info.file.name} es demasiado pesado.`);
      } else {
        setUploadFile(info.file);
      }
      return info.file;
    },
  };

  return (
    <Fragment>
      <Dragger {...draggerProps} style={{ padding: "10px" }}>
        {uploadedFile ? (
          <Space direction="vertical">
            <FileExcelTwoTone
              twoToneColor="#389e0d"
              style={{ fontSize: "90px", padding: "10px" }}
            />
            <Title level={5}>{uploadedFile.fileName}</Title>
          </Space>
        ) : (
          <p className="ant-upload-drag-icon">
            <img src={CloudUpload} alt="" />
          </p>
        )}
        <p className="ant-upload-text">
          <Button
            type="primary"
            shape="round"
            size={uploadedFile ? "small" : "large"}
            style={{ background: "#345AB3", borderColor: "#345AB3" }}
          >
            {uploadedFile
              ? "Seleccionar otro archivo"
              : "Seleccione un archivo a subir"}
          </Button>
        </p>
        {!uploadedFile && (
          <p className="ant-upload-hint" style={{ marginTop: "15px" }}>
            (XLS, XLSX Máximo 100KB)
          </p>
        )}
      </Dragger>
    </Fragment>
  );
};