import { getToken } from "../../services/HttpService";



export const putUser = async (request) => {

      /* const endPoint = `https://${types.apiID}.execute-api.us-east-1.amazonaws.com/users`;  */
      const endPoint = `${process.env.REACT_APP_API}/users`; 
      const response = await getToken();

      const resp = await fetch(endPoint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
          method: 'PUT',
          body: JSON.stringify(request)
      })
  
      return await resp.json();
}
