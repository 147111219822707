export const buildPropertiesAndRules = (formData) =>{

    const keys = Object.keys(formData);
    const values = Object.values(formData);

    let schemaProperties={};
    let rules = {};
    let requireds = [];

    keys.forEach( (key,i) =>{

        if(values[i].dataType !== 'date'){    
            schemaProperties ={...schemaProperties,
                [key]:{
                    "type":values[i].dataType,
                    "title":key
                }
            }
        }else{
            schemaProperties ={...schemaProperties,
                [key]:{
                    "type":"string",
                    "title":key,
                    "format": "date-time"
                }
            }
        }

        if(values[i].rules){
            for(let item of values[i].rules){
                (item.rule === "obligatorio") && requireds.push(key);
            }

            let rulesObj ={};
            values[i].rules.forEach(value =>{
                rulesObj = {...rulesObj,
                    [value.rule]:value
                }
            })

            rules = {...rules,
                [key]:{
                    rules:rulesObj
                }
            }
        }
    });


    const rulesKey = Object.keys(rules);
    
    rulesKey.forEach((key) =>{
        let rulesByField = rules[key].rules;

        if(rulesByField.limitado){
            if(schemaProperties[key].type === "integer"){
                
                if(rulesByField.limitado.maxNumber){
                    schemaProperties[key]={...schemaProperties[key],
                        maximum: rulesByField.limitado.maxNumber
                    }
                }

                if(rulesByField.limitado.minNumber){
                    schemaProperties[key]={...schemaProperties[key],
                        minimum: rulesByField.limitado.minNumber
                    }
                }
            }

            if(schemaProperties[key].type === "string"){
                if(rulesByField.limitado.numberOfDigits){
                    schemaProperties[key]={...schemaProperties[key],
                        maxLength: rulesByField.limitado.numberOfDigits
                    }
                }

                if(rulesByField.limitado.onlyLetters){
                    schemaProperties[key]={...schemaProperties[key],
                        pattern: "^[A-Za-zÁÉÍÓÚñáéíóúÑ\\s]+[A-Za-zÁÉÍÓÚñáéíóúÑ]$"
                    }
                }

                if(rulesByField.limitado.noSpecialCharacter){
                    schemaProperties[key]={...schemaProperties[key],
                        pattern: "^[a-zA-Z0-9 -]*$"
                    }
                }
            }
        }
        
    })



    return {
        schemaProperties,
        rules,
        requireds
    }
}