import { getToken } from "../../services/HttpService";

export const postInfrastructures = async (request) => {

    const endPoint = `${process.env.REACT_APP_API}/infrastructures`; 
    const response = await getToken();
    const resp = await fetch(endPoint, {
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(request)
    })

    return await resp.json();
}
