import { getToken } from "../../services/HttpService";

export const putTables = async (request) => {

    const endPoint = `${process.env.REACT_APP_API}/tables`; 
    const response = await getToken();
    const resp = await fetch(endPoint,{
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method: 'PUT',
        body: JSON.stringify(request),
        redirect: "follow"
    })

    return await resp.json();
}