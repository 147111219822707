

export class WebSocketClient {

    constructor(wssUri) {
        this.socketConn = new WebSocket(wssUri);
        this.messageHandler = null; // Inicializa la propiedad
        this.socketConn.onmessage = (event) => {
            if (this.messageHandler) {
                this.messageHandler(event.data);
            }
        };
    }

    openConnection() {
        this.socketConn.onopen = (event) => { console.log('Websocket is connected') }

    }

    closeConnection() {
        this.socketConn.addEventListener('close', e => console.log('WebSocket Connection is closed'));
        this.socketConn.close();
    }

    sendMessage(token) {

        const message = {
            action: "sendMessage",
            message: {
                token
            }
        }

        setTimeout(() => {
            this.socketConn.send(JSON.stringify(message))
        }, 1000)
    }

    setMessageHandler(callback) {
        this.messageHandler = callback;
    }

    mensaje() {
        return this.socketConn.onmessage;
    }
}