import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const ComboBox = ({props}) => {

  return (
    <Autocomplete
      id={props.id}
      blurOnSelect
      autoSelect
      clearOnEscape
      onChange={(event, newValue) => {if(event.target.innerText !== "" && newValue); return props.onChange(newValue.value)}}
      options={props.options.enumOptions}
      getOptionSelected ={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      style={{ width: "100%" }}
      renderInput={(params) => {return <TextField  {...params} label={props.label} variant="standard" />}}
    />
  );
}

export const ComboBoxDefault = ({props, isRequired=false, value=null}) => {
  return (
    <Autocomplete
      id={props.id}
      blurOnSelect
      autoSelect
      clearOnEscape
      onChange={(event, newValue) => {if(event.target.innerText !== "" && newValue)  return props.onChange(newValue.value)}}
      options={props.options.enumOptions}
      getOptionSelected ={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      style={{ width: "100%" }}
      renderInput={(params) => {
        if(value){
          params.inputProps.value=value; 
        }
        return <TextField {...params} label={props.label} variant="standard" />}}
    />
  );
}

