export const initialSchema = (dataSource, listFields)=>{

    dataSource = [...new Set(dataSource)];

    return {
        "type": "object",
        "properties": {
          "dataType": {
            "type": "string",
            "title":"Tipo Dato",
            "enum": [
              "string",
              "integer",
              "date"
            ],
            "enumNames":["texto", "numero", "Fecha"],
            "default": "string"
          }
        },
        "required": [
          "dataType"
        ],
        "dependencies": {
          "dataType": {
            "oneOf": [
              {
                "properties": {
                  "dataType": {
                    "enum": [
                      "string"
                    ]
                  },
                  "rules": {
          "title": "Reglas",
          "type": "array",
          "items": {
              "title": "Regla",
              "type": "object",
              "properties": {
                  "rule": {
                      "type": "string",
                      "title":"Regla",
                      "enum": [
                          "obligatorio",
                          "listado",
                          "limitado",
                          "especial",
                          "omitir"
                      ],
                      "default": "obligatorio"
                  }
              },
              "required": [
              "rule"
              ],
              "dependencies": {
                  "rule": {
                      "oneOf": [
                          {
                              "properties": {
                              "rule": {
                                  "enum": [
                                  "obligatorio"
                                  ]
                              }
                          }
                          },
                          {
                              "properties": {
                                  "rule": {
                                      "enum": [
                                      "listado"
                                      ]
                                  },
                                  "bindDataSource": {
                                      "type": "string",
                                      "title": "Enlazar Tabla de referencia",
                                      "enum": dataSource
                                  },
                                  "autocomplete":{
                                      "type":"boolean",
                                      "title":"Autocompletado ?",
                                      "default": false
                                  },
                                  "generateCode":{
                                    "type":"string",
                                    "title": "Elige un campo generar codigo",
                                    "enum":listFields
                                }
                              }
                          },
                          {
                              "properties": {

                                  "rule": {
                                      "enum": [
                                          "limitado"
                                      ]
                                  },
                                  "numberOfDigits": {
                                      "type": "number",
                                      "title": "Cantidad de Caracteres",
                                      "minimum": 1,
                                  },
                                  "onlyLetters": {
                                    "type": "boolean",
                                    "title": "Solo letras ?",
                                    "default":false
                                  },
                                  "noSpecialCharacter":{
                                    "type": "boolean",
                                    "title": "Bloquear caracteres especiales ?",
                                    "default":false
                                  }
                              }
                          },
                          {
                              "properties": {
                                  "rule": {
                                      "enum": [
                                      "especial"
                                      ]
                                  },
                                  "bindDataSource": {
                                      "type": "string",
                                      "title": "Origen de selección",
                                      "enum":[
                                          "Infraestructura"
                                      ]
                                  }
                              },
                              "dependencies":{
                                "bindDataSource":{
                                    "oneOf":[
                                        {
                                            "properties": {
                                                "bindDataSource": {
                                                    "enum": [
                                                    "Infraestructura"
                                                    ]
                                                },
                                                "autocomplete":{
                                                    "type":"boolean",
                                                    "title":"Autocompletado ?",
                                                    "default": false
                                                },
                                                "multiple":{
                                                    "type":"boolean",
                                                    "title":"Selección Multiple ?",
                                                    "default": false
                                                },
                                                "generateField":{
                                                    "type":"array",
                                                    "title":"Relación de Campos",
                                                    "items":{
                                                        "type": "object",
                                                        "properties": {
                                                            "fieldsOfForm": {
                                                                "type": "string",
                                                                "title":"Elija Campo de formulario",
                                                                "enum":listFields
                                                            },
                                                            "fieldsOfInfrastructure": {
                                                                "type": "string",
                                                                "title":"Elija Campo de Infraestructura",
                                                                "enum":[
                                                                    "uniqueCodeInfra",
                                                                    "DANECode",
                                                                    "category",
                                                                    "tradeName"
                                                                ],
                                                                "enumNames":[
                                                                    "Codigo Infraestructura",
                                                                    "Codigo DANE/MEN",
                                                                    "Categoria",
                                                                    "Nombre Infraestructura"
                                                                ]
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                              }
                          },
                          {
                              "properties": {
                              "rule": {
                                  "enum": [
                                  "omitir"
                                  ]
                              }
                          }
                          }
                      ]
                  }
              }
          }
      }
                }
              },
              {
                "properties": {
                  "dataType": {
                    "enum": [
                      "integer"
                    ]
                  },
                 "rules": {
          "title": "Reglas",
          "type": "array",
          "items": {
              "title": "Regla",
              "type": "object",
              "properties": {
                  "rule": {
                      "type": "string",
                      "title":"Regla",
                      "enum": [
                          "obligatorio",
                          "limitado",
                          "omitir"
                      ],
                      "default": "obligatorio"
                  }
              },
              "dependencies": {
                  "rule": {
                      "oneOf": [
                          {
                              "properties": {
                              "rule": {
                                  "enum": [
                                  "obligatorio"
                                  ]
                              }
                          }
                          },
                          {
                              "properties": {
                                  "rule": {
                                      "enum": [
                                          "limitado"
                                      ]
                                  },
                                  "maxNumber": {
                                      "type": "number",
                                      "title": "Maximo",
                                      "minimum": 1
                                  },
                                  "minNumber": {
                                      "type": "number",
                                      "title": "Minimo",
                                      "minimum": 0
      
                                  }
                              }
                          },
                          {
                              "properties": {
                              "rule": {
                                  "enum": [
                                  "omitir"
                                  ]
                              }
                          }
                          }
                      ]
                  }
              }
              
          }
      }
                }
              },
              {
                "properties": {
                  "dataType": {
                    "enum": [
                      "date"
                    ]
                  },
                  "rules": {
          "title": "Reglas",
          "type": "array",
          "items": {
              "title": "Regla",
              "type": "object",
              "properties": {
                  "rule": {
                      "type": "string",
                      "title":"Regla",
                      "enum": [
                          "obligatorio",
                          "omitir",
                          "calculo"
                      ],
                      "default": "obligatorio"
                  }
              },
              "required": [
              "rule"
              ],
              "dependencies": {
                  "rule": {
                      "oneOf": [
                          {
                              "properties": {
                                "rule": {
                                    "enum": [
                                    "obligatorio"
                                    ]
                                }
                          }
                          },
                          {
                              "properties": {
                                "rule": {
                                    "enum": [
                                    "omitir"
                                    ]
                                }
                          }
                          },
                        {
                            "properties": {
                                "rule": {
                                    "enum": [
                                    "calculo"
                                    ]
                                },
                                "age":{
                                    "type":"string",
                                    "title":"Campo a Calcular",
                                    "enum":["age"],
                                    "enumNames":["Edad"]
                                }  
                            }
                        },
                    ]
                  }
              }
              
          }
      }
                }
              }
            ]
          }
        }
      }
}