import { getToken } from "../../services/HttpService";



export const postMicrodata = async model => {
    const endPoint = `${process.env.REACT_APP_API}/microdataform`;
    const response = await getToken(); 

    const resp = await fetch(endPoint, {
        headers: {
            Authorization: `Bearer ${response.token}`,
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(model)
    });

    return await resp.json();
}

export const setFileMicrodata = async formData => {
    
    const response = await getToken();
    const resp = await fetch(
        `${process.env.REACT_APP_API}/microdata`,
        {
            headers: {
                Authorization: `Bearer ${response.token}`
            },
            method: 'POST',
            body: formData
        }
    )

    return await resp.json();
}

export const putFileMicrodata = async formData => {
    const response = await getToken();
    const resp = await fetch(
        `${process.env.REACT_APP_API}/microdata`,
        {
            headers: {
                Authorization: `Bearer ${response.token}`
            },
            method: 'PUT',
            body: formData
        }
    )

    return await resp.json();
}

export const putAssistanceFileMicrodata = async formData => {
    const response = await getToken();
    const resp = await fetch(
        `${process.env.REACT_APP_API}/microdata/assistance`,
        {
            headers: {
                Authorization: `Bearer ${response.token}`
            },
            method: 'PUT',
            body: formData
        }
    )

    return await resp.json();
}

