import React, { useState, useEffect } from 'react'
import { Container } from "@material-ui/core"
import { Button, message, Table } from 'antd'
import { remove } from 'lodash'
import moment from 'moment'
import { buildRowsAndColumns } from '../../services/buildRowsAndColumns.service'
import { deleteBatchMicrodata, listBatchMicrodata } from '../../helpers/user/deleteBatchMicrodata'
import Swal from 'sweetalert2'
import { traslateErrorUpload } from '../../services/traslateErrorUpload.service'
import './styles/deleteStyles.css'
export const DeleteBatchMicrodata = () => {

    const [uploadFiles, setUploadFiles] = useState([]);
    const [deleted, setDeleted] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [{ columns, rows }, setContentTable] = useState({ columns: [], rows: [] });
    const [loadingTable, setLoadingTable] = useState(true);
    const [serchState, setserchState] = useState({
        searchText: '',
        searchedColumn: '',
    });
    const [requestIDSelected, setRequestIDSelected] = useState(null);

    const buildTable = (listData) => {
        const newListData = listData.map(data => {
            data.uploadDate = moment(data.uploadDate).format('YYYY/MM/DD LTS');
            return data;
        });
        const { arrayColumns, arrayRows: rows } = buildRowsAndColumns(newListData, 150, "antd", serchState, setserchState);
        const newArrayColumns = traslateErrorUpload(arrayColumns);
        setContentTable({ columns: newArrayColumns, rows });
        setLoadingTable(false);
    }

    useEffect(
        () => {
            if (uploadFiles && !uploadFiles.length) {
                listBatchMicrodata().then(data => {

                    if (data.Items.length > 0) {
                        buildTable(data.Items);
                        setUploadFiles(data.Items);
                    } else {
                        message.info("No hay cargas registradas");
                    }
                })
            }
        },
        [uploadFiles, buildTable]
    )


    const onHandleSubmit = () => {
        if (requestIDSelected === null) {
            message.warn("Debe seleccionar una petición de carga a eliminar");
            return;
        }

        Swal.fire({
            customClass: {
                popup: 'popupV2',
                actions: 'accionsV2',
                cancelButton: 'buttonCancel',
                footer: 'pie'
            },
            html: "<div class='modal-container'><div class='modal-header'><div class='modal-header-title'><div class='header-icon'>?</div><div class='header-title'>Eliminar seleccionados</div></div><div class='modal-header-content'><p class='small-msg'> Se eliminarán todos los beneficiarios asociados a la carga(s) de manera permanente, y esta acción no se puede revertir </p> </div></div></div>",
            footer: `<small> ${requestIDSelected.map(value => value.requestId)} </small>`,
            showCancelButton: true,
            cancelButtonText: `Cancelar`,
            confirmButtonText: `Borrar`,
        }).then((result) => {
            if (result.isConfirmed) {
                setDeleted(true)
                deleteBatchMicrodata({ requests: requestIDSelected })
                    .then(response => {




                        setDeleted(false);
                        setSelectedRowKeys([]);



                        Swal.fire({
                            title: 'Procesando petición',
                            text: 'Esta acción puede tardar, por favor aguarde a recibir una notificación',
                            confirmButtonText: `Aceptar`,
                        });

                        listBatchMicrodata().then(data => {
                            if (data.Items.length > 0) {

                                buildTable(data.Items);
                                setUploadFiles(data.Items);
                            } else {
                                message.info("No hay cargas registradas");
                            }
                        });


                    })
                    .catch(error => {
                        console.error('Error al procesar las solicitudes:', error);
                        Swal.fire({
                            text: 'Algo fallo',
                            confirmButtonText: `Aceptar`,
                            icon: 'error'
                        });
                    });

            } else {
                // setDeleted(false);
                //setSelectedRowKeys([]);
            }
        });
    }



    const removeUploadFile = (requestId) => {
        let uploadFilesAux = uploadFiles
        remove(uploadFilesAux, { requestId })
        setUploadFiles(uploadFilesAux)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length <= 10) {
                setSelectedRowKeys(selectedRowKeys);
                setRequestIDSelected(selectedRows);
            } else {
                Swal.fire({
                    text: 'Solo se pueden seleccionar 10 registros máximo.',
                    confirmButtonText: `Aceptar`,
                    icon: 'error'
                });
            }

        }
    };



    return (
        <div className="animate__animated animate__fadeIn" style={{ paddingBottom: 45 }}>
            <Container>

                <Table rowSelection={{
                    type: "checkbox",
                    ...rowSelection
                    , selectedRowKeys
                }} columns={columns} dataSource={rows} scroll={{ x: '100vw', y: 500 }} size="middle" loading={loadingTable} />

                <Button
                    disabled={(selectedRowKeys.length > 0) ? false : true}
                    loading={deleted}
                    onClick={onHandleSubmit}>
                    {(!deleted) ? "Borrar" : "Borrando"}
                </Button>
            </Container>
        </div>
    )
}