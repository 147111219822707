import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import Form from '@rjsf/material-ui';
import Swal from 'sweetalert2';
import { Table, message, Button, Empty} from 'antd';
import { SaveOutlined} from "@ant-design/icons";
import { saveAgreement } from '../../helpers/user/postAgremeents';
import { buildUiSchema } from '../../services/buildUiSchema.service';
import { Tags } from '../generics/autoCompleteFIledMultiselect';
import { buildRowsAndColumns } from '../../services/buildRowsAndColumns.service';
import { getAgreementStructure } from '../../helpers/user/getAgreementStructure';
import { transformError } from '../../services/transformError';
import './styles/editAgreements.css'
import { traslateInfrastructureName } from '../../services/traslateInfrastructureName.service';

export const PostAgreement = () => {

    const initialState = {rows:[], columns:[]}
    const [schemas, setSchemas] = useState([]);
    const [previusModality, setPreviusModality] = useState({});
    const [schemaForm, setSchemaForm] = useState();
    const [dataQuery, setDataQuery] = useState();
    const [{arrayColumns, arrayRows}, setlistInfrastructures] = useState(initialState);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [state, setstate] = useState({
        searchText: '',
        searchedColumn: '',
      });
    const [loading, setLoading] = useState(true);
    const {name} = useSelector(state => state.auth);
    const memoAbortController = useMemo(() => new AbortController(), [] );


    const rowsAndColumns = useCallback( (listData) =>{
        const {arrayColumns, arrayRows} = buildRowsAndColumns(listData, 150, "antd", state, setstate);
        return { arrayColumns, arrayRows};
    }, [state]);   
    
    const bringAllData = useCallback( () =>{

        getAgreementStructure(memoAbortController).then((values) =>{ 
            setDataQuery(values.dataQuery);
            const {arrayColumns, arrayRows} = rowsAndColumns(values.dataQuery.infrastructures);
            const newArrayColumns = traslateInfrastructureName(arrayColumns);
            setlistInfrastructures({ arrayColumns:newArrayColumns, arrayRows});
            setSchemas(values.items);
            const rulesKey = Object.keys(values.items.rules);
            const rules = values.items.rules;
            let uiSchema = buildUiSchema(rulesKey, rules);
            
            setSchemaForm({
                schema:{
                    "codeSchema": values.items.code,
                    "title": values.items.archive,
                    "type":"object",
                    "properties":values.items.properties,
                    "required": values.items.required
                },
                uiSchema:{
                    ...values.items.uiSchema,
                    ...uiSchema
                }
            });
            setLoading(false);
        }).catch(err => console.error);
    }, [memoAbortController, rowsAndColumns])

    //TODO: traer solo el schema del convenio, implica otra lambda
    useEffect(() => {

        bringAllData();

        return () => {
            memoAbortController.abort()
        }
  
    }, [memoAbortController, bringAllData]);

    const   handlerOnChamgeSchema = (e) =>{
        const listFormDataKey = Object.keys(e.formData);
        const  {properties} = e.schema;
        let uiSchema = {...e.uiSchema};

        //AIN
        if(e.formData["ID Programa"] === "13" && previusModality !== e.formData["ID Programa"]){
            const ainData = dataQuery.modalidad.AIN;
            const listAin = [" "];
            ainData.map(value => listAin.push(value.name));
            properties["Modalidad"] = {type:"string", enum: listAin}
            delete uiSchema.Modalidad;
        }

        //JEC
        if(e.formData["ID Programa"] === "12" && previusModality !== e.formData["ID Programa"]){
            const jecData = dataQuery.modalidad.JEC;
            const listJec = [" "];
            jecData.map(value => listJec.push(value.name));
            properties["Modalidad"] = {type:"array", title:"Modalidad", uniqueItems:true, items:{type:"string",enum: listJec}}
     
            uiSchema = {
                ...e.uiSchema,
                "Modalidad":{
                    "ui:widget":(props) => <Tags props={props} />
                }
            };
        }

        listFormDataKey.forEach((key) =>{
            properties[key] = {
                ...properties[key],
                default: e.formData[key]
            }
        });

        e.uiSchema = {...uiSchema}
        setPreviusModality(e.formData["ID Programa"]);
        setSchemaForm({...e});
    }

    const handlerSubmit = (e) =>{
        
        let {formData} = e;
        if(formData.Infraestructuras.length <= 0){
            message.error("Debe agrega almenos una infraestructura");
            return;
        }
        let params = {};
        //==== busca regla de generar codigo y los adiciona a sus respectivos campos ===//
        const schemaChoosed = schemas;

        const listSchemaKey = Object.keys(schemaChoosed.rules);
        listSchemaKey.forEach(value =>{
            if(schemaChoosed.rules[value].rules.listado){
                if(schemaChoosed.rules[value].rules.listado.generateCode && schemaForm.schema.properties[value].enumNames){

                    const index = schemaForm.schema.properties[value].enum.indexOf(formData[value]);
                    const resultNames = schemaForm.schema.properties[value].enumNames[index];
                    const valueName = schemaChoosed.rules[value].rules.listado.generateCode;
                    if(formData[value]){
                        formData = {...formData, 
                            [valueName]: formData[value],
                            [value]:resultNames
                        }
                    }
                }else if(schemaForm.schema.properties[value].enumNames){
                    const index = schemaForm.schema.properties[value].enum.indexOf(formData[value]);
                    const resultNames = schemaForm.schema.properties[value].enumNames[index];
                    if(resultNames){
                        formData = {...formData, 
                            [value]:resultNames
                        }
                    }
                }
            }
            
            if(schemaChoosed.rules[value].rules.especial){
                if(schemaChoosed.rules[value].rules.especial.generateField){
                    const listOfFields = schemaChoosed.rules[value].rules.especial.generateField;
                    const infrastructureFiltered = dataQuery.infrastructures.filter(item => item.tradeName === formData[value]);
                    listOfFields.forEach(dataField =>{
                        formData = {
                            ...formData,
                            [dataField.fieldsOfForm]:infrastructureFiltered[0][dataField.fieldsOfInfrastructure]
                        }
                    })
                }
            }
            
        });
        
        params = {
            ...formData,
            changes:{user: name}
        }
        
        Swal.fire({
            title: 'Convenios',
            text:"¿ Desea guardar los registros ?",
            footer:"<small> Recuerda Asignar el convenio a un usuario para ser utilizado</small>",
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: `Cancelar`,
            confirmButtonText: `Guardar`,
            denyButtonText: `No Guardar`,
          }).then((result) => {
            if (result.isConfirmed) {
                saveAgreement(params)
                .then((resp) => {
                    if(resp.status){
                        Swal.fire('Guardado!', "Guardado Exitoso", 'success');
                        //limpia formulario
                        setSchemaForm();
                        const listFormDataKey = Object.keys(schemaForm.formData);
                        const  {properties} = schemaForm.schema;
                        listFormDataKey.forEach((key) =>{
                            delete properties[key].default
                        });
                        setSchemaForm({...schemaForm, properties});
                        setSelectedRowKeys([]);
                    }
                    else{
                        Swal.fire('Error!',"'"+ resp.message + "'", 'error');
                    } 
                })
            } else if (result.isDenied) {
              Swal.fire('No Guardado', '', 'info');
            }
        });
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          let schema = {...schemaForm};
          let listInfrastructureNames = selectedRows.map((infra) => infra.tradeName);
          schema.schema.properties.Infraestructuras.default = listInfrastructureNames;
          setSelectedRowKeys(selectedRowKeys);
          setSchemaForm({...schema});
        }
    };

    return (
        <div className="divEditAgreements animate__animated animate__fadeIn">

            <div className="divFormEditAgreement">
                <div className= "mainForm">
                    {(schemaForm) ? 
                    <Form 
                    noHtml5Validate={true}
                    uiSchema={schemaForm.uiSchema} 
                    schema={schemaForm.schema} 
                    onSubmit = {handlerSubmit} 
                    onChange = {handlerOnChamgeSchema} 
                    autoComplete="off"
                    transformErrors={transformError}
                    showErrorList={false}>
                        <Button
                        type="primary"
                        shape="round"
                        size="large"
                        htmlType={'submit'}
                        icon={
                        <SaveOutlined
                        style={{
                            fontSize: "20px",
                        }}
                        />
                        }
                        style={{
                            width: 130,
                            background: "#345AB3",
                            borderColor: "#345AB3",
                            marginTop: "30px",
                            display:"flex",
                            alignItems:"center",
                        }}
                        >
                        Guardar
                        </Button>
                        </Form>
                    :  <Empty imageStyle={{
                        height: 250,
                    }} description={<span>No hay datos</span>} />
                    
                    
                    }
                </div>

                <div className="divInfrastructure">

                    <Table rowSelection={{
                        type:"checkbox",
                        selectedRowKeys,
                        ...rowSelection
                    }} 
                    loading={loading}
                    columns={arrayColumns} 
                    dataSource={arrayRows} 
                    style={{marginRight:45}} 
                    scroll={{ y: 500 }}/>
                    
                </div>
            </div>
    
        </div>
    )
}


